import React from 'react'
import PropTypes from 'prop-types'

import { Pagination, Typography, useViewport, useQuery, putCookie } from '@chilecompra/react-kit'

import { ColumnPaginator, Hr, RowPaginator } from './SearchResults.styles'

import {
  DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
  DIRECT_DEAL_FILTER_DEFAULT_ROWS
} from '../../config/settings/constants'
import { updateQuery } from '../../modules/utils/searches'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'
import useConditionalSearchNavigate from '../../modules/hooks/useCondicionalSearchNavigate'

/**
 * The SearchResult paginator component.
 */
const SearchResultPaginator = ({ result = 0, page = 0, size = 0, count = 0 }) => {
  const {
    size: { isSmall, isTiny, isDownToTiny }
  } = useViewport()
  const query = useQuery()
  const { isBuyer, isSeller } = useAuthorization()
  const { conditionalNavigate } = useConditionalSearchNavigate(isSeller, isBuyer)

  const handleChangePage = (_, updatedValue) => {
    /**
     * one more page is subtracted from the page because
     * the search service starts with page 0.
     */
    const pageValue = updatedValue - 1

    const dictionary = {
      ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
      ...Object.fromEntries(query.entries()),
      page: pageValue
    }

    updateQuery(query, dictionary)
    putCookie('search', query.toString())
    conditionalNavigate(query)
  }

  return (
    <>
      {result > 1 && (
        <>
          <Hr margin="24px 0 0" />
          <RowPaginator>
            {!isSmall && !isTiny && (
              <ColumnPaginator padding="16px 0">
                <Typography variant="body2" color="black2" tabIndex="0">
                  Mostrando {size} de {result} resultados
                </Typography>
              </ColumnPaginator>
            )}
            <ColumnPaginator padding="16px 0">
              {result > DIRECT_DEAL_FILTER_DEFAULT_ROWS && (
                <Pagination
                  boundaryCount={2}
                  count={count}
                  page={page}
                  size={isDownToTiny ? 'small' : 'medium'}
                  onChange={handleChangePage}
                />
              )}
            </ColumnPaginator>
          </RowPaginator>
        </>
      )}
    </>
  )
}

SearchResultPaginator.propTypes = {
  result: PropTypes.number,
  page: PropTypes.number,
  size: PropTypes.number,
  count: PropTypes.number
}

export default SearchResultPaginator
