import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getCookie, useEffectOnce, useViewport } from '@chilecompra/react-kit'
import { Button, Card, Table, Typography } from '@chilecompra/react-kit/components'

import CheckIcon from '@mui/icons-material/Check'

import DirectDealVoucherIssueDialog from './DirectDealVoucher.issue.dialog'

import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

import {
  onCleanDirectDealVoucher,
  onCloseConfirmIssueDirectDealVoucher,
  onOpenConfirmIssueDirectDealVoucher,
  onRedirectIssuePurchaseDirectDealVoucher
} from './DirectDealVoucher.actions'

import {
  ContainerCheck,
  CellVoucher,
  Divider,
  Grid,
  HeaderVoucher,
  MainCircle,
  Strong,
  InnerCircle,
  TableCellContent,
  TableCellHeader,
  TableRow,
  Root
} from './DirectDealVoucher.styles'

import routes from '../../config/settings/routes'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'
import { DIRECT_DEAL_REDIRECT_PURCHASE_ISSUE } from '../../config/settings/constants'

/**
 * The DirectDealVoucher container.
 */
const DirectDealVoucherContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { size } = useViewport()

  const {
    voucher: directDealVoucherInfo,
    dialogs: directDealVoucherDialog,
    loading: directDealVoucherLoading
  } = useSelector(state => state.directDealVoucher)

  const [dateClosedVoucher, setDateClosedVoucher] = useState('')

  const handlerBackToMenu = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
    dispatch(onCleanDirectDealVoucher())
  }
  const handlerContinueIssue = () => {
    dispatch(onOpenConfirmIssueDirectDealVoucher())
  }

  const handlerCloseConfirmIssue = () => {
    dispatch(onCloseConfirmIssueDirectDealVoucher())
  }

  const handlerRedirectPurchase = () => {
    dispatch(onRedirectIssuePurchaseDirectDealVoucher())
    window.location.href = REACT_APP_MERCADO_PUBLICO_BASE_URL.concat(DIRECT_DEAL_REDIRECT_PURCHASE_ISSUE).concat(
      directDealVoucherInfo.porId
    )
  }

  const handlerRedirectSummarySheet = () => {
    navigate(routes.directDealSummary.replace(/:directDealId/, directDealVoucherInfo?.code))
    dispatch(onCleanDirectDealVoucher())
  }

  const formatDateAndHourClosed = () => {
    const onlyDate = (directDealVoucherInfo?.dateClosed && directDealVoucherInfo?.dateClosed.split('T')[0]) || undefined
    const onlyHour = (directDealVoucherInfo?.hourClosed && directDealVoucherInfo?.hourClosed.split('T')[1]) || undefined

    if (onlyDate && onlyHour) setDateClosedVoucher(onlyDate.concat('T', onlyHour))
  }

  useEffectOnce(() => {
    handlerCloseConfirmIssue()
    formatDateAndHourClosed()
  })

  return (
    <Root>
      <Divider />
      <Grid container spacing={2} margin="43px 0">
        <Grid item xs={12} textAlign="center" padding="32px 0">
          <ContainerCheck style={{ display: 'flex', justifyContent: 'center' }} margin="0 0 32px">
            <MainCircle backgroundColor="green">
              <InnerCircle>
                <CheckIcon style={{ color: 'white', fontSize: '24px' }} />
              </InnerCircle>
            </MainCircle>
          </ContainerCheck>
          <Typography variant="h2" color="black1" fontWeight="bold" margin="0 0 8px" tabIndex="0">
            Ficha de Trato Directo creada con éxito
          </Typography>
          <Typography variant="h3" color="success" fontWeight="bold" tabIndex="0">
            Número: {directDealVoucherInfo?.code}
          </Typography>
        </Grid>

        {directDealVoucherInfo?.withPublicity && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Card color="primary" padding="24px 16px">
                <Typography variant="body1" color="black1" align="left" tabIndex="0">
                  Al ser un Trato Directo por la causal "<Strong>{directDealVoucherInfo?.cause?.name}</Strong>" y el
                  monto de la compra es superior a las 1.000 UTM, el estado del Trato Directo permanecerá en estado{' '}
                  <Strong>Recibiendo solicitudes</Strong> hasta que se cumpla la fecha de cierre el día "
                  <Strong>
                    <FormattedDateComponent date={dateClosedVoucher} includeHours />
                  </Strong>
                  ".
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        {size.isTiny && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Grid container>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold" tabIndex="0">
                      Nombre
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2" tabIndex="0">
                      {directDealVoucherInfo?.name}
                    </Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold" tabIndex="0">
                      Causal de Trato Directo
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2" tabIndex="0">
                      {directDealVoucherInfo?.cause}
                    </Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold" tabIndex="0">
                      Monto total
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2" tabIndex="0">
                      <FormattedCurrencyComponent
                        amount={directDealVoucherInfo?.total}
                        currency={directDealVoucherInfo?.currency?.type}
                        includeSymbol
                      />
                    </Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold" tabIndex="0">
                      Proveedor
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher>
                    <Typography variant="body2" tabIndex="0">
                      {directDealVoucherInfo?.providerLegalName}
                      <Typography color="black3" fontSize="14px" tabIndex="0">
                        RUT: {directDealVoucherInfo?.providerDni}
                      </Typography>
                    </Typography>
                  </CellVoucher>
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                  <HeaderVoucher>
                    <Typography variant="body2" fontWeight="bold" tabIndex="0">
                      Fecha de creación
                    </Typography>
                  </HeaderVoucher>
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <CellVoucher enableBottomBorder={!directDealVoucherInfo?.withPublicity} tabIndex="0">
                    <FormattedDateComponent date={new Date()} />
                  </CellVoucher>
                </Grid>
                {directDealVoucherInfo?.withPublicity && (
                  <>
                    <Grid item md={3} sm={3} xs={12}>
                      <HeaderVoucher>
                        <Typography variant="body2" fontWeight="bold" tabIndex="0">
                          Fecha de cierre
                        </Typography>
                      </HeaderVoucher>
                    </Grid>
                    <Grid item md={9} sm={9} xs={12}>
                      <CellVoucher tabIndex="0">
                        <FormattedDateComponent date={directDealVoucherInfo?.dateClosed} />
                      </CellVoucher>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        {!size.isTiny && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Table accessibility={{ label: 'Listado del detalle de la ficha de trato directo creada' }}>
                <TableRow accessibility={{ label: 'Nombre' }}>
                  <TableCellHeader
                    disableBottomBorder
                    enableHead
                    full
                    style={{ padding: '16px' }}
                    tabIndex="0"
                    width={size.isMedium ? '45%' : '35%'}
                  >
                    Nombre
                  </TableCellHeader>
                  <TableCellContent disableBottomBorder style={{ padding: '16px' }} tabIndex="0">
                    {directDealVoucherInfo?.name}
                  </TableCellContent>
                </TableRow>
                <TableRow accessibility={{ label: 'Causal de Trato Directo' }}>
                  <TableCellHeader
                    disableBottomBorder
                    enableHead
                    full
                    style={{ padding: '16px' }}
                    tabIndex="0"
                    width={size.isMedium ? '45%' : '35%'}
                  >
                    Causal de Trato Directo
                  </TableCellHeader>
                  <TableCellContent disableBottomBorder style={{ padding: '16px' }} tabIndex="0">
                    {directDealVoucherInfo?.cause}
                  </TableCellContent>
                </TableRow>
                <TableRow accessibility={{ label: 'Monto total' }}>
                  <TableCellHeader
                    disableBottomBorder
                    enableHead
                    full
                    style={{ padding: '16px' }}
                    tabIndex="0"
                    width={size.isMedium ? '45%' : '35%'}
                  >
                    Monto total
                  </TableCellHeader>
                  <TableCellContent disableBottomBorder style={{ padding: '16px' }} tabIndex="0">
                    <FormattedCurrencyComponent
                      amount={directDealVoucherInfo?.total}
                      currency={directDealVoucherInfo?.currency?.type}
                      includeSymbol
                    />
                  </TableCellContent>
                </TableRow>
                <TableRow accessibility={{ label: 'Proveedor' }}>
                  <TableCellHeader
                    disableBottomBorder
                    enableHead
                    full
                    style={{ padding: '16px' }}
                    tabIndex="0"
                    width={size.isMedium ? '45%' : '35%'}
                  >
                    Proveedor
                  </TableCellHeader>
                  <TableCellContent disableBottomBorder style={{ padding: '7px 16px' }} tabIndex="0">
                    <Typography variant="body2">{directDealVoucherInfo?.providerLegalName}</Typography>
                    <Typography variant="body2" color="black3">
                      RUT: {directDealVoucherInfo?.providerDni}
                    </Typography>
                  </TableCellContent>
                </TableRow>
                <TableRow accessibility={{ label: 'Fecha de creación' }}>
                  <TableCellHeader
                    disableBottomBorder={directDealVoucherInfo?.withPublicity}
                    enableHead
                    full
                    style={{ padding: '16px' }}
                    tabIndex="0"
                    width={size.isMedium ? '45%' : '35%'}
                  >
                    Fecha de creación
                  </TableCellHeader>
                  <TableCellContent
                    disableBottomBorder={directDealVoucherInfo?.withPublicity}
                    style={{ padding: '16px' }}
                    tabIndex="0"
                  >
                    <FormattedDateComponent date={new Date()} />
                  </TableCellContent>
                </TableRow>
                {directDealVoucherInfo?.withPublicity && (
                  <TableRow accessibility={{ label: 'Fecha de cierre' }}>
                    <TableCellHeader
                      enableHead
                      full
                      style={{ padding: '16px' }}
                      tabIndex="0"
                      width={size.isMedium ? '45%' : '35%'}
                    >
                      Fecha de cierre
                    </TableCellHeader>
                    <TableCellContent style={{ padding: '16px' }} tabIndex="0">
                      <FormattedDateComponent date={directDealVoucherInfo?.dateClosed} />
                    </TableCellContent>
                  </TableRow>
                )}
              </Table>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        {!directDealVoucherInfo?.withPublicity && (
          <>
            <Grid item lg={3} md={3} sm={0} xs={0} />
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="left">
              <Typography variant="body1" tabIndex="0">
                A continuación podrás generar la orden de compra respectiva la cuál deberás editar y enviar a autorizar
                en el módulo Búsqueda y Gestión de Orden de Compra.
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={0} xs={0} />
          </>
        )}

        {directDealVoucherInfo?.withPublicity && (
          <Grid item xs={12} textAlign="center" margin="32px 0 0">
            <Button aria-label="Ver la ficha del Trato Directo" color="primary" onClick={handlerRedirectSummarySheet}>
              Ver la ficha del Trato Directo
            </Button>
          </Grid>
        )}

        {!directDealVoucherInfo?.withPublicity && (
          <Grid item xs={12} textAlign="center" margin="32px 0 0">
            <Button aria-label="Continuar y emitir orden de compra" color="primary" onClick={handlerContinueIssue}>
              Continuar y emitir orden de compra
            </Button>
          </Grid>
        )}

        <Grid item xs={12} textAlign="center">
          <Button aria-label="Volver al inicio del módulo" variant="text" onClick={handlerBackToMenu}>
            Volver al inicio del módulo
          </Button>
        </Grid>
      </Grid>

      <DirectDealVoucherIssueDialog
        open={directDealVoucherDialog.openConfirmIssue}
        onClose={handlerCloseConfirmIssue}
        onIssue={handlerRedirectPurchase}
        loading={directDealVoucherLoading}
      />
    </Root>
  )
}

export default DirectDealVoucherContainer
