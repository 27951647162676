import React from 'react'
import PropTypes from 'prop-types'

import { Card, DataTable, Typography } from '@chilecompra/react-kit/components'

import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'

import { Grid } from './DirectDealSummary.styles'

/**
 * The DirectDealSummary's products.
 */
const DirectDealSummaryProducts = props => {
  const { loading, products, currency } = props
  return (
    <Grid container spacing={2} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container>
            <Grid item>
              <Typography fontWeight="bold" variant="h3" tabIndex="0">
                Listado de productos y/o servicios solicitados
              </Typography>
            </Grid>
            <Grid item margin="24px 0 0">
              <DataTable
                accessibility={{ label: 'Listado de productos y/o servicios solicitados' }}
                headers={[
                  { label: 'Producto/Servicio', value: 'name' },
                  { label: 'Detalle', value: 'detail' },
                  { label: 'Cantidad y unidad', value: 'amount' },
                  { label: 'Total', value: 'total', align: 'right' }
                ]}
                headersCellsProps={[
                  { enableSort: true, width: '40%' },
                  { enableSort: true, width: '20%' },
                  { enableSort: true, width: '30%' },
                  { enableSort: true, width: '5%', align: 'right' }
                ]}
                enableStripped
                initialRows={5}
                loading={loading}
                records={products}
                recordsDecorators={[
                  ({ name }) => (
                    <Typography variant="body2" lineHeight="18px" tabIndex="0">
                      {name}
                    </Typography>
                  ),
                  ({ detail }) => (
                    <Typography variant="body2" lineHeight="18px" tabIndex="0">
                      {detail}
                    </Typography>
                  ),
                  ({ amount, nameUnit }) => (
                    <>
                      <Typography variant="body2" tabIndex="0">
                        {amount}
                      </Typography>
                      <Typography variant="body2" color="black3" tabIndex="0">
                        {nameUnit}
                      </Typography>
                    </>
                  ),
                  ({ total }) => (
                    <Typography variant="body2" lineHeight="18px" align="right" tabIndex="0">
                      <FormattedCurrencyComponent amount={total || 0} currency={currency || ''} includeSymbol />
                    </Typography>
                  )
                ]}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryProducts.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      detail: PropTypes.string,
      amount: PropTypes.number,
      unit: PropTypes.string,
      total: PropTypes.number,
      nameUnit: PropTypes.string,
      cod: PropTypes.string
    })
  ),
  currency: PropTypes.string
}

export default DirectDealSummaryProducts
