import {
  REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_BUYER,
  REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_SELLER,
  REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_PUBLIC,
  REACT_APP_PARTICIPANT_LIST,
  REACT_APP_PROVIDER_FOREIGN_STEP_TWO
} from '../settings/environment'

export const features = {
  'download.link.excel.result.buyer':
    REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_BUYER === true || REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_BUYER === 'true',
  'download.link.excel.result.seller':
    REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_SELLER === true || REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_SELLER === 'true',
  'download.link.excel.result.public':
    REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_PUBLIC === true || REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_PUBLIC === 'true',
  'provider.foreign.step.two':
    REACT_APP_PROVIDER_FOREIGN_STEP_TWO === true || REACT_APP_PROVIDER_FOREIGN_STEP_TWO === 'true',
  'participant.list.probidad': REACT_APP_PARTICIPANT_LIST === true || REACT_APP_PARTICIPANT_LIST === 'true'
}
