import {
  CLEAN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE,
  CLOSE_CONFIRM_STEP_ONE_DIALOG,
  CLOSE_SAVED_FAILED_STEP_ONE_DIALOG,
  CLOSE_SAVED_STEP_ONE_DIALOG,
  CREATE_DIRECT_DEAL_ERROR,
  CREATE_DIRECT_DEAL_SUCCESS,
  CREATE_DIRECT_DEAL,
  GET_DESCRIPTION_CAUSE_STEP_ONE_ERROR,
  GET_DESCRIPTION_CAUSE_STEP_ONE_SUCCESS,
  GET_DESCRIPTION_CAUSE_STEP_ONE,
  GET_INFO_DNI_ERROR,
  GET_INFO_DNI_SUCCESS,
  GET_INFO_DNI,
  GET_UTM_STEP_ONE_ERROR,
  GET_UTM_STEP_ONE_SUCCESS,
  GET_UTM_STEP_ONE,
  OPEN_CONFIRM_STEP_ONE_DIALOG,
  OPEN_SAVED_FAILED_STEP_ONE_DIALOG,
  OPEN_SAVED_STEP_ONE_DIALOG,
  RESET_STEP_ONE,
  SAVED_DRAFT_DIRECT_DEAL_STEP_ONE_ERROR,
  SAVED_DRAFT_DIRECT_DEAL_STEP_ONE_SUCCESS,
  SAVED_DRAFT_DIRECT_DEAL_STEP_ONE,
  SAVED_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE,
  SAVED_SHEET_DIRECT_DEAL_STEP_ONE
} from './DirectDealStepOne.actions'

const directDealStepOneState = {
  causes: [],
  error: undefined,
  dialogs: { isOpenCreation: false, isOpenFailed: false, isOpenSaved: false },
  info: null,
  sheetCode: null,
  utm: null,
  stepZero: undefined,
  loadings: { page: false, requester: false },
  storage: null
}

/**
 * The DirectDealStepOne reducer.
 */
const directDealStepOneReducer = (state = directDealStepOneState, { payload, type }) => {
  switch (type) {
    case SAVED_DRAFT_DIRECT_DEAL_STEP_ONE:
    case GET_DESCRIPTION_CAUSE_STEP_ONE:
    case GET_UTM_STEP_ONE:
    case CREATE_DIRECT_DEAL: {
      return { ...state, error: undefined, loadings: { ...state.loadings, page: true } }
    }
    case GET_INFO_DNI: {
      return { ...state, error: undefined, loadings: { ...state.loadings, requester: true } }
    }
    case GET_INFO_DNI_ERROR: {
      return { ...state, error: payload.error, loadings: { ...state.loadings, requester: false }, info: null }
    }
    case GET_INFO_DNI_SUCCESS: {
      return { ...state, error: undefined, loadings: { ...state.loadings, requester: false } }
    }
    case SAVED_DRAFT_DIRECT_DEAL_STEP_ONE_ERROR:
    case GET_DESCRIPTION_CAUSE_STEP_ONE_ERROR:
    case GET_UTM_STEP_ONE_ERROR:
    case CREATE_DIRECT_DEAL_ERROR: {
      return { ...state, error: payload.error, loadings: { ...state.loadings, page: false } }
    }
    case GET_UTM_STEP_ONE_SUCCESS: {
      return { ...state, error: undefined, loadings: { ...state.loadings, page: false }, utm: { ...payload } }
    }
    case OPEN_CONFIRM_STEP_ONE_DIALOG: {
      return { ...state, dialogs: { ...state.dialogs, isOpenCreation: true } }
    }
    case CLOSE_CONFIRM_STEP_ONE_DIALOG: {
      return { ...state, dialogs: { ...state.dialogs, isOpenCreation: false } }
    }
    case GET_DESCRIPTION_CAUSE_STEP_ONE_SUCCESS: {
      return { ...state, loadings: { ...state.loadings, page: false }, causes: [...payload.causes] }
    }
    case SAVED_DRAFT_DIRECT_DEAL_STEP_ONE_SUCCESS:
    case CREATE_DIRECT_DEAL_SUCCESS: {
      return {
        ...state,
        loadings: { ...state.loadings, page: false },
        storage: payload.storage,
        sheetCode: payload.sheetCode
      }
    }
    case RESET_STEP_ONE: {
      return { ...state, info: null, sheetCode: null, utm: null, storage: null }
    }
    case SAVED_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE: {
      return { ...state, stepZero: payload }
    }
    case CLEAN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE: {
      return { ...state, stepZero: undefined }
    }
    case SAVED_SHEET_DIRECT_DEAL_STEP_ONE: {
      return { ...state, storage: payload }
    }
    case OPEN_SAVED_FAILED_STEP_ONE_DIALOG: {
      return { ...state, dialogs: { ...state.dialogs, isOpenFailed: true } }
    }
    case CLOSE_SAVED_FAILED_STEP_ONE_DIALOG: {
      return { ...state, dialogs: { ...state.dialogs, isOpenFailed: false } }
    }
    case OPEN_SAVED_STEP_ONE_DIALOG: {
      return { ...state, dialogs: { ...state.dialogs, isOpenSaved: true } }
    }
    case CLOSE_SAVED_STEP_ONE_DIALOG: {
      return { ...state, dialogs: { ...state.dialogs, isOpenSaved: false } }
    }
    default: {
      return state
    }
  }
}

export default directDealStepOneReducer
