import { createContext } from 'react'

const contextPayload = {
  flowMain: null,
  flowStepZero: null,
  flowStepOne: null,
  flowStepTwo: null
}

const StepFlowProviderContext = createContext(contextPayload)
StepFlowProviderContext.displayName = 'step-flow-provider-context'

export default StepFlowProviderContext
