import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Card, Input, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  checkInputErrorEmail,
  checkInputErrorDni,
  useInput
} from '@chilecompra/react-kit'

import { Grid, GridItem, SearchSuffix, SearchIconCustom } from './DirectDealStepOne.styles'
import { onGetInfoDNIThunk } from './DirectDealStepOne.actions'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { formatDNI, unformatDNI } from '../../modules/utils/formatters'
import { DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED } from '../../config/settings/constants'

/**
 * The DirectDealStepOne's requester information.
 */
const DirectDealRequester = props => {
  const { formRef, loading, values } = props

  const dispatch = useDispatch()
  const {
    error: dniError,
    onChange: handleDniChange,
    onError: handleDniError,
    setValue: setDniValue,
    value: dniValue
  } = useInput({
    changeCallback: () => {
      setNameValue('')
      setLastNameValue('')
    },
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorDni()],
    formatCallbacks: [value => formatDNI(value)],
    initialValue: values?.dni || ''
  })
  const {
    error: nameError,
    onChange: handleNameChange,
    onError: handleNameError,
    onReset: handleNameReset,
    setValue: setNameValue,
    value: nameValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.name || ''
  })
  const {
    error: lastNameError,
    onChange: handleLastNameChange,
    onError: handleLastNameError,
    onReset: handleLastNameReset,
    setValue: setLastNameValue,
    value: lastNameValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.lastName || ''
  })
  const {
    error: jobError,
    onChange: handleJobChange,
    onError: handleJobError,
    setValue: setJobValue,
    value: jobValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.job || ''
  })
  const {
    error: emailError,
    onChange: handleEmailChange,
    onError: handleEmailError,
    setValue: setEmailValue,
    value: emailValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMaxLength(200),
      checkInputErrorEmail('Formato de email incorrecto.')
    ],
    initialValue: values?.email || ''
  })

  const handlerSearchDni = () => {
    dispatch(
      onGetInfoDNIThunk({
        dni: unformatDNI(dniValue),
        callback: requester => {
          handleNameReset()
          handleLastNameReset()

          setNameValue(requester?.names || '')
          setLastNameValue(requester?.lastNames || '')
        }
      })
    )
  }

  useImperativeFormRef(
    formRef,
    () => ({
      dni: {
        error: dniError,
        onError: handleDniError,
        value: dniValue
      },
      email: {
        error: emailError,
        onError: handleEmailError,
        value: emailValue
      },
      job: {
        error: jobError,
        onError: handleJobError,
        value: jobValue
      },
      lastName: {
        error: lastNameError,
        onError: handleLastNameError,
        value: lastNameValue
      },
      name: {
        error: nameError,
        onError: handleNameError,
        value: nameValue
      }
    }),
    [dniError, dniValue, emailError, emailValue, jobError, jobValue, lastNameError, lastNameValue, nameError, nameValue]
  )

  useEffect(() => {
    setDniValue(values?.dni || '')
    setNameValue(values?.name || '')
    setLastNameValue(values?.lastName || '')
    setJobValue(values?.job || '')
    setEmailValue(values?.email || '')
  }, [values])

  return (
    <Grid container margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography fontWeight="bold" variant="body1" margin="0 0 8px" tabIndex="0">
          Requirente del Trato Directo
        </Typography>
        <Typography fontSize="14px" fontWeight="regular" lineHeight="18px" variant="body2" tabIndex="0">
          Información de la persona que realizó la solicitud de compra
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <Grid container spacing={1}>
          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="RUT"
              value={dniValue}
              onChange={handleDniChange}
              onError={handleDniError}
              error={dniError}
              suffix={
                <SearchSuffix aria-label="Buscar RUT" onClick={handlerSearchDni}>
                  <SearchIconCustom />
                </SearchSuffix>
              }
              loading={loading}
            />
          </GridItem>

          <GridItem md={6} />

          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="Nombres"
              disabled
              value={nameValue}
              onChange={handleNameChange}
              onError={handleNameError}
              error={nameError}
              loading={loading}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="Apellidos"
              disabled
              value={lastNameValue}
              onChange={handleLastNameChange}
              onError={handleLastNameError}
              error={lastNameError}
              loading={loading}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="Cargo"
              value={jobValue}
              onChange={handleJobChange}
              onError={handleJobError}
              error={jobError}
              loading={loading}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Input
              type="email"
              label="Correo electrónico"
              value={emailValue}
              onChange={handleEmailChange}
              onError={handleEmailError}
              error={emailError}
              loading={loading}
            />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem xs={12} md={4}>
        {formRef.current.withTechnicalReport && (
          <Card
            enableBorder
            lineColor="default"
            color="blank"
            padding="24px"
            sx={{
              position: 'absolute',
              top: '0%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              maxWidth: '400px',
              width: '90%',
              display: { sm: 'none', xs: 'none', lg: 'block', md: 'block' }
            }}
          >
            <Grid container>
              <GridItem>
                <Typography variant="h4" fontWeight="bold" padding="0 0 8px" tabIndex="0">
                  Se requiere informe técnico
                </Typography>
              </GridItem>
              <GridItem>
                <Typography variant="body2" lineHeight="18px" tabIndex="0">
                  {DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED}
                </Typography>
              </GridItem>
            </Grid>
          </Card>
        )}
      </GridItem>
    </Grid>
  )
}

DirectDealRequester.propTypes = {
  loading: PropTypes.bool,
  requesterInfo: PropTypes.shape({
    names: PropTypes.string,
    lastNames: PropTypes.string
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  values: PropTypes.shape({
    dni: PropTypes.string,
    name: PropTypes.string,
    lastName: PropTypes.string,
    job: PropTypes.string,
    email: PropTypes.string
  })
}

export default DirectDealRequester
