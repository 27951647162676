import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { batch, useDispatch } from 'react-redux'

import { AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Button, Card, Skeleton, Typography, useQuery } from '@chilecompra/react-kit'

import DirectDealStepTwoProduct from './DirectDealStepTwo.product'
import DirectDealStepTwoTotals from './DirectDealStepTwo.totals'

import {
  onRemoveSearchingProducts,
  onSavedProductSuccess,
  onRemoveProductSuccess
} from '../../ProductSearch/ProductSearch.actions'
import { onRemoveSearchingCategoriesProducts } from '../../ProductCategorySearch/ProductCategorySearch.actions'
import { onShowSnackbar } from '../../SnackBarProvider/SnackBarProvider.actions'

import { Accordion, FileRequestImage, GridContainer, GridItem, SpanTitleAccordion } from '../DirectDealStepTwo.styles'

import routes from '../../../config/settings/routes'
import { formatCurrencyToNumber } from '../../../modules/utils/formatters'
import { useImperativeFormRef } from '../../../modules/hooks/useFormRef'
import { CURRENCY_TRANSLATION, DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS } from '../../../config/settings/constants'

/**
 * The DirectDealStepTwo products container.
 */
const DirectDealStepTwoProducts = props => {
  const {
    code,
    currency,
    formStepTwoRef,
    loading,
    onChangeAccordion,
    onDependencyError,
    onStorage,
    products,
    unitMeasurement
  } = props

  const navigate = useNavigate()
  const query = useQuery()
  const dispatch = useDispatch()

  const [accordionsExpanded, setAccordionsExpanded] = useState({})
  const [netTotal, setNetTotal] = useState(0)

  const handlerRedirectProductSearch = () => {
    dispatch(onRemoveSearchingProducts())
    dispatch(onRemoveSearchingCategoriesProducts())
    onStorage()

    if (currency) {
      query.set('code', code)
      navigate({ pathname: routes.productSearch, search: query.toString() }, { replace: true })
    } else {
      onDependencyError()
    }
  }
  const handlerExpandedAccordions = productPanel => (event, isExpanded) => {
    event.preventDefault()

    if (productPanel.isInvalid) {
      return
    }

    if (accordionsExpanded[productPanel.key]?.isInvalid) {
      return
    }

    if (!accordionsExpanded[productPanel.key]?.isSaved) {
      return
    }

    setAccordionsExpanded(prev => ({
      ...prev,
      [productPanel.key]: {
        ...prev[productPanel.key],
        isExpanded
      }
    }))
  }
  const handleSavedProduct = payload => {
    batch(() => {
      dispatch(onSavedProductSuccess(payload))
      dispatch(
        onShowSnackbar({
          title: 'Producto guardado',
          severity: 'success'
        })
      )
      setAccordionsExpanded(prev => ({
        ...prev,
        [payload.productKey]: {
          ...prev[payload.productKey],
          isExpanded: false
        }
      }))
    })
  }
  const handleRemoveProduct = productKey => {
    batch(() => {
      dispatch(onRemoveProductSuccess(productKey))
      dispatch(
        onShowSnackbar({
          title: 'Producto eliminado',
          severity: 'success'
        })
      )
      setAccordionsExpanded(prev => {
        const newState = { ...prev }
        delete newState[productKey]
        return newState
      })
    })
  }
  const handlerCalculateItem = ({ item, callback }) => {
    const { amount, unit, currencyType, discountOrCharge, discount, charge } = item

    const amountItem = parseInt(amount) || 0
    const unitItem = formatCurrencyToNumber(unit || '0', currencyType)
    const discountItem = formatCurrencyToNumber(discount || '0', currencyType)
    const chargeItem = formatCurrencyToNumber(charge || '0', currencyType)
    let net = 0

    if (currencyType) {
      net = amountItem * unitItem
      if (callback) callback(net)

      if (discountOrCharge === DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS[0].value) {
        net =
          Math.round(Math.max(net - discountItem, 0) * CURRENCY_TRANSLATION[currency?.type]?.roundedDecimal) /
          CURRENCY_TRANSLATION[currency?.type]?.roundedDecimal
      } else if (discountOrCharge === DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS[1].value) {
        net =
          Math.round(Math.max(net + chargeItem, 0) * CURRENCY_TRANSLATION[currency?.type]?.roundedDecimal) /
          CURRENCY_TRANSLATION[currency?.type]?.roundedDecimal
      }
    } else {
      if (callback) callback(net)
    }

    return net
  }

  const calculateProductsTotalNet = productsUpdate => {
    const total = productsUpdate.reduce((acc, product) => {
      const { amount, unitPrice, discount, charge, discountOrCharge } = product
      return (
        acc +
        handlerCalculateItem({
          item: {
            amount,
            unit: unitPrice,
            currencyType: currency?.type,
            discountOrCharge,
            discount,
            charge
          }
        })
      )
    }, 0)

    setNetTotal(total)
  }

  useEffect(() => {
    setAccordionsExpanded(
      products.reduce((acc, product) => {
        acc[product.productKey] = { isExpanded: product.isInvalid, isInvalid: product.isInvalid, isSaved: true }
        return acc
      }, {})
    )
    calculateProductsTotalNet(products)
  }, [products, currency])

  useEffect(() => {
    onChangeAccordion(
      Object.values(accordionsExpanded).some(accordion => {
        return accordion.isInvalid || !accordion.isSaved
      })
    )
  }, [accordionsExpanded])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      netTotal
    }),
    [netTotal]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem lg={2} md={2} sm={12} xs={12}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Productos y servicios a solicitar
        </Typography>
        <Typography variant="body2" padding="8px 0 0" tabIndex="0">
          Los productos que ingreses deben coincidir con los que ingresarás al realizar la orden de compra.
        </Typography>
      </GridItem>

      {!loading && products.length <= 0 && (
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Card enableBorder lineColor="primary" color="blank" padding="78px 0">
            <GridContainer justifyContent="center" alignItems="center" spacing={2}>
              <GridItem xs={12} display="flex" justifyContent="center" alignItems="center">
                <FileRequestImage alt="Lupa buscando en los servidores" src="/assets/images/Error_Busqueda.svg" />
              </GridItem>
              <GridItem xs={12} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" fontWeight="regular" padding="16px 0 0" color="gray1" tabIndex="0">
                  No tienes productos o servicios agregados
                </Typography>
              </GridItem>
              <GridItem xs={12} display="flex" justifyContent="center" alignItems="center">
                <Button
                  aria-label="Agregar productos"
                  variant="outlined"
                  color="primary"
                  onClick={handlerRedirectProductSearch}
                >
                  Agregar productos
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      )}

      {!loading && products.length > 0 && (
        <>
          <GridItem lg={10} md={10} sm={12} xs={12}>
            <GridContainer>
              <GridItem xs={12} margin="0 0 24px">
                <Button
                  aria-label="Agregar más productos o servicios"
                  variant="outlined"
                  color="primary"
                  onClick={handlerRedirectProductSearch}
                >
                  Agregar más productos o servicios
                </Button>
              </GridItem>
              <GridItem lg={7} md={7} sm={12} xs={12}>
                {products.map(product => (
                  <Accordion
                    key={product.productKey}
                    expanded={!!accordionsExpanded[product.productKey]?.isExpanded}
                    onChange={handlerExpandedAccordions({ isInvalid: product.isInvalid, key: product.productKey })}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" color="primary" />}>
                      <Typography variant="body2" fontWeight="bold" tabIndex="0">
                        {product.productName} <SpanTitleAccordion>Cód: {product.productId}</SpanTitleAccordion>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DirectDealStepTwoProduct
                        product={product}
                        currency={currency}
                        unitMeasurement={unitMeasurement}
                        onCalculate={handlerCalculateItem}
                        onSave={handleSavedProduct}
                        onRemove={handleRemoveProduct}
                        onInvalidProduct={isInvalid => {
                          setAccordionsExpanded(prev => ({
                            ...prev,
                            [product.productKey]: {
                              ...prev[product.productKey],
                              isInvalid
                            }
                          }))
                        }}
                        onSavedProduct={isSaved => {
                          setAccordionsExpanded(prev => ({
                            ...prev,
                            [product.productKey]: {
                              ...prev[product.productKey],
                              isSaved
                            }
                          }))
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </GridItem>
              <GridItem lg={3} md={4} sm={12} xs={12}>
                <DirectDealStepTwoTotals currency={currency} netTotal={netTotal} />
              </GridItem>
            </GridContainer>
          </GridItem>
        </>
      )}

      {loading && (
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Skeleton />
        </GridItem>
      )}
    </GridContainer>
  )
}

DirectDealStepTwoProducts.propTypes = {
  code: PropTypes.string,
  currency: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      categoryId: PropTypes.number,
      charge: PropTypes.number,
      codeZGEN: PropTypes.string,
      detail: PropTypes.string,
      discount: PropTypes.number,
      discountOrCharge: PropTypes.string,
      dontKnowCodeZGEN: PropTypes.bool,
      isInvalid: PropTypes.bool,
      productId: PropTypes.number,
      productKey: PropTypes.string,
      productName: PropTypes.string,
      unitMeasure: PropTypes.string,
      unitPrice: PropTypes.number,
      withDiscountOrCharge: PropTypes.bool
    })
  ),
  unitMeasurement: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onStorage: PropTypes.func,
  onDependencyError: PropTypes.func,
  onChangeAccordion: PropTypes.func
}

export default DirectDealStepTwoProducts
