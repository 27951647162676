import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { batch, useDispatch } from 'react-redux'
import { Input, DatePicker, Typography, Select, Radio } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  Textarea,
  useDatePicker,
  useInput,
  useRadio,
  useSelect
} from '@chilecompra/react-kit'

import {
  DIRECT_DEAL_STEP_TWO_TYPE_CONTRACT_OPTIONS,
  DIRECT_DEAL_STEP_TWO_CONTRACT_DURATION_DICTIONARY
} from '../../config/settings/constants'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'
import { onResetAmountProducts } from '../ProductSearch/ProductSearch.actions'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwoGeneralInformation's container.
 */
const DirectDealStepTwoGeneralInformation = props => {
  const { cause, contracts, contractDurations, currencies, formStepTwoRef, loading, storage } = props

  const [contractTypeOptions, setContractTypeOptions] = useState([])
  const dispatch = useDispatch()
  const MIN_DATE_EXECUTION = new Date()

  const {
    error: nameSheetError,
    onChange: handleNameSheetChange,
    onError: handleNameSheetError,
    value: nameSheetValue,
    setValue: setNameValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100), checkInputErrorRequired()],
    initialValue: storage?.nameSheet || ''
  })

  const {
    error: descriptionError,
    onChange: handleDescriptionChange,
    onError: handleDescriptionError,
    value: descriptionValue,
    setValue: setDescriptionValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(500), checkInputErrorRequired()],
    initialValue: storage?.description || ''
  })

  const {
    error: justificationError,
    onChange: handleJustificationChange,
    onError: handleJustificationError,
    value: justificationValue,
    setValue: setJustificationValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(500), checkInputErrorRequired()],
    initialValue: storage?.justification || ''
  })

  const {
    error: durationError,
    onChange: handleDurationChange,
    onError: handleDurationError,
    value: durationValue,
    setValue: setDurationValue
  } = useSelect({
    initialValue: storage?.duration || '',
    changeCallback: () => setDateFinishExecuteValue(new Date()),
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: contractTypeError,
    onChange: handleContractTypeChange,
    onError: handleContractTypeError,
    setValue: setContractTypeValue,
    value: contractTypeValue
  } = useSelect({
    initialValue: storage?.contractType || '',
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: coinError,
    onChange: handleCoinChange,
    onError: handleCoinError,
    value: coinValue,
    setValue: setCoinValue
  } = useSelect({
    initialValue: storage?.coin || '',
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: () =>
      batch(() => {
        dispatch(onResetAmountProducts())
        dispatch(
          onShowSnackbar({
            title: 'Moneda cambiada',
            message: 'Se han limpiado todos los valores.',
            severity: 'warning'
          })
        )
      })
  })

  const {
    error: dateFinishExecuteError,
    onChange: handleDateFinishExecuteChange,
    onError: handleDateFinishExecuteError,
    setValue: setDateFinishExecuteValue,
    value: dateFinishExecuteValue
  } = useDatePicker({
    initialValue: storage?.dateFinishExecute || new Date()
  })

  const {
    onChange: handleContractCategoryChange,
    value: contractCategoryValue,
    setValue: setRadioContractCategoryValue
  } = useRadio({
    initialValue: storage?.contractCategory || '',
    changeCallback: updateValue => {
      setContractTypeValue('')
      setContractTypeByContractCategory(updateValue)
    },
    errorCallbacks: [checkInputErrorRequired()]
  })
  const setContractTypeByContractCategory = category => {
    if (category === '1') {
      setContractTypeOptions(contracts?.GOODS)
    } else {
      setContractTypeOptions(contracts?.SERVICES)
    }
  }

  useEffect(() => {
    setNameValue(storage?.nameSheet || '')
    setDescriptionValue(storage?.description || '')
    setJustificationValue(storage?.justification || '')
    setDurationValue(storage?.duration || '')
    setCoinValue(storage?.coin || '')
    setDateFinishExecuteValue(storage?.dateFinishExecute || new Date())
    setRadioContractCategoryValue(storage?.contractCategory || null)
    setContractTypeValue(storage?.contractType || '')

    if (contractCategoryValue || storage?.contractCategory) {
      setContractTypeByContractCategory(contractCategoryValue || storage?.contractCategory)
    } else {
      setContractTypeOptions([])
    }
  }, [storage])

  useEffect(() => {
    if (contractCategoryValue || storage?.contractCategory) {
      setContractTypeByContractCategory(contractCategoryValue || storage?.contractCategory)
    } else {
      setContractTypeOptions([])
    }
  }, [contracts])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      coin: {
        error: coinError,
        onError: handleCoinError,
        value: coinValue
      },
      contractCategory: {
        value: contractCategoryValue
      },
      contractType: {
        error: contractTypeError,
        onError: handleContractTypeError,
        value: contractTypeValue
      },
      dateFinishExecute: {
        error: dateFinishExecuteError,
        onError: handleDateFinishExecuteError,
        value: dateFinishExecuteValue
      },
      description: {
        error: descriptionError,
        onError: handleDescriptionError,
        value: descriptionValue
      },
      duration: {
        error: durationError,
        onError: handleDurationError,
        value: durationValue
      },
      justification: {
        error: justificationError,
        onError: handleJustificationError,
        value: justificationValue
      },
      nameSheet: {
        error: nameSheetError,
        onError: handleNameSheetError,
        value: nameSheetValue
      }
    }),
    [
      coinValue,
      coinError,
      contractCategoryValue,
      contractTypeValue,
      contractTypeError,
      dateFinishExecuteError,
      dateFinishExecuteValue,
      descriptionValue,
      descriptionError,
      durationValue,
      durationError,
      justificationValue,
      justificationError,
      nameSheetError,
      nameSheetValue
    ]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Información general
        </Typography>
        <Typography fontWeight="regular" lineHeight="16px" variant="body2" padding="8px 0 0" tabIndex="0">
          Ingresa los datos generales de tu contratación.
        </Typography>
      </GridItem>

      <GridItem xs={12} md={6}>
        <GridContainer spacing={1}>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body1" fontWeight="bold" tabIndex="0">
              Causal de Trato Directo
            </Typography>
            <Typography fontWeight="regular" lineHeight="16px" variant="body1" padding="8px 0 0" tabIndex="0">
              {cause}
            </Typography>
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Input
              error={nameSheetError}
              info="Este será el nombre que tendrá el Trato Directo y la orden de compra"
              label="Nombre"
              loading={loading}
              maxCount={100}
              onChange={handleNameSheetChange}
              value={nameSheetValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Textarea
              error={descriptionError}
              label="Descripción"
              loading={loading}
              maxCount={500}
              onChange={handleDescriptionChange}
              size="medium"
              value={descriptionValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Textarea
              error={justificationError}
              label="Justificación del Trato Directo"
              loading={loading}
              maxCount={500}
              onChange={handleJustificationChange}
              size="medium"
              value={justificationValue}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Select
              error={durationError}
              label="Duración del contrato"
              loading={loading}
              onChange={handleDurationChange}
              options={contractDurations}
              placeholder="Seleccione la duración del contrato"
              value={durationValue}
            />
          </GridItem>
          {durationValue === DIRECT_DEAL_STEP_TWO_CONTRACT_DURATION_DICTIONARY.FOR_PERIOD && (
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <DatePicker
                error={dateFinishExecuteError}
                format="dd/MM/yyyy"
                label="Fecha de Término de ejecución"
                loading={loading}
                minDate={MIN_DATE_EXECUTION}
                value={dateFinishExecuteValue}
                onChange={handleDateFinishExecuteChange}
              />
            </GridItem>
          )}
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Radio
              label={
                <Typography variant="body2" fontWeight="bold" style={{ letterSpacing: 'normal' }}>
                  Categoría del contrato
                </Typography>
              }
              loading={loading}
              onChange={handleContractCategoryChange}
              options={DIRECT_DEAL_STEP_TWO_TYPE_CONTRACT_OPTIONS}
              value={contractCategoryValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Select
              error={contractTypeError}
              label="Tipo de contrato"
              loading={loading}
              onChange={handleContractTypeChange}
              options={contractTypeOptions}
              placeholder="Selecciona el tipo de contrato"
              value={contractTypeValue}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Select
              error={coinError}
              label="Moneda"
              loading={loading}
              onChange={handleCoinChange}
              options={currencies}
              placeholder="Selecciona el tipo de moneda"
              value={coinValue}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoGeneralInformation.propTypes = {
  cause: PropTypes.string,
  contracts: PropTypes.shape({
    GOODS: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })),
    SERVICES: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string }))
  }),
  contractDurations: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })),
  currencies: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string })),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  storage: PropTypes.arrayOf({
    coin: PropTypes.string,
    contractType: PropTypes.string,
    dateFinishExecute: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    duration: PropTypes.string,
    justification: PropTypes.string,
    nameSheet: PropTypes.string,
    contractCategory: PropTypes.string
  })
}
export default DirectDealStepTwoGeneralInformation
