import { DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY } from '../../config/settings/constants'
import {
  SET_INITIAL_CREATE_FLOW_PROVIDER,
  SET_DIRECTION_STEP_ONE_FLOW_PROVIDER,
  SET_DIRECTION_STEP_ZERO_FLOW_PROVIDER,
  SET_DIRECTION_STEP_TWO_FLOW_PROVIDER,
  SET_INITIAL_EDIT_FLOW_PROVIDER
} from './StepFlowProvider.actions'

const stepFlowProviderState = {
  flowMain: null,
  flowStepZero: null,
  flowStepOne: null,
  flowStepTwo: null
}

/**
 * The authorization provider's reducer.
 */

const stepFlowProviderReducer = (state = stepFlowProviderState, { payload, type }) => {
  switch (type) {
    case SET_INITIAL_CREATE_FLOW_PROVIDER: {
      return {
        ...state,
        flowMain: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.CREATE,
        flowStepZero: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.AHEAD,
        flowStepOne: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.AHEAD,
        flowStepTwo: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.AHEAD
      }
    }
    case SET_INITIAL_EDIT_FLOW_PROVIDER: {
      return {
        ...state,
        flowMain: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.UPDATE,
        flowStepZero: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.RETURN,
        flowStepOne: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.RETURN,
        flowStepTwo: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.RETURN
      }
    }
    case SET_DIRECTION_STEP_ONE_FLOW_PROVIDER: {
      return {
        ...state,
        flowStepOne: payload.direction
      }
    }
    case SET_DIRECTION_STEP_ZERO_FLOW_PROVIDER: {
      return {
        ...state,
        flowStepZero: payload.direction
      }
    }
    case SET_DIRECTION_STEP_TWO_FLOW_PROVIDER: {
      return {
        ...state,
        flowStepTwo: payload.direction
      }
    }
    default: {
      return state
    }
  }
}

export default stepFlowProviderReducer
