import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { CheckBox, Typography, Input } from '@chilecompra/react-kit/components'
import { checkInputErrorRequired, Skeleton, useCheckBox, useInput } from '@chilecompra/react-kit'

import { GridContainer, GridItem, Link, SearchSuffix, SearchIconCustom } from './DirectDealStepTwo.styles'

import { onCleanInfoProviderDirectDealStepTwo, onGetInfoProviderThunk } from './DirectDealStepTwo.actions'

import { formatDNI } from '../../modules/utils/formatters'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { checkInputErrorCustomValue } from '../../modules/utils/checkers'

import ProtectByFeatures from '../../components/Security/ProtectByFeatures'

import { STATUS_ABILITY_PROVIDER_DICTIONARY } from '../../config/settings/constants'

/**
 * The DirectDealStepTwoProvider's container.
 */
const DirectDealStepTwoProvider = props => {
  const { formStepTwoRef, loading, onOpenDialog, onRedirect, provider, storage } = props

  const [notFoundProvider, setNotFoundProvider] = useState(false)
  const dispatch = useDispatch()

  const {
    onChange: handleForeignSupplierChange,
    value: foreignSupplierValue,
    setValue: setForeignSupplierValue
  } = useCheckBox({
    changeCallback: () => {
      handleProviderDniReset()
      setProviderValue('')
      setNotFoundProvider(false)
      dispatch(onCleanInfoProviderDirectDealStepTwo())
    },
    initialValue: storage?.foreignSupplier || false
  })

  const {
    error: providerDniError,
    onChange: handleProviderDniChange,
    onError: handleProviderDniError,
    onReset: handleProviderDniReset,
    setValue: setProviderValue,
    value: providerDniValue
  } = useInput({
    changeCallback: () => dispatch(onCleanInfoProviderDirectDealStepTwo()),
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorCustomValue(
        !foreignSupplierValue && formStepTwoRef.current.provider?.value && notFoundProvider,
        'No se ha encontrado RUT del proveedor'
      ),
      checkInputErrorCustomValue(
        foreignSupplierValue && formStepTwoRef.current.provider?.value && notFoundProvider,
        'Proveedor sin inscripción vigente en Mercado Público.'
      )
    ],
    formatCallbacks: !foreignSupplierValue ? [formatDNI] : [],
    initialValue: storage?.providerDni || ''
  })

  const handlerSearchProvider = () => {
    if (!providerDniValue) {
      return
    }

    dispatch(
      onGetInfoProviderThunk({
        providerDni: providerDniValue,
        isForeign: foreignSupplierValue,
        callback: () => {
          handleProviderDniReset()
          setProviderValue(providerDniValue)
          setNotFoundProvider(false)
        },
        callbackError: () => setNotFoundProvider(true)
      })
    )
  }
  useEffect(() => {
    setProviderValue(storage?.providerDni || '')
    setForeignSupplierValue(storage?.foreignSupplier || false)
  }, [storage])

  useEffect(() => {
    if (provider && !provider?.isEnabled) {
      onOpenDialog()
    }
  }, [provider])

  useEffect(() => {
    if (notFoundProvider) {
      handleProviderDniError()
    }
  }, [notFoundProvider])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      provider: {
        error: providerDniError,
        onError: handleProviderDniError,
        value: providerDniValue
      },
      foreignSupplierValue
    }),
    [providerDniError, providerDniValue, foreignSupplierValue]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Identificación del proveedor
        </Typography>
        <Typography fontWeight="regular" variant="body2" padding="8px 0 0" tabIndex="0">
          Completa la información del proveedor con el que realizarás el Trato Directo.
        </Typography>
      </GridItem>
      <GridItem md={6} sm={12} xs={12}>
        <GridContainer spacing={1}>
          <ProtectByFeatures rule={features => features['provider.foreign.step.two']}>
            <GridItem md={12} sm={12} xs={12}>
              <CheckBox
                accessibility={{
                  label: '¿El proveedor es extranjero?'
                }}
                checked={foreignSupplierValue}
                label={
                  <Typography variant="body2" margin="4px 0 0 -11px" tabIndex="0">
                    ¿El proveedor es extranjero?
                  </Typography>
                }
                margin="0 0 0 -3px"
                onChange={handleForeignSupplierChange}
              />
            </GridItem>
          </ProtectByFeatures>
          <GridItem md={6} sm={6} xs={12}>
            <Input
              error={providerDniError}
              label={foreignSupplierValue ? 'ID Proveedor extranjero' : 'RUT Proveedor'}
              loading={loading}
              value={providerDniValue}
              onChange={handleProviderDniChange}
              suffix={
                <SearchSuffix aria-label="Buscar RUT" onClick={handlerSearchProvider}>
                  <SearchIconCustom />
                </SearchSuffix>
              }
            />
          </GridItem>
          <GridItem md={6} sm={6} xs={12} />
          <GridItem md={6} sm={6} xs={12}>
            {loading && <Skeleton />}
            {!loading && provider && (
              <>
                <Typography variant="body2" fontWeight="bold" tabIndex="0">
                  Razón Social
                </Typography>
                <Typography
                  fontWeight="regular"
                  lineHeight="16px"
                  variant="h4"
                  color="gray1"
                  padding="8px 0 0"
                  tabIndex="0"
                >
                  {provider?.businessName}
                </Typography>
              </>
            )}
          </GridItem>
          <GridItem md={6} sm={6} xs={12}>
            {loading && <Skeleton />}
            {!loading && provider && (
              <>
                <Typography variant="body2" fontWeight="bold" tabIndex="0">
                  Estado de habilidad
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color={
                    {
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.NOT_INFORMATION]: 'default',
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.ABILITY]: 'success',
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.NOT_ABILITY]: 'error',
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.IN_REVIEW]: 'warning'
                    }[provider?.status]
                  }
                  tabIndex="0"
                >
                  {provider?.status}
                </Typography>
                <Typography
                  fontWeight="regular"
                  lineHeight="16px"
                  variant="body2"
                  color="black1"
                  padding="8px 0 0"
                  tabIndex="0"
                >
                  Revisa más información sobre este proveedor accediendo a su{' '}
                  <Link alt="Link Ficha Proveedor" onClick={onRedirect}>
                    ficha en Mercado Público
                  </Link>
                </Typography>
              </>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoProvider.propTypes = {
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  provider: PropTypes.shape({
    businessName: PropTypes.string,
    isEnabled: PropTypes.bool,
    status: PropTypes.string
  }),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    providerDni: PropTypes.string,
    foreignSupplier: PropTypes.bool
  }),
  onOpenDialog: PropTypes.func,
  onRedirect: PropTypes.func
}

export default DirectDealStepTwoProvider
