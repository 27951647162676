import { addDays, format } from 'date-fns'

export const DIRECT_DEAL_FILTER_DEFAULT_ROWS = 10

export const DIRECT_DEAL_SEARCH_FILTER_INITIAL_DIFF_DAYS = 7
export const DIRECT_DEAL_SEARCH_FILTER_INITIAL_PAGE = 0

export const DIRECT_DEAL_SEARCH_ORDERS_DICTIONARY = {
  MOST_RECENT_PUBLISHED: 1,
  SOON_TO_CLOSE: 2
}

export const DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES = {
  cause: 'ALL',
  causeType: '',
  dateFrom: format(addDays(new Date(), -DIRECT_DEAL_SEARCH_FILTER_INITIAL_DIFF_DAYS), 'dd/MM/yyyy'),
  dateTo: format(new Date(), 'dd/MM/yyyy'),
  keyword: '',
  orderBy: DIRECT_DEAL_SEARCH_ORDERS_DICTIONARY.MOST_RECENT_PUBLISHED,
  page: DIRECT_DEAL_SEARCH_FILTER_INITIAL_PAGE,
  size: DIRECT_DEAL_FILTER_DEFAULT_ROWS,
  state: 'ALL'
}

export const DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_BUYER = {
  provider: ''
}

export const DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER = {
  checkReceived: false,
  checkSend: false,
  maxBudget: '',
  minBudget: '',
  organization: '',
  region: ''
}

export const DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC = {
  item: '',
  maxBudget: '',
  minBudget: '',
  organization: '',
  provider: '',
  region: ''
}

export const USER_TYPES_TRANSLATION_DICTIONARY = {
  Comprador: 'BUYER',
  Proveedor: 'SELLER'
}

export const USER_TYPES_DICTIONARY = {
  BUYER: 'BUYER',
  SELLER: 'SELLER'
}

export const DIRECT_DEAL_SEARCH_ORDERS = [
  { name: 'Publicados recientemente', value: '1' },
  { name: 'Próximos a cerrar', value: '2' }
]

export const CURRENCY_TRANSLATION = {
  CLP: {
    symbol: '$',
    maximumSignificantDigits: 0,
    thousandSeparator: '.',
    decimalSeparator: ',',
    cod: 'CLP',
    roundedDecimal: 100,
    name: 'Peso Chileno'
  },
  USD: {
    symbol: 'USD',
    maximumSignificantDigits: 2,
    thousandSeparator: ',',
    decimalSeparator: '.',
    cod: 'USD',
    roundedDecimal: 100,
    name: 'Dólar Estadounidense'
  },
  CLF: {
    symbol: 'UF',
    maximumSignificantDigits: 4,
    thousandSeparator: '.',
    decimalSeparator: ',',
    cod: 'CLF',
    roundedDecimal: 10000,
    name: 'Unidad de Fomento'
  },
  UTM: {
    symbol: 'UTM',
    maximumSignificantDigits: 4,
    thousandSeparator: '.',
    decimalSeparator: ',',
    cod: 'UTM',
    roundedDecimal: 10000,
    name: 'Unidad Tributaria Mensual'
  },
  EUR: {
    symbol: '€',
    maximumSignificantDigits: 2,
    thousandSeparator: ',',
    decimalSeparator: '.',
    cod: 'EUR',
    roundedDecimal: 100,
    name: 'Euro'
  }
}

export const BINARY_CONFIRMATION_RESPONSE = [
  {
    label: 'Si',
    value: '1',
    orden: 1
  },
  {
    label: 'No',
    value: '0',
    orden: 2
  }
]

export const DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS = [
  {
    label: 'Descuentos',
    value: '1'
  },
  {
    label: 'Cargos',
    value: '2'
  }
]

export const DIRECT_DEAL_STEP_TWO_TYPE_CONTRACT_OPTIONS = [
  {
    label: 'Bienes',
    value: '1'
  },
  {
    label: 'Servicios',
    value: '2'
  }
]

export const CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL = 3000

export const SNACKBAR_CLOSE_TIMEOUT = 500

export const DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED = `   De conformidad con lo señalado en el artículo 8 bis de la Ley N°19.886, los procedimientos de
                    contratación que se realicen invocando esta causal deberán acompañar el informe que justifique la
                    procedencia de la causal, en los términos establecidos en el artículo 8 bis de la Ley N°19.886`

export const DIRECT_DEAL_SHEET_STATUS_DICTIONARY = {
  CANCEL: 'Cancelada',
  CLOSED: 'Cerrada',
  ISSUE_PURCHASE: 'Orden de compra emitida',
  PUBLISH: 'Publicada',
  RECEIVING_REQUEST: 'Recibiendo Solicitud',
  REMOVE: 'Eliminada',
  SAVED: 'Guardada'
}

export const DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY = {
  CONFIRMATION: 'CONFIRMATION',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const DIRECT_DEAL_STEP_ZERO_OPTIONS = [
  {
    value: '1',
    label: 'Si, este Trato Directo proviene de una resolución firmada antes del 12 de diciembre de 2024'
  },
  {
    value: '2',
    label: 'No, este Trato Directo no proviene de una resolución firmada antes del 12 de diciembre de 2024 '
  }
]

export const DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_OPTIONS = [
  {
    value: '3',
    label: 'Continuar con el proveedor original y emitir la orden de compra de este Trato Directo'
  },
  {
    value: '1',
    label: 'Desistir y utilizar otro mecanismo de compra'
  },
  {
    value: '2',
    label: 'Desistir de la opción de realizar este Trato Directo'
  }
]

export const DIRECT_DEAL_SUMMARY_REQUEST_CONTRACT_STEP_DICTIONARY = {
  FORM: 'FORM',
  SUCCESS: 'SUCCESS'
}

export const DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY = {
  CONTINUE_WITH_ORIGINAL_PROVIDER: '3',
  USE_ANOTHER_MECHANISM: '1',
  STOP_PROCESS: '2'
}

export const DIRECT_DEAL_SUMMARY_DECISION_OTHER_MECHANISM_OPTIONS = [
  {
    value: 'Licitación',
    name: 'Licitación'
  },
  {
    value: 'Convenio Marco',
    name: 'Convenio Marco'
  },
  {
    value: 'Compra Ágil',
    name: 'Compra Ágil'
  }
]

export const DIRECT_DEAL_STEP_TWO_TAX_EXEMPT_DICTIONARY = {
  EXEMPT: 5,
  FREE_TRADE_ZONE: 20
}

export const DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE = '51'

export const DIRECT_DEAL_STEP_TWO_CONTRACT_DURATION_DICTIONARY = {
  IMMEDIATELY: 'Ejecución Inmediata',
  FOR_PERIOD: 'Ejecución en el tiempo'
}

export const PROTECT_BY_STEP_DICTIONARY = {
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  VOUCHER: 'VOUCHER'
}

export const STATUS_ABILITY_PROVIDER_DICTIONARY = {
  NOT_INFORMATION: 'SIN INFORMACIÓN',
  ABILITY: 'HÁBIL',
  NOT_ABILITY: 'INHÁBIL',
  IN_REVIEW: 'EN REVISIÓN'
}

export const DIRECT_DEAL_ORIGIN_DICTIONARY = {
  DIRECT_DEAL_BEFORE: '1',
  DIRECT_DEAL_AFTER: '2'
}

export const DIRECT_DEAL_INITIAL_VALUES_PRODUCT = {
  AMOUNT: '0',
  UNIT_MEASUREMENT: '',
  UNIT_PRICE: '0',
  WITH_DESCOUNT_OR_CHARGE: false,
  DISCOUNT_OR_CHARGE: '',
  DISCOUNT: '0',
  CHARGE: '0',
  ZGEN: '',
  DETAIL: ''
}

export const DIRECT_DEAL_SHEET_PURCHASE_STATUS_DICTIONARY = {
  SEND: 'Enviada a Proveedor',
  SAVED: 'Guardada',
  CANCEL: 'Cancelada',
  NOT_ACCEPTED: 'No Aceptada',
  RECEIVED_CONFORMITY: 'Recepción Conforme'
}

export const DIRECT_DEAL_REDIRECT_PURCHASE_ISSUE = '/PurchaseOrder/WizardCreateAndRedirect.aspx?POMODE=POEdit&porId='
export const DIRECT_DEAL_REDIRECT_PURCHASE_SHEET = '/PurchaseOrder/Modules/PO/DetailsPurchaseOrder.aspx?codigooc='

export const DIRECT_DEAL_DECLARATION_OLD_ORIGIN_DATE = '12/11/2024'
export const DIRECT_DEAL_DECLARATION_ORIGIN_DATE = '12/12/2024'

export const DIRECT_DEAL_HONORARIUM_PERCENT_DICTIONARY = {
  HONORARIUM: 27,
  HONORARIUM_BENEFIT_SII: 28
}

export const PARTICIPANT_LIST_PREFIX = 'TD'

export const DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  AHEAD: 'AHEAD',
  RETURN: 'RETURN'
}
