import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, Typography } from '@chilecompra/react-kit/components'
import { Breadcrumb, SpanCodeHeader, Root } from './DirectDealPageHeader.styles'

/**
 * The DirectDeal page header
 */
const DirectDealPageHeader = props => {
  const { breadcrumbs, code, description, step, title } = props

  return (
    <Root>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb, key) => (
          <Breadcrumb key={key} onClick={breadcrumb?.onRedirectTo} variant="text" tabIndex="0">
            {breadcrumb?.text}
          </Breadcrumb>
        ))}
      </Breadcrumbs>
      {step && (
        <Typography color="black3" fontWeight="bold" margin="28px 0 0" tabIndex="0" variant="caption">
          {step}
        </Typography>
      )}
      {title && (
        <Typography color="black1" fontWeight="bold" margin="4px 0 0" tabIndex="0" variant="h2">
          {title}
          {code && <SpanCodeHeader>{code}</SpanCodeHeader>}
        </Typography>
      )}
      {description}
    </Root>
  )
}

DirectDealPageHeader.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  code: PropTypes.string,
  description: PropTypes.node,
  step: PropTypes.string,
  title: PropTypes.string
}

export default DirectDealPageHeader
