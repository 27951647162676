import React from 'react'
import PropTypes from 'prop-types'

import { useViewport } from '@chilecompra/react-kit'
import { Typography } from '@chilecompra/react-kit/components'

import { CircleSuccessIcon, GridRow, GridColumn, LinkAddProduct, StatusWrapper } from './ProductCategorySearch.styles'

/**
 * The ProductCategorySearch's component.
 */
const ProductCategorySearch = props => {
  const { categoryId, handleAdd, id, isAdded, name } = props

  const {
    size: { isTiny }
  } = useViewport()

  return (
    <GridRow
      alignItems="center"
      border={isAdded}
      textAlign={isTiny && 'center'}
      background={isAdded}
      margin="12px 0"
      padding="10px 5px"
    >
      <GridColumn xs={12} sm={3}>
        <StatusWrapper justifyContent={isTiny && 'center'}>
          {isAdded ? <CircleSuccessIcon fontSize="24px" /> : ''}
          <LinkAddProduct aria-label="Agregar Producto/Servicio" onClick={handleAdd(id, name, categoryId)}>
            {!isAdded ? '+ Agregar al listado' : '+ Agregar nuevamente'}
          </LinkAddProduct>
        </StatusWrapper>
      </GridColumn>
      <GridColumn xs={12} sm={2}>
        <Typography color="gray1" fontSize="12px" variant="body2" tabIndex="0">
          {id}
        </Typography>
      </GridColumn>
      <GridColumn xs={12} sm={7}>
        <Typography color="black1" fontSize="12px" variant="body2" tabIndex="0">
          {name}
        </Typography>
      </GridColumn>
    </GridRow>
  )
}

ProductCategorySearch.propTypes = {
  categoryId: PropTypes.number.isRequired,
  handleAdd: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isAdded: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
}

export default ProductCategorySearch
