import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getCookie, Typography, usePathParam } from '@chilecompra/react-kit'

import BrandLayout from '../../components/Layouts/BrandLayout'
import routes from '../../config/settings/routes'
import { initializeReCaptcha } from '../../modules/hooks/reCaptcha'
import { PROTECT_BY_STEP_DICTIONARY } from '../../config/settings/constants'

import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealStepTwoContainer from '../../containers/DirectDealStepTwo/DirectDealStepTwo.container'
import StepFlowProviderContainer from '../../containers/StepFlowProvider/StepFlowProvider.container'
import StepFlowProviderContext from '../../containers/StepFlowProvider/StepFlowProvider.context'

import ProtectByProfile from '../../components/Security/ProtectByProfile'
import NotFound from '../NotFound/NotFound'
import ProtectByStep from '../../components/Security/ProtectByStep'
import DirectDealSearch from '../DirectDealSearch/DirectDealSearch'

import { Grid } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's page.
 */
const DirectDealStepTwo = () => {
  const navigate = useNavigate()
  const directDealCode = usePathParam('code')

  const directDealheading = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Trato Directo',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: `Crear Trato Directo`
      }
    ],
    step: 'PASO 2 DE 2',
    title: 'Crear ficha de Trato Directo',
    description: (
      <Typography color="black1" margin="5px 0 16px" variant="body2" tabIndex="0">
        Ingresa toda la información del Trato Directo que deseas realizar, la causal, justificación, productos o
        servicios, entre otros.
        <br />
        Al finalizar podrás continuar con la emisión de la orden de compra.
      </Typography>
    ),
    code: directDealCode
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {({ isPrivated }) => {
          if (isPrivated) initializeReCaptcha()
          return (
            <ProtectByProfile rule={userType => userType === 'Comprador'} fallback={() => <NotFound />}>
              <ProtectByStep step={PROTECT_BY_STEP_DICTIONARY.STEP_TWO} fallback={() => <DirectDealSearch />}>
                <StepFlowProviderContainer>
                  <StepFlowProviderContext.Consumer>
                    {() => (
                      <BrandLayout featured={<DirectDealPageHeader {...directDealheading} />}>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <DirectDealStepTwoContainer />
                          </Grid>
                        </Grid>
                      </BrandLayout>
                    )}
                  </StepFlowProviderContext.Consumer>
                </StepFlowProviderContainer>
              </ProtectByStep>
            </ProtectByProfile>
          )
        }}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealStepTwo
