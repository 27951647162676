import React, { useEffect } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'

import {
  checkInputErrorMaxLength,
  putCookie,
  useInput,
  useQuery,
  useSelect,
  useEffectOnce
} from '@chilecompra/react-kit'
import { Button, Card, Input, Select, Typography } from '@chilecompra/react-kit/components'

import {
  onGetCausesSearchThunk,
  onGetDirectDealThunk,
  onGetStatusSearchThunk,
  onSetCauseCriteria
} from './SearchAndFilterBar.actions'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import { DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES } from '../../config/settings/constants'
import { updateQuery } from '../../modules/utils/searches'
import useConditionalSearchNavigate from '../../modules/hooks/useCondicionalSearchNavigate'

import { GridContainer, GridItem, HeadingFiltersArea, SearchIconCustom } from './SearchAndFilterBar.styles'
/**
 * The SearchAndFilterBar's container.
 */
const SearchAndFilterBar = () => {
  const {
    causes: searchCauses,
    state: searchStatus,
    loading: searchLoading
  } = useSelector(state => state.searchFilters)
  const query = useQuery()
  const dispatch = useDispatch()
  const { isBuyer, isSeller } = useAuthorization()
  const { conditionalNavigate } = useConditionalSearchNavigate(isSeller, isBuyer)

  const queryString = query.toString()

  const setStatusByProfile = () => {
    if (query.has('state')) {
      return query.get('state')
    } else {
      if (isSeller) {
        return (
          searchStatus.find(status => status.name === 'Recibiendo Solicitud')?.value ||
          DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.state
        )
      } else {
        return DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.state
      }
    }
  }

  const {
    error: keywordError,
    value: keywordValue,
    onChange: keywordChange
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(50)],
    initialValue: query.has('keyword') ? query.get('keyword') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.keyword,
    overwriteCallback: () =>
      query.has('keyword') ? query.get('keyword') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.keyword
  })
  const {
    error: causeFromError,
    value: cause,
    onChange: handleCauseChange
  } = useSelect({
    initialValue: query.has('cause') ? query.get('cause') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.cause,
    overwriteCallback: () => (query.has('cause') ? query.get('cause') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.cause),
    changeCallback: updatedValue => dispatch(onSetCauseCriteria(updatedValue))
  })
  const {
    error: statusError,
    value: state,
    onChange: handleStatusChange,
    setValue: setStateValue
  } = useSelect({
    initialValue: query.has('state') ? query.get('state') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.state,
    overwriteCallback: () => (query.has('state') ? query.get('state') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.state)
  })

  const handleSearch = updatedValues => event => {
    event.preventDefault()
    const dictionary = {
      ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
      ...Object.fromEntries(query.entries()),
      keyword: updatedValues.keywordValue,
      cause: updatedValues.cause,
      state: updatedValues.state,
      page: DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.page
    }

    updateQuery(query, dictionary)
    putCookie('search', query.toString())
    conditionalNavigate(query)
  }

  useEffectOnce(() => {
    batch(() => {
      dispatch(onGetCausesSearchThunk())
      dispatch(onGetStatusSearchThunk())
    })
  })

  useEffect(() => {
    if (!queryString) {
      return
    }
    dispatch(onGetDirectDealThunk({ query }))
  }, [queryString])

  useEffect(() => {
    if (searchStatus && searchStatus.length > 0) {
      const stateBySeller = setStatusByProfile()

      const dictionary = {
        ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
        ...Object.fromEntries(query.entries()),
        state: stateBySeller,
        page: DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.page
      }

      updateQuery(query, dictionary)
      putCookie('search', query.toString())
      conditionalNavigate(query)
      setStateValue(stateBySeller)
    }
  }, [searchStatus])

  return (
    <HeadingFiltersArea>
      <Card padding="32px 24px 16px">
        <GridContainer spacing={1} alignItems="center">
          <GridItem xs={12}>
            <Typography variant="h4" fontWeight="bold" margin="0 0 10px" tabIndex="0">
              Búsqueda de Trato Directo
            </Typography>
          </GridItem>

          <GridItem lg={4} md={6} sm={12} xs={12}>
            <Input
              error={keywordError}
              label="Palabra clave o número de Trato Directo"
              loading={searchLoading}
              maxLength={30}
              minLength={2}
              value={keywordValue}
              onChange={keywordChange}
            />
          </GridItem>
          <GridItem lg={3} md={3} sm={12} xs={12}>
            <Select
              error={causeFromError}
              label="Causal de Trato Directo"
              placeholder="Todos las causales"
              loading={searchLoading}
              options={searchCauses}
              value={cause}
              onChange={handleCauseChange}
            />
          </GridItem>
          <GridItem lg={3} md={3} sm={12} xs={12}>
            <Select
              error={statusError}
              label="Estado"
              placeholder="Todos los estados"
              loading={searchLoading}
              options={searchStatus}
              value={state}
              onChange={handleStatusChange}
            />
          </GridItem>
          <GridItem lg={2} md={12} sm={12} xs={12}>
            <Button
              aria-label="Buscar"
              color="primary"
              loading={searchLoading}
              onClick={handleSearch({ cause, state, keywordValue })}
              width="100%"
            >
              Buscar <SearchIconCustom />
            </Button>
          </GridItem>
        </GridContainer>
      </Card>
    </HeadingFiltersArea>
  )
}

export default SearchAndFilterBar
