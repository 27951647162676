import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getCookie, Typography } from '@chilecompra/react-kit'

import BrandLayout from '../../components/Layouts/BrandLayout'
import routes from '../../config/settings/routes'
import { initializeReCaptcha } from '../../modules/hooks/reCaptcha'

import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealStepZeroContainer from '../../containers/DirectDealStepZero/DirectDealStepZero.container'
import StepFlowProviderContainer from '../../containers/StepFlowProvider/StepFlowProvider.container'
import StepFlowProviderContext from '../../containers/StepFlowProvider/StepFlowProvider.context'

import ProtectByProfile from '../../components/Security/ProtectByProfile'
import NotFound from '../NotFound/NotFound'

import { Grid } from './DirectDealStepZero.styles'

/**
 * The DirectDealStepZero's page.
 */
const DirectDealStepZero = () => {
  const navigate = useNavigate()

  const directDealheading = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Trato Directo',
        onRedirectTo: () => {
          const query = getCookie('search')
          navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
        }
      },
      {
        text: 'Crear Trato Directo'
      }
    ],
    step: 'PASO 0 DE 2',
    title: 'Crear nuevo Trato Directo',
    description: (
      <Typography color="black1" margin="5px 0 16px" variant="body2" tabIndex="0">
        Ingresa toda la información del Trato Directo que deseas realizar, la causal, justificación, productos o
        servicios, entre otros.
        <br />
        Al finalizar podrás continuar con la emisión de la orden de compra.
      </Typography>
    )
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {({ isPrivated }) => {
          if (isPrivated) initializeReCaptcha()
          return (
            <ProtectByProfile rule={userType => userType === 'Comprador'} fallback={() => <NotFound />}>
              <StepFlowProviderContainer>
                <StepFlowProviderContext.Consumer>
                  {() => (
                    <BrandLayout featured={<DirectDealPageHeader {...directDealheading} />}>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <DirectDealStepZeroContainer />
                        </Grid>
                      </Grid>
                    </BrandLayout>
                  )}
                </StepFlowProviderContext.Consumer>
              </StepFlowProviderContainer>
            </ProtectByProfile>
          )
        }}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealStepZero
