import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Input, Select, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRegEx,
  checkInputErrorRequired,
  useInput,
  useSelect
} from '@chilecompra/react-kit'

import { AmountPrefix, GridContainer, GridItem } from './DirectDealStepTwo.styles'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { cleanLeadingZero, formatInputByCurrency } from '../../modules/utils/formatters'
import { getRegexByCurrency, checkInputErrorCustomValue } from '../../modules/utils/checkers'

/**
 * DirectDealStepTwoTaxes's container
 */
const DirectDealStepTwoTaxes = props => {
  const { currency, formStepTwoRef, loading, storage, taxes } = props

  const {
    error: deliveryError,
    onChange: handleDeliveryChange,
    onError: handleDeliveryError,
    setValue: setDeliveryValue,
    value: deliveryValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))],
    formatCallbacks: [value => cleanLeadingZero(formatInputByCurrency(currency?.type, value))],
    initialValue: storage?.delivery || '0'
  })

  const {
    error: discountError,
    onChange: handleDiscountChange,
    onError: handleDiscountError,
    setValue: setDiscountValue,
    value: discountValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorRegEx(getRegexByCurrency(currency?.type)),
      checkInputErrorCustomValue(formStepTwoRef.current.total <= 0, 'El descuento no debe ser mayor al total')
    ],
    formatCallbacks: [value => cleanLeadingZero(formatInputByCurrency(currency?.type, value))],
    initialValue: storage?.discount || '0'
  })

  const {
    error: observationDiscountError,
    onChange: handleObservationDiscountChange,
    onError: handleObservationDiscountError,
    value: observationDiscountValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100)],
    initialValue: storage?.observationDiscount || ''
  })

  const {
    error: chargeError,
    onChange: handleChargeChange,
    onError: handleChargeError,
    setValue: setChargeValue,
    value: chargeValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))],
    formatCallbacks: [value => cleanLeadingZero(formatInputByCurrency(currency?.type, value))],
    initialValue: storage?.chargeValue || '0'
  })

  const {
    error: observationChargeError,
    onChange: handleObservationChargeChange,
    onError: handleObservationChargeError,
    value: observationChargeValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100)],
    initialValue: storage?.observationCharge || ''
  })

  const {
    error: taxesError,
    onChange: handleTaxesChange,
    onError: handleTaxesError,
    value: taxesValue
  } = useSelect({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: storage?.tax || ''
  })

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      charge: {
        error: chargeError,
        onError: handleChargeError,
        value: chargeValue
      },
      delivery: {
        error: deliveryError,
        onError: handleDeliveryError,
        value: deliveryValue
      },
      discount: {
        error: discountError,
        onError: handleDiscountError,
        value: discountValue
      },
      observationCharge: {
        error: observationChargeError,
        onError: handleObservationChargeError,
        value: observationChargeValue
      },
      observationDiscount: {
        error: observationDiscountError,
        onError: handleObservationDiscountError,
        value: observationDiscountValue
      },
      taxes: {
        error: taxesError,
        onError: handleTaxesError,
        value: taxesValue
      }
    }),
    [
      chargeError,
      chargeValue,
      deliveryError,
      deliveryValue,
      discountError,
      discountValue,
      observationChargeError,
      observationChargeValue,
      observationDiscountError,
      observationDiscountValue,
      taxesError,
      taxesValue
    ]
  )

  useEffect(() => {
    if (!storage?.coin || storage?.coin !== currency?.value) {
      setDeliveryValue('0')
      setDiscountValue('0')
      setChargeValue('0')
    } else {
      setDeliveryValue(storage?.delivery || '0')
      setDiscountValue(storage?.discount || '0')
      setChargeValue(storage?.chargeValue || '0')
    }
  }, [currency, storage])

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Despacho, impuestos, descuentos y cargos
        </Typography>
        <Typography fontWeight="regular" variant="body2" padding="8px 0 0" tabIndex="0">
          Aplican al total de los productos y servicios especificados.
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer spacing={1}>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={deliveryError}
              label="Despacho"
              prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
              loading={loading}
              value={deliveryValue}
              onChange={handleDeliveryChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12} />
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={discountError}
              label="Descuento"
              loading={loading}
              prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
              value={discountValue}
              onChange={handleDiscountChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={observationDiscountError}
              label="Observaciones"
              loading={loading}
              maxCount={100}
              value={observationDiscountValue}
              onChange={handleObservationDiscountChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Input
              error={chargeError}
              label="Cargos"
              loading={loading}
              prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
              value={chargeValue}
              onChange={handleChargeChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            {observationChargeError}
            <Input
              error={observationChargeError}
              label="Observaciones"
              loading={loading}
              maxCount={100}
              value={observationChargeValue}
              onChange={handleObservationChargeChange}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xd={12}>
            <Select
              error={taxesError}
              label="Impuestos"
              loading={loading}
              placeholder="Seleccione una opción"
              options={taxes}
              value={taxesValue}
              onChange={handleTaxesChange}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoTaxes.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    coin: PropTypes.string,
    delivery: PropTypes.string,
    discount: PropTypes.string,
    observationDiscount: PropTypes.string,
    chargeValue: PropTypes.string,
    observationCharge: PropTypes.string,
    tax: PropTypes.string
  }),
  taxes: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, name: PropTypes.string }))
}

export default DirectDealStepTwoTaxes
