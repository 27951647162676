import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Typography, Toast, useInput } from '@chilecompra/react-kit/components'
import { checkInputErrorRegEx, checkInputErrorRequired, useViewport } from '@chilecompra/react-kit'

import ProductSearchRow from './ProductSearch.row'
import { LETTERS_NUMBERS } from '../../modules/utils/regexes'

import { onGetProductsThunk, onAddProductSuccess } from './ProductSearch.actions'
import { Card, Divider, Grid, LineJump, NotFoundImg, Root } from './ProductSearch.styles'

/**
 * The ProductSearch's tab products.
 */
const ProductSearchTabProducts = () => {
  const { products, productsSaved, loading, error } = useSelector(store => store.productSearch)
  const dispatch = useDispatch()

  const {
    size: { isDownToSmall, isDownToTiny }
  } = useViewport()

  const {
    error: productsError,
    value: productsValue,
    onChange: handleProductsChange
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(LETTERS_NUMBERS)]
  })

  const handleOnSearchProduct = () => dispatch(onGetProductsThunk({ name: productsValue }))

  const handleAddProduct = (productId, productName, categoryId) => {
    const productKey = (Math.random() + 1).toString(36).substring(7)
    const newProduct = {
      amount: '0',
      categoryId,
      charge: '0',
      codeZGEN: '',
      detail: '',
      discount: '0',
      discountOrCharge: '',
      dontKnowCodeZGEN: false,
      isInvalid: true,
      productId,
      productKey,
      productName,
      unitMeasure: '',
      unitPrice: '0',
      withDiscountOrCharge: false
    }

    dispatch(onAddProductSuccess({ newProduct }))
  }

  return (
    <Root>
      <Grid container>
        <Grid item xs={12} md={6} lg={3}>
          <Typography
            color="black1"
            fontSize="14px"
            fontWeight="bold"
            lineHeight="18px"
            margin="0 0 4px"
            tabIndex="0"
            variant="body2"
          >
            Texto o ID de productos a buscar
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Input
            autoFocus
            error={productsError}
            onChange={handleProductsChange}
            placeholder="Ejm. Cuaderno"
            type="text"
            value={productsValue}
          />
          <Typography color="gray1" fontSize="12px" lineHeight="14px" variant="subtitle" tabIndex="0">
            Para refinar tu búsqueda puedes utilizar los siguientes comandos: <LineJump />
            - 'Texto': Búsqueda exacta <LineJump />
            - Texto*: Palabras que comienzan con <LineJump />- *texto*: Palabras que contienen
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg container justifyContent={isDownToSmall ? 'flex-start' : 'flex-end'}>
          <Button
            aria-label="Buscar"
            color="primary"
            disabled={productsError !== '' || loading}
            loading={loading}
            margin={isDownToSmall ? '10px 0 0' : '0'}
            onClick={handleOnSearchProduct}
            type="button"
            variant="outlined"
            width={isDownToTiny ? '100%' : undefined}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      {!loading && error && error?.reason === 'SOMETHING_WENT_WRONG_ERROR' && (
        <Grid container margin="16px 0 24px">
          <Grid item xs={12}>
            <Toast severity="error" title="Lo sentimos, estamos presentando problemas con nuestro servidor.">
              En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos
              minutos más.
            </Toast>
          </Grid>
        </Grid>
      )}
      {!loading && error && error?.reason === 'NOT_FOUND_ERROR' && (
        <Grid container margin="16px 0 24px">
          <Grid item xs={12}>
            <Card color="blank" padding="75.5px 0 74.5px" textAlign="center">
              <NotFoundImg alt="No hay resultados" src="/assets/images/nofound.svg" />
              <Typography fontWeight="bold" margin="8px 0" variant="h4" tabIndex="0">
                No se encontraron resultados para tu búsqueda
              </Typography>
              <Typography color="black3" fontSize="14px" lineHeight="18px" variant="body1" tabIndex="0">
                El producto o servicio que buscas no se encuentra en
                <br />
                Mercado Público. Intenta con otra palabra clave u
                <br />
                otro criterio de búsqueda.
              </Typography>
            </Card>
          </Grid>
        </Grid>
      )}
      {products && (
        <>
          <LineJump />
          {products.map((category, i) => (
            <Grid key={category.name}>
              <Grid item sm={12}>
                <Typography color="black1" fontWeight="bold" key={category.name} variant="body2" tabIndex="0">
                  {category.name}
                </Typography>
              </Grid>
              {category.products.map(product => (
                <ProductSearchRow
                  categoryId={product.categoryId}
                  onAddProduct={handleAddProduct}
                  key={product.id}
                  isAdded={productsSaved.some(added => added.productId === product.id)}
                  productId={product.id}
                  productName={product.name}
                />
              ))}
              {products.length - 1 !== i && isDownToTiny && <Divider />}
              <LineJump />
            </Grid>
          ))}
        </>
      )}
    </Root>
  )
}

export default ProductSearchTabProducts
