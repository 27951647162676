import {
  CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO,
  GET_INFO_DNI_SIGNER_ERROR,
  GET_INFO_DNI_SIGNER_SUCCESS,
  GET_INFO_DNI_SIGNER,
  GET_SAVED_DIRECT_DEAL_STEP_ZERO_ERROR,
  GET_SAVED_DIRECT_DEAL_STEP_ZERO_SUCCESS,
  GET_SAVED_DIRECT_DEAL_STEP_ZERO,
  RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO,
  SAVED_SHEET_DIRECT_DEAL_STEP_ZERO
} from './DirectDealStepZero.actions'

const directDealStepZeroState = {
  error: undefined,
  loading: false,
  storage: null
}

/**
 * The DirectDealStepOne reducer.
 */
const directDealStepZeroReducer = (state = directDealStepZeroState, { payload, type }) => {
  switch (type) {
    case GET_SAVED_DIRECT_DEAL_STEP_ZERO:
    case GET_INFO_DNI_SIGNER: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_SAVED_DIRECT_DEAL_STEP_ZERO_ERROR:
    case GET_INFO_DNI_SIGNER_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_INFO_DNI_SIGNER_SUCCESS: {
      return { ...state, error: undefined, loading: false }
    }
    case RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, storage: payload }
    }
    case CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, storage: undefined }
    }
    case GET_SAVED_DIRECT_DEAL_STEP_ZERO_SUCCESS: {
      return { ...state, storage: payload.sheetStepZero, loading: false }
    }
    case SAVED_SHEET_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, storage: payload }
    }
    default: {
      return state
    }
  }
}

export default directDealStepZeroReducer
