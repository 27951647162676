import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'

import { Grid, GridItem, ContainerCheck, MainCircle, StyledCloseIcon } from '../DirectDealStepOne.styles'

/**
 * The DirectDealStepOne's saved error dialog.
 */
const DirectDealStepOneSavedErrorDialog = props => {
  const { onClose, open } = props

  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onClose}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <GridItem textAlign="center" xs={12}>
          <ContainerCheck style={{ display: 'flex', justifyContent: 'center' }} margin="0 0 18px">
            <MainCircle backgroundColor="error">
              <StyledCloseIcon />
            </MainCircle>
          </ContainerCheck>
          <Typography variant="h3" fontWeight="bold" color="black1" tabIndex="0">
            La ficha no pudo ser guardada
          </Typography>
        </GridItem>
        <GridItem textAlign="center" xs={12} margin="2px 0 0">
          <Typography variant="body2" color="black1" tabIndex="0">
            Comprueba que hayas ingresado toda la información requerida y vuelve a intentarlo dentro de unos minutos.
          </Typography>
        </GridItem>
        <GridItem padding="0 50px" margin="38px 0 4px" textAlign="center" xs={12}>
          <Button aria-label="Volver" variant="outlined" color="default" onClick={onClose}>
            Volver
          </Button>
        </GridItem>
      </Grid>
    </Dialog>
  )
}

DirectDealStepOneSavedErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
}

export default DirectDealStepOneSavedErrorDialog
