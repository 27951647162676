import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, Input, Textarea, Typography } from '@chilecompra/react-kit/components'
import { useInput, useTextarea, useViewport } from '@chilecompra/react-kit'

import AttachmentsWrapper from '../../../components/components/AttachmentsWrapper.component'

import { Grid } from '../DirectDealSummary.styles'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../../config/settings/constants'

/**
 * The DirectDealSummaryResquest dialog
 */
const DirectDealSummaryRequestDialog = props => {
  const { contracts, loading, onClosed, requestId } = props

  const { size } = useViewport()

  const [subBusinessRequest, setSubBusinessRequest] = useState()
  const [idBusinessRequest, setIdBusinessRequest] = useState()

  const {
    setValue: setAnswerRequest,
    value: answerRequestValue,
    onChange: onChangeAnswerRequest
  } = useTextarea({ initialValue: '' })
  const {
    setValue: setDniRequest,
    value: dniRequestValue,
    onChange: onChangeDniRequest
  } = useInput({ initialValue: '' })
  const {
    setValue: setBusinessRequest,
    value: businessRequestValue,
    onChange: onChangeBusinessRequest
  } = useInput({ initialValue: '' })

  useEffect(() => {
    if (requestId) {
      const requestDetail = contracts.requests.find(req => req.id === requestId)
      setAnswerRequest(requestDetail?.answer)
      setDniRequest(requestDetail?.dni)
      setBusinessRequest(requestDetail?.legalName)
      setSubBusinessRequest(requestDetail?.subBusiness)
      setIdBusinessRequest(requestDetail?.idBusiness)
    }
  }, [requestId])

  return (
    <Dialog
      open={contracts?.openDetail || false}
      disableBackdropClick
      verticalAlign="top"
      maxWidth={size.isTiny || size.isSmall ? '400px' : '600px'}
      onClose={onClosed}
    >
      <Grid container spacing={2}>
        <Grid item margin="0 0 4px">
          <Typography variant="h3" fontWeight="bold" color="black1" lineHeight="18px" margin="8px 0" tabIndex="0">
            Solicitud de un nuevo proceso de contratación
          </Typography>
        </Grid>
        <Grid item>
          <Textarea
            disabled
            label="Repuesta ingresada"
            loading={loading}
            size="medium"
            value={answerRequestValue}
            onChange={onChangeAnswerRequest}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Input disabled label="RUT" loading={loading} value={dniRequestValue} onChange={onChangeDniRequest} />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Input
            disabled
            label="Razón social"
            loading={loading}
            value={businessRequestValue}
            onChange={onChangeBusinessRequest}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" fontWeight="bold" tabIndex="0">
            Evidencia Adjunta
          </Typography>
          <AttachmentsWrapper
            business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
            subBusiness={subBusinessRequest}
            idBusiness={idBusinessRequest}
            isPrivate
            canRead
            color="blank"
            showIconList
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Button
            aria-label="Cerrar"
            variant="contained"
            width={size.isSmall || size.isTiny ? '100%' : '60%'}
            onClick={onClosed}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DirectDealSummaryRequestDialog.propTypes = {
  contracts: PropTypes.shape({
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dni: PropTypes.string,
        isActive: PropTypes.bool
      })
    ),
    openDetail: PropTypes.bool
  }),
  loading: PropTypes.bool,
  onClosed: PropTypes.func,
  requestId: PropTypes.string
}

export default DirectDealSummaryRequestDialog
