import { batch } from 'react-redux'

import { getIdentity } from '../../services/config'
import { getSavedDirectDealSearch } from '../../services/directdeal'

import { makeActionCreator } from '../../config/store/utils'
import { BINARY_CONFIRMATION_RESPONSE, DIRECT_DEAL_ORIGIN_DICTIONARY } from '../../config/settings/constants'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

export const GET_INFO_DNI_SIGNER = 'GET_INFO_DNI_SIGNER'
export const GET_INFO_DNI_SIGNER_SUCCESS = 'GET_INFO_DNI_SIGNER_SUCCESS'
export const GET_INFO_DNI_SIGNER_ERROR = 'GET_INFO_DNI_SIGNER_ERROR'

export const onGetSignerInfoDNI = makeActionCreator(GET_INFO_DNI_SIGNER, 'payload')
export const onGetSignerInfoDNISuccess = makeActionCreator(GET_INFO_DNI_SIGNER_SUCCESS)
export const onGetSignerInfoDNIError = makeActionCreator(GET_INFO_DNI_SIGNER_ERROR, 'payload')

export const onGetSignerInfoDNIThunk = payload => async dispatch => {
  dispatch(onGetSignerInfoDNI())

  try {
    const { data } = await getIdentity(payload)

    return batch(() => {
      dispatch(onGetSignerInfoDNISuccess())
      payload.callback({
        names: data?.payload.nombres || '',
        lastNames: `${data?.payload.apellidoPaterno || ''} ${data?.payload.apellidoMaterno || ''}`
      })
    })
  } catch (err) {
    return batch(() => {
      dispatch(onGetSignerInfoDNIError({ err }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible encontrar información',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}

export const RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO = 'RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO'
export const onReturnInfoStepZeroDirectDealStepZero = makeActionCreator(
  RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO,
  'payload'
)

export const CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO = 'CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO'
export const onCleanInitialSheetDealStepZero = makeActionCreator(CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO)

export const GET_SAVED_DIRECT_DEAL_STEP_ZERO = 'GET_SAVED_DIRECT_DEAL_STEP_ZERO'
export const GET_SAVED_DIRECT_DEAL_STEP_ZERO_ERROR = 'GET_SAVED_DIRECT_DEAL_STEP_ZERO_ERROR'
export const GET_SAVED_DIRECT_DEAL_STEP_ZERO_SUCCESS = 'GET_SAVED_DIRECT_DEAL_STEP_ZERO_SUCCESS'

export const onGetSavedDirectDealStepZero = makeActionCreator(GET_SAVED_DIRECT_DEAL_STEP_ZERO)
export const onGetSavedDirectDealStepZeroError = makeActionCreator(GET_SAVED_DIRECT_DEAL_STEP_ZERO_ERROR, 'payload')
export const onGetSavedDirectDealStepZeroSuccess = makeActionCreator(GET_SAVED_DIRECT_DEAL_STEP_ZERO_SUCCESS, 'payload')

export const onGetSavedDirectDealStepZeroThunk = payload => async dispatch => {
  dispatch(onGetSavedDirectDealStepZero())

  try {
    const { data } = await getSavedDirectDealSearch({
      actionType: GET_SAVED_DIRECT_DEAL_STEP_ZERO,
      directDealCode: payload.directDealCode
    })

    return batch(() => {
      dispatch(
        onGetSavedDirectDealStepZeroSuccess({
          sheetStepZero: {
            resolution: data?.payload?.firmante?.resolucion || '',
            resolutionDate: data?.payload?.firmante?.fechaResolucion || '',
            names: data?.payload?.firmante?.nombres || '',
            lastNames: data?.payload?.firmante?.apellidos || '',
            dni: data?.payload?.firmante?.rut || '',
            job: data?.payload?.firmante?.cargo || '',
            origin: data?.payload?.origen
              ? DIRECT_DEAL_ORIGIN_DICTIONARY.DIRECTs_DEAL_BEFORE
              : DIRECT_DEAL_ORIGIN_DICTIONARY.DIRECT_DEAL_AFTER
          }
        })
      )
      payload.callback({
        stepOne: {
          name: data?.payload?.requirente?.nombres || '',
          dni: data?.payload?.requirente?.rut || '',
          lastName: data?.payload?.requirente?.apellidos || '',
          job: data?.payload?.requirente?.cargo || '',
          email: data?.payload?.requirente?.correo || '',
          cause: data?.payload?.causal?.id,
          amount: data?.payload?.esMayor1000UTM
            ? BINARY_CONFIRMATION_RESPONSE[0].value
            : BINARY_CONFIRMATION_RESPONSE[1].value,
          withPublicity: data?.payload?.conPublicidad
        },
        stepTwo: {
          nameSheet: data?.payload?.nombre || '',
          address: data?.payload?.despacho?.direccion || '',
          justification: data?.payload?.justificacion || '',
          city: data?.payload?.despacho?.disCodeComuna,
          code: data?.payload?.codigoTratoDirecto,
          coin: data?.payload?.moneda || null,
          comment: data?.payload?.despacho?.comentarios || '',
          contractCategory: data?.payload?.categoriaContrato?.toString() || '',
          contractType: data?.payload?.tipoContrato?.id?.toString() || '',
          dateFinishExecute: data?.payload?.fechaEjecucion
            ? new Date(data?.payload?.fechaEjecucion?.split('+')[0])
            : null,
          delivery: data?.payload?.costo?.montoDespacho?.toString() || 0,
          chargeValue: data?.payload?.costo?.montoCargo?.toString() || 0,
          description: data?.payload?.descripcion,
          discount: data?.payload?.costo?.montoDescuento?.toString() || 0,
          duration: data?.payload?.duracionContrato?.nombre,
          observationCharge: data?.payload?.costo?.obsCargo,
          observationDiscount: data?.payload?.costo?.obsDescuento,
          providerDni: data?.payload?.rutProveedor,
          region: data?.payload?.despacho?.disCode,
          tax: data?.payload?.costo?.taxCode,
          total: data?.payload?.montoTotal || 0
        },
        pricing:
          data?.payload?.cotizaciones.map(item => ({
            idQuote: item?.id,
            contributorDni: item?.rutCotizante,
            contributorLegalName: item?.nombreCotizante,
            contributorPhone: item?.telefonoCotizante,
            contributorEmail: item?.correoCotizante,
            idBusiness: item?.idAdjunto,
            idSubBusiness: item?.idBusiness
          })) || [],
        products:
          data?.payload?.productos.map(item => ({
            amount: item?.cantidad?.toString() || 0,
            categoryId: item?.codCategoria,
            charge: item?.cargo?.toString() || 0,
            codeZGEN: item?.zgen,
            detail: item?.descripcion,
            discount: item?.descuento?.toString() || 0,
            discountOrCharge: item?.descuento ? '1' : item?.cargo ? '2' : '',
            dontKnowCodeZGEN: item?.noConoceCodigoZgen,
            isInvalid: false,
            productKey: item?.codUnicoProducto,
            productId: item?.codProducto,
            productName: item?.nombreProductoServicio,
            unitMeasure: item?.unidadMedida,
            unitPrice: item?.valorUnitario?.toString() || 0,
            withDiscountOrCharge: !!(item?.descuento || item?.cargo)
          })) || []
      })
    })
  } catch (error) {
    return dispatch(
      onGetSavedDirectDealStepZeroError({
        error: {
          code: error.code,
          message: error.message
        }
      })
    )
  }
}

export const SAVED_SHEET_DIRECT_DEAL_STEP_ZERO = 'SAVED_SHEET_DIRECT_DEAL_STEP_ZERO'
export const onSavedSheetDealStepZero = makeActionCreator(SAVED_SHEET_DIRECT_DEAL_STEP_ZERO, 'payload')
