import { factoryService, postJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * POST: Issue purchase to direct deal sheet
 */
export const postIssuePurchaseToSheet = factoryService(({ sheetCode }) => {
  const body = {
    codigoTratoDirecto: sheetCode
  }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/ordencompra/generar`, { body })
})
