import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import {
  ContainerIconCircle,
  GridContainer,
  GridItem,
  MainCircle,
  StyledPriorityIcon
} from '../DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's confirm dialog.
 */
const DirectDealStepTwoConfirmDialog = props => {
  const { loading, onCreate, onClose, open } = props

  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onClose}>
      <GridContainer direction="row" justifyContent="center" alignItems="center">
        <GridItem textAlign="center" padding="10px" xs={12}>
          <ContainerIconCircle style={{ display: 'flex', justifyContent: 'center' }} margin="0 0 18px">
            <MainCircle backgroundColor="warning">
              <StyledPriorityIcon />
            </MainCircle>
          </ContainerIconCircle>
          <Typography variant="h3" lineHeight="22px" fontWeight="bold" color="black1" tabIndex="0">
            Estás a punto de crear una ficha de Trato Directo
          </Typography>
        </GridItem>
        <GridItem textAlign="center" xs={12}>
          <Typography variant="body2" lineHeight="21px" color="black1" padding="0 40px" tabIndex="0">
            Una vez publicada la ficha, los proveedores podrán ingresar consultas y hacer observaciones, solicitando la
            realización de la compra por otro mecanismo de compra.
          </Typography>
        </GridItem>
        <GridItem padding="0 50px" margin="38px 0 0" textAlign="center" xs={12}>
          <Button aria-label="Crear ficha" color="success" variant="contained" onClick={onCreate} loading={loading}>
            Crear ficha
          </Button>
        </GridItem>
        <GridItem padding="0 50px" margin="4px 0 10px" textAlign="center" xs={12}>
          <Button aria-label="Volver" variant="text" onClick={onClose} loading={loading}>
            Volver
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  )
}

DirectDealStepTwoConfirmDialog.propTypes = {
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
}

export default DirectDealStepTwoConfirmDialog
