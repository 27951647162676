const routes = {
  directDealStepZero: '/trato-directo-paso-cero/:code?',
  directDealStepOne: '/trato-directo-paso-uno/:code?',
  directDealStepTwo: '/trato-directo-paso-dos/:code',
  directDealSummary: '/resumen-ficha/:directDealId',
  directDealSummaryPublic: '/resumen-ficha-publica/:directDealId',
  directDealVoucher: '/trato-directo-voucher',
  directDealQuoteCreate: '/crear-cotizacion',
  directDealQuoteEdit: '/editar-cotizacion',
  productSearch: '/buscador-productos-y-servicios',
  root: '/',
  search: '/buscador',
  searchPublic: '/buscador-publico'
}

export default routes
