import React from 'react'
import PropTypes from 'prop-types'

import { Card, Typography } from '@chilecompra/react-kit'

import FormattedCurrency from './../../../components/components/FormattedCurrency.component'

import { GridContainer, GridItem } from '../DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo totals container.
 */
const DirectDealStepTwoTotals = props => {
  const { currency, netTotal } = props

  return (
    <Card padding="17px 0">
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="body2" color="black1" tabIndex="0">
            Total Neto
          </Typography>
          <Typography variant="subtitle" color="black1" margin="0 0 8px" tabIndex="0">
            con descuento o cargo
          </Typography>
          <Typography variant="body1" color="black1" tabIndex="0">
            <FormattedCurrency amount={netTotal} includeSymbol currency={currency?.type} />
          </Typography>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

DirectDealStepTwoTotals.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  netTotal: PropTypes.number
}

export default DirectDealStepTwoTotals
