import React, { useMemo } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getCookie, useEffectOnce, usePathParam, useViewport } from '@chilecompra/react-kit'

import DirectDealBasicBackground from './DirectDealStepOne.basicbackground'
import DirectDealRequester from './DirectDealStepOne.requester'
import DirectDealStepOneFooter from './DirectDealStepOne.footer'

import DirectDealStepOneCreateSuccessDialog from './Dialogs/DirectDealStepOne.create.success.dialog'
import DirectDealStepOneSavedErrorDialog from './Dialogs/DirectDealStepOne.saved.error.dialog'
import DirectDealStepOneSavedSuccessDialog from './Dialogs/DirectDealStepOne.saved.success.dialog'

import {
  onCleanInfoStepZeroDirectDealStepOne,
  onCloseConfirmStepOneDialog,
  onCloseSavedFailedStepOneDialog,
  onCloseSavedStepOneDialog,
  onCreateDirectDealThunk,
  onGetDescriptionCausesStepOneThunk,
  onGetUTMStepOneThunk,
  onOpenConfirmStepOneDialog,
  onOpenSavedFailedStepOneDialog,
  onOpenSavedStepOneDialog,
  onResetStepOne,
  onSavedDraftDirectDealStepOneThunk
} from './DirectDealStepOne.actions'
import { onSavedInfoStepOneDirectDealStepTwo } from '../DirectDealStepTwo/DirectDealStepTwo.actions'
import { onSetDirectionStepZeroFlow } from '../StepFlowProvider/StepFlowProvider.actions'

import { Root, Divider } from './DirectDealStepOne.styles'

import { DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY } from '../../config/settings/constants'
import routes from '../../config/settings/routes'

import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'
import { useFormRef } from '../../modules/hooks/useFormRef'

import { useStepFlow } from '../StepFlowProvider/StepFlowProvider.hooks'

/**
 * The DirectDealStepOne's container.
 */
const DirectDealStepOneContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formStepOneRef = useFormRef()
  const { size } = useViewport()
  const directDealCode = usePathParam('code')
  const { flowMain, flowStepOne } = useStepFlow()

  const {
    loadings: directDealStepOneLoadings,
    utm: directDealStepOneUTM,
    dialogs: directDealStepOneDialogs,
    sheetCode: directDealStepOneSheetCode,
    causes: directDealStepOneCauses,
    stepZero: directDealStepZero,
    storage: directDealStepOneValues
  } = useSelector(state => state.directDealStepOne)

  const generateRequestStepOne = form => {
    return {
      name: form.name?.value,
      cause: form.cause?.value,
      amount: form.greaterThanThounsand?.value,
      dni: form.dni?.value,
      lastName: form.lastName?.value,
      job: form.job?.value,
      email: form.email?.value,
      withPublicity: form.withPublicity,
      withTechnicalReport: form.withTechnicalReport
    }
  }
  const generateRequestStepZero = form => {
    return {
      resolution: form?.resolution || '',
      resolutionDate: form?.resolutionDate || '',
      names: form?.names || '',
      lastNames: form?.lastNames || '',
      dni: form?.dni || '',
      job: form?.job || '',
      originDirectDeal: form?.origin
    }
  }

  const handlerBack = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
  }
  const handlerBackStepZero = () => {
    batch(() => {
      dispatch(onCleanInfoStepZeroDirectDealStepOne())
      dispatch(onSetDirectionStepZeroFlow({ direction: DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.RETURN }))

      navigate(routes.directDealStepZero.replace(/:code/, directDealCode || directDealStepOneSheetCode || ''), {
        replace: true
      })
    })
  }
  const handlerNext = () => {
    if (isInvalidStepOne) {
      formStepOneRef.current?.cause?.onError()
      formStepOneRef.current?.dni?.onError()
      formStepOneRef.current?.email?.onError()
      formStepOneRef.current?.job?.onError()
      formStepOneRef.current?.name?.onError()
      formStepOneRef.current?.lastName?.onError()

      return
    }

    dispatch(
      onCreateDirectDealThunk({
        sheetCode: directDealCode || directDealStepOneSheetCode,
        stepOne: generateRequestStepOne(formStepOneRef.current),
        stepZero: generateRequestStepZero(directDealStepZero),
        callback: sheet => {
          dispatch(onSavedInfoStepOneDirectDealStepTwo(sheet))

          if (directDealCode || directDealStepOneSheetCode) {
            handlerRedirectStepTwo()
          } else {
            dispatch(onOpenConfirmStepOneDialog())
          }

          dialogScrollTop()
        },
        callbackError: () => {
          dispatch(onOpenSavedFailedStepOneDialog())
          dialogScrollTop()
        }
      })
    )
  }
  const handlerRedirectStepTwo = () => {
    navigate(
      { pathname: routes.directDealStepTwo.replace(/:code/, directDealCode || directDealStepOneSheetCode) },
      { replace: true }
    )
  }
  const handleSave = () => {
    if (isNotAllowSavedStepOne) {
      formStepOneRef.current?.dni?.onError()
      formStepOneRef.current?.email?.onError()
      return
    }

    dispatch(
      onSavedDraftDirectDealStepOneThunk({
        sheetCode: directDealCode || directDealStepOneSheetCode,
        stepOne: generateRequestStepOne(formStepOneRef.current),
        stepZero: generateRequestStepZero(directDealStepZero),
        callback: () => {
          dispatch(onOpenSavedStepOneDialog())
          dialogScrollTop()
        },
        callbackError: () => {
          dispatch(onOpenSavedFailedStepOneDialog())
          dialogScrollTop()
        }
      })
    )
  }
  const handleCloseSavedErrorDialog = () => {
    dispatch(onCloseSavedFailedStepOneDialog())
  }
  const handleCloseSavedDialog = () => {
    dispatch(onCloseSavedStepOneDialog())
  }
  const handleCloseCreateDialog = () => {
    dispatch(onCloseConfirmStepOneDialog())
  }

  const isNotAllowSavedStepOne = useMemo(() => {
    return (
      (formStepOneRef.current.dni?.error !== '' && formStepOneRef.current.dni?.value !== '') ||
      (formStepOneRef.current.email?.error !== '' && formStepOneRef.current.email?.value !== '')
    )
  }, [
    formStepOneRef.current.dni?.value,
    formStepOneRef.current.dni?.error,
    formStepOneRef.current.email?.value,
    formStepOneRef.current.email?.error
  ])
  const isInvalidStepOne = useMemo(() => {
    return (
      formStepOneRef.current.name?.value === '' ||
      formStepOneRef.current.name?.error !== '' ||
      formStepOneRef.current.cause?.value === '' ||
      formStepOneRef.current.cause?.error !== '' ||
      formStepOneRef.current.greaterThanThounsand?.value === '' ||
      formStepOneRef.current.dni?.value === '' ||
      formStepOneRef.current.dni?.error !== '' ||
      formStepOneRef.current.lastName?.value === '' ||
      formStepOneRef.current.lastName?.error !== '' ||
      formStepOneRef.current.job?.value === '' ||
      formStepOneRef.current.job?.error !== '' ||
      formStepOneRef.current.email?.value === '' ||
      formStepOneRef.current.email?.error !== ''
    )
  }, [
    formStepOneRef.current.name?.value,
    formStepOneRef.current.name?.error,
    formStepOneRef.current.cause?.value,
    formStepOneRef.current.cause?.error,
    formStepOneRef.current.greaterThanThounsand?.value,
    formStepOneRef.current.dni?.value,
    formStepOneRef.current.dni?.error,
    formStepOneRef.current.lastName?.value,
    formStepOneRef.current.lastName?.error,
    formStepOneRef.current.job?.value,
    formStepOneRef.current.job?.error,
    formStepOneRef.current.email?.value,
    formStepOneRef.current.email?.error
  ])

  useEffectOnce(() => {
    batch(() => {
      dispatch(onGetUTMStepOneThunk())
      dispatch(onGetDescriptionCausesStepOneThunk(directDealStepZero?.origin || ''))

      dispatch(onCloseConfirmStepOneDialog())
      dispatch(onCloseSavedFailedStepOneDialog())

      if (
        flowMain === DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.CREATE &&
        flowStepOne === DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.AHEAD
      ) {
        dispatch(onResetStepOne())
      }
    })
  })

  return (
    <Root>
      <Divider width="100%" />
      <DirectDealBasicBackground
        causes={directDealStepOneCauses}
        formRef={formStepOneRef}
        loading={directDealStepOneLoadings.page}
        origin={directDealStepZero?.origin}
        utm={directDealStepOneUTM}
        values={directDealStepOneValues}
      />
      <Divider width={size.isTiny || size.isSmall ? '100%' : '67%'} />
      <DirectDealRequester
        formRef={formStepOneRef}
        loading={directDealStepOneLoadings.requester || directDealStepOneLoadings.page}
        values={directDealStepOneValues}
      />
      <Divider width="100%" />
      <DirectDealStepOneFooter
        loading={directDealStepOneLoadings.requester || directDealStepOneLoadings.page}
        onBack={handlerBackStepZero}
        onContinue={handlerNext}
        onSave={handleSave}
      />

      <DirectDealStepOneCreateSuccessDialog
        open={directDealStepOneDialogs.isOpenCreation}
        sheetCode={directDealStepOneSheetCode}
        onBack={handlerBack}
        onClose={handleCloseCreateDialog}
        onNext={handlerRedirectStepTwo}
      />
      <DirectDealStepOneSavedErrorDialog
        open={directDealStepOneDialogs.isOpenFailed}
        onClose={handleCloseSavedErrorDialog}
      />
      <DirectDealStepOneSavedSuccessDialog
        open={directDealStepOneDialogs.isOpenSaved}
        onBack={handlerBack}
        onClose={handleCloseSavedDialog}
        sheetCode={directDealStepOneSheetCode}
      />
    </Root>
  )
}

export default DirectDealStepOneContainer
