import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Card, Typography } from '@chilecompra/react-kit/components'

import routes from '../../config/settings/routes'

import { onSetInitialCreateFlow } from '../StepFlowProvider/StepFlowProvider.actions'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import { Column, Div, Row, Link, Image } from './NewDirectDealBox.styles'

/**
 * The NewDirectDealBox's container.
 */
const NewDirectDealBox = () => {
  const { isBuyer, isSeller } = useAuthorization()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handlerNewDirectDeal = event => {
    event.preventDefault()

    dispatch(onSetInitialCreateFlow())
    navigate(routes.directDealStepZero.replace(/:code/, ''), {
      replace: true
    })
  }

  return (
    <Div>
      {isBuyer && (
        <Card padding="16px" color="success" height="100%">
          <Row>
            <Column xs={12}>
              <Image alt="Agregar nuevo trato directo" src="/assets/images/direct-dealSheet.svg" />
              <Typography variant="h3" fontWeight="bold" padding="8px00" color="black1" tabIndex="0">
                Nuevo Trato Directo
              </Typography>
              <Typography variant="body2" fontWeight="regular" padding="8px 0 0" color="black1" tabIndex="0">
                Publica la contratación excepcional que deseas realizar.
              </Typography>
              <Typography variant="subtitle" fontWeight="regular" padding="8px 0 0">
                <Link href="#" onClick={handlerNewDirectDeal}>
                  Crear nuevo Trato Directo
                </Link>
              </Typography>
            </Column>
          </Row>
        </Card>
      )}
      {!isBuyer && !isSeller && (
        <Card padding="16px" color="blank" enableBorder height="100%">
          <Row>
            <Column xs={12}>
              <Typography variant="h4" fontWeight="bold" padding="8px 0 0" tabIndex="0">
                ¿Qué es Trato Directo?
              </Typography>
              <Typography variant="body2" fontWeight="regular" padding="8px 0 0" tabIndex="0">
                Mecanismo excepcional de compra que implica la contratación de un sólo proveedor previa resolución
                fundada o decreto alcaldicio.
              </Typography>
            </Column>
          </Row>
        </Card>
      )}
    </Div>
  )
}

export default NewDirectDealBox
