import { useContext } from 'react'
import StepFlowProviderContext from './StepFlowProvider.context'

/**
 * The authorization custom hook.
 */
export const useStepFlow = () => {
  const { flowMain, flowStepOne, flowStepTwo, flowStepZero } = useContext(StepFlowProviderContext)

  return { flowMain, flowStepOne, flowStepTwo, flowStepZero }
}
