import { batch } from 'react-redux'

import {
  getContractDuration,
  getContractType,
  getTaxesDetail,
  getClosingDate,
  saveDirectDeal,
  publishDirectDeal
} from '../../services/directDealStepTwo'
import {
  getAllCurrencies,
  getProviderAbilityV2,
  getRegionsAndCities,
  getUnitsMeasurements
} from '../../services/config'
import { getSavedDirectDealSearch } from '../../services/directdeal'
import { getCauses } from '../../services/causes'

import { CURRENCY_TRANSLATION, DIRECT_DEAL_ORIGIN_DICTIONARY } from '../../config/settings/constants'
import { makeActionCreator } from '../../config/store/utils'
import { checkStatusProviderValue } from '../../modules/utils/checkers'

export const OPEN_CONFIRM_STEP_TWO_DIALOG = 'OPEN_CONFIRM_STEP_TWO_DIALOG'
export const onOpenConfirmStepTwoDialog = makeActionCreator(OPEN_CONFIRM_STEP_TWO_DIALOG)
export const CLOSE_CONFIRM_STEP_TWO_DIALOG = 'CLOSE_CONFIRM_STEP_TWO_DIALOG'
export const onCloseConfirmStepTwoDialog = makeActionCreator(CLOSE_CONFIRM_STEP_TWO_DIALOG)

export const PUBLISH_DIRECT_DEAL = 'PUBLISH_DIRECT_DEAL'
export const PUBLISH_DIRECT_DEAL_SUCCESS = 'PUBLISH_DIRECT_DEAL_SUCCESS'
export const PUBLISH_DIRECT_DEAL_ERROR = 'PUBLISH_DIRECT_DEAL_ERROR'
export const onPublishDirectDeal = makeActionCreator(PUBLISH_DIRECT_DEAL, 'payload')
export const onPublishDirectDealSuccess = makeActionCreator(PUBLISH_DIRECT_DEAL_SUCCESS, 'payload')
export const onPublishDirectDealError = makeActionCreator(PUBLISH_DIRECT_DEAL_ERROR, 'payload')

export const onPublishDirectDealThunk = payload => async dispatch => {
  dispatch(onPublishDirectDeal())
  try {
    const { data } = await publishDirectDeal({ sheet: payload.sheetStepTwo, actionType: PUBLISH_DIRECT_DEAL })

    return batch(() => {
      dispatch(onPublishDirectDealSuccess())
      payload.callback(data?.payload?.idOrdenCompra || '')
    })
  } catch ({ originalError }) {
    return batch(() => {
      dispatch(
        onPublishDirectDealError({
          err: originalError?.response?.data?.errores
            ? originalError?.response?.data?.errores[0]
            : { descripcion: 'No ha sido posible crear la ficha' }
        })
      )
      payload.callbackError({
        err: originalError?.response?.data?.errores
          ? originalError?.response?.data?.errores[0]
          : { descripcion: 'No ha sido posible crear la ficha' }
      })
    })
  }
}

export const SAVE_DIRECT_DEAL = 'SAVE_DIRECT_DEAL'
export const SAVE_DIRECT_DEAL_SUCCESS = 'SAVE_DIRECT_DEAL_SUCCESS'
export const SAVE_DIRECT_DEAL_ERROR = 'SAVE_DIRECT_DEAL_ERROR'
export const onSaveDirectDeal = makeActionCreator(SAVE_DIRECT_DEAL, 'payload')
export const onSaveDirectDealSuccess = makeActionCreator(SAVE_DIRECT_DEAL_SUCCESS)
export const onSaveDirectDealError = makeActionCreator(SAVE_DIRECT_DEAL_ERROR, 'payload')

export const onSaveDirectDealThunk = payload => async dispatch => {
  dispatch(onSaveDirectDeal())

  try {
    await saveDirectDeal({ sheet: payload.sheetStepTwo, actionType: SAVE_DIRECT_DEAL })

    return batch(() => {
      dispatch(onSaveDirectDealSuccess())
      payload.callback()
    })
  } catch (err) {
    return batch(() => {
      dispatch(onSaveDirectDealError({ err }))
      payload.callbackError()
    })
  }
}

export const GET_CONTRACT_DURATION = 'GET_CONTRACT_DURATION'
export const GET_CONTRACT_DURATION_ERROR = 'GET_CONTRACT_DURATION_ERROR'
export const GET_CONTRACT_DURATION_SUCCESS = 'GET_CONTRACT_DURATION_SUCCESS'
export const onGetContractDurationSearch = makeActionCreator(GET_CONTRACT_DURATION)
export const onGetContractDurationSearchError = makeActionCreator(GET_CONTRACT_DURATION_ERROR, 'payload')
export const onGetContractDurationSearchSuccess = makeActionCreator(GET_CONTRACT_DURATION_SUCCESS, 'payload')
export const onGetContractDurationSearchThunk = () => async dispatch => {
  dispatch(onGetContractDurationSearch())
  try {
    const { data } = await getContractDuration({ actionType: GET_CONTRACT_DURATION })
    return dispatch(
      onGetContractDurationSearchSuccess({
        contractDuration:
          data?.payload.map(item => ({
            value: item.desc,
            name: item.desc,
            id: item.id
          })) || []
      })
    )
  } catch (error) {
    return dispatch(
      onGetContractDurationSearchError({
        error
      })
    )
  }
}

export const GET_CONTRACT_TYPE = 'GET_CONTRACT_TYPE'
export const GET_CONTRACT_TYPE_ERROR = 'GET_CONTRACT_TYPE_ERROR'
export const GET_CONTRACT_TYPE_SUCCESS = 'GET_CONTRACT_TYPE_SUCCESS'
export const onGetContractTypeSearch = makeActionCreator(GET_CONTRACT_TYPE)
export const onGetContractTypeSearchError = makeActionCreator(GET_CONTRACT_TYPE_ERROR, 'payload')
export const onGetContractTypeSearchSuccess = makeActionCreator(GET_CONTRACT_TYPE_SUCCESS, 'payload')
export const onGetContractTypeSearchThunk = () => async dispatch => {
  dispatch(onGetContractTypeSearch())
  try {
    const { data } = await getContractType({ actionType: GET_CONTRACT_TYPE })
    return dispatch(
      onGetContractTypeSearchSuccess({
        contractType: data?.payload.reduce((acc, category) => {
          if (category.descCategoriaContrato === 'Bienes') {
            acc.GOODS = category?.tipos.map(item => ({
              value: item.idTipoContrato,
              name: item.descTipoContrato,
              id: item.idTipoContrato
            }))
          } else if (category.descCategoriaContrato === 'Servicios') {
            acc.SERVICES = category?.tipos.map(item => ({
              value: item.idTipoContrato,
              name: item.descTipoContrato,
              id: item.idTipoContrato
            }))
          }
          return acc
        }, {})
      })
    )
  } catch (error) {
    return dispatch(
      onGetContractDurationSearchError({
        error
      })
    )
  }
}

export const GET_TAXES = 'GET_TAXES'
export const GET_TAXES_ERROR = 'GET_TAXES_ERROR'
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS'
export const onGetTaxesSearch = makeActionCreator(GET_TAXES)
export const onGetTaxesSearchError = makeActionCreator(GET_TAXES_ERROR, 'payload')
export const onGetTaxesSearchSuccess = makeActionCreator(GET_TAXES_SUCCESS, 'payload')
export const onGetTaxesSearchThunk = () => async dispatch => {
  dispatch(onGetTaxesSearch())
  try {
    const { data } = await getTaxesDetail({ actionType: GET_TAXES })
    return dispatch(
      onGetTaxesSearchSuccess({
        taxes:
          data?.payload?.map(item => ({
            value: item.id,
            name: item.nombre,
            percentage: item.porcentaje
          })) || []
      })
    )
  } catch (error) {
    return dispatch(
      onGetTaxesSearchError({
        error
      })
    )
  }
}

export const GET_INFO_REGIONS = 'GET_INFO_REGIONS'
export const GET_INFO_REGIONS_SUCCESS = 'GET_INFO_REGIONS_SUCCESS'
export const GET_INFO_REGIONS_ERROR = 'GET_INFO_REGIONS_ERROR'

export const onGetRegions = makeActionCreator(GET_INFO_REGIONS, 'payload')
export const onGetRegionsSuccess = makeActionCreator(GET_INFO_REGIONS_SUCCESS, 'payload')
export const onGetRegionsError = makeActionCreator(GET_INFO_REGIONS_ERROR, 'payload')
export const onGetRegionsThunk = () => async dispatch => {
  dispatch(onGetRegions())

  try {
    const { data } = await getRegionsAndCities({ actionType: GET_INFO_REGIONS })

    if (data && Array.isArray(data.payload)) {
      const regions = data.payload.map(region => ({
        value: region.id,
        name: region.nombre,
        orden: region.orden
      }))
      dispatch(onGetRegionsSuccess({ regions }))
    } else {
      throw new Error('Estructura de datos inválida: no se han encontrado regiones.')
    }
  } catch (err) {
    dispatch(onGetRegionsError({ err }))
  }
}

export const GET_INFO_CITIES = 'GET_INFO_CITIES'
export const GET_INFO_CITIES_SUCCESS = 'GET_INFO_CITIES_SUCCESS'
export const GET_INFO_CITIES_ERROR = 'GET_INFO_CITIES_ERROR'

export const onGetCities = makeActionCreator(GET_INFO_CITIES, 'payload')
export const onGetCitiesSuccess = makeActionCreator(GET_INFO_CITIES_SUCCESS, 'payload')
export const onGetCitiesError = makeActionCreator(GET_INFO_CITIES_ERROR, 'payload')

export const onGetCitiesThunk = updatedValue => async dispatch => {
  dispatch(onGetCities())

  try {
    const { data } = await getRegionsAndCities({ actionType: GET_INFO_CITIES })
    const cities = data?.payload
      .flatMap(region => region.comunas)
      .filter(comuna => comuna.region === updatedValue)
      .map(comuna => ({
        value: comuna.id,
        name: comuna.nombre
      }))

    dispatch(onGetCitiesSuccess({ cities }))
  } catch (err) {
    dispatch(onGetCitiesError({ err }))
  }
}

export const GET_INFO_PROVIDER = 'GET_INFO_PROVIDER'
export const GET_INFO_PROVIDER_SUCCESS = 'GET_INFO_PROVIDER_SUCCESS'
export const GET_INFO_PROVIDER_ERROR = 'GET_INFO_PROVIDER_ERROR'

export const onGetInfoProvider = makeActionCreator(GET_INFO_PROVIDER, 'payload')
export const onGetInfoProviderSuccess = makeActionCreator(GET_INFO_PROVIDER_SUCCESS, 'payload')
export const onGetInfoProviderError = makeActionCreator(GET_INFO_PROVIDER_ERROR, 'payload')

export const onGetInfoProviderThunk = payload => async dispatch => {
  dispatch(onGetInfoProvider())

  try {
    const { data } = await getProviderAbilityV2({
      providerDni: payload.providerDni,
      isForeign: payload.isForeign ? '1' : '0',
      actionType: GET_INFO_PROVIDER
    })

    if (!data?.payload) {
      return batch(() => {
        dispatch(onGetInfoProviderError({ err: data?.errores }))
        payload.callbackError()
      })
    }

    return batch(() => {
      dispatch(
        onGetInfoProviderSuccess({
          provider: {
            businessName: data?.payload?.razonSocial || '',
            isEnabled: data?.payload?.habilidad || false,
            status: checkStatusProviderValue(data?.payload?.habilidad, data?.payload?.acreditacionVigente)
          }
        })
      )
      payload.callback()
    })
  } catch (err) {
    return batch(() => {
      dispatch(onGetInfoProviderError({ err }))
      payload.callbackError()
    })
  }
}

export const GET_CLOSING_DATE = 'GET_CLOSING_DATE'
export const GET_CLOSING_DATE_SUCCESS = 'GET_CLOSING_DATE_SUCCESS'
export const GET_CLOSING_DATE_ERROR = 'GET_CLOSING_DATE_ERROR'
export const onGetClosingDate = makeActionCreator(GET_CLOSING_DATE, 'payload')
export const onGetClosingDateSuccess = makeActionCreator(GET_CLOSING_DATE_SUCCESS, 'payload')
export const onGetClosingDateError = makeActionCreator(GET_CLOSING_DATE_ERROR, 'payload')
export const onGetClosingDateThunk = payload => async dispatch => {
  dispatch(onGetClosingDate())
  try {
    const { data } = await getClosingDate({ closingDate: payload.closingDate, actionType: GET_CLOSING_DATE })

    return dispatch(
      onGetClosingDateSuccess({
        minDate: data?.payload.fechaCierre
      })
    )
  } catch (err) {
    return dispatch(onGetClosingDateError({ err }))
  }
}

export const GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO = 'GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO'
export const GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS = 'GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS'
export const GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR = 'GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR'

export const onGetUnitMeasurementStepTwo = makeActionCreator(GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO)
export const onGetUnitMeasurementStepTwoSuccess = makeActionCreator(
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS,
  'payload'
)
export const onGetUnitMeasurementStepTwoError = makeActionCreator(
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR,
  'payload'
)
export const onGetUnitMeasurementStepTwoThunk = () => async dispatch => {
  dispatch(onGetUnitMeasurementStepTwo())

  try {
    const { data } = await getUnitsMeasurements({ actionType: GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO })
    return dispatch(
      onGetUnitMeasurementStepTwoSuccess({
        unitMeasurement: data?.payload?.unidades.map(unit => {
          return {
            value: unit?.codigo,
            code: unit?.codigo,
            name: unit?.nombre,
            id: unit?.id
          }
        })
      })
    )
  } catch (err) {
    return dispatch(onGetUnitMeasurementStepTwoError({ err }))
  }
}

export const STORAGE_DIRECT_DEAL_STEP_TWO = 'STORAGE_DIRECT_DEAL_STEP_TWO'
export const onStorageDirectDealStepTwo = makeActionCreator(STORAGE_DIRECT_DEAL_STEP_TWO, 'payload')

export const OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO = 'OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO'
export const onOpenSavedDirectDealStepTwo = makeActionCreator(OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO)

export const CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO = 'CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO'
export const onCloseSavedDirectDealStepTwo = makeActionCreator(CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO)

export const GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO = 'GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO'
export const GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS = 'GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS'
export const GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR = 'GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR'

export const onGetAllCurrienciesDirectDealStepTwo = makeActionCreator(GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO)
export const onGetAllCurrienciesDirectDealStepTwoSuccess = makeActionCreator(
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS,
  'payload'
)
export const onGetAllCurrienciesDirectDealStepTwoError = makeActionCreator(
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR,
  'payload'
)

export const onGetAllCurrienciesDirectDealStepTwoThunk = () => async dispatch => {
  dispatch(onGetAllCurrienciesDirectDealStepTwo())

  try {
    const { data } = await getAllCurrencies({ actionType: GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO })
    return dispatch(
      onGetAllCurrienciesDirectDealStepTwoSuccess({
        currencies: data?.payload?.map(currency => {
          return {
            type: currency?.moneda,
            name: currency?.nombre,
            valueClp: currency?.valorPeso,
            value: currency?.moneda,
            symbol: CURRENCY_TRANSLATION[currency?.moneda]?.symbol || ''
          }
        })
      })
    )
  } catch (err) {
    return dispatch(onGetAllCurrienciesDirectDealStepTwoError({ err }))
  }
}

export const GET_SAVED_DIRECT_DEAL_SEARCH = 'GET_SAVED_DIRECT_DEAL_SEARCH'
export const GET_SAVED_DIRECT_DEAL_SEARCH_ERROR = 'GET_SAVED_DIRECT_DEAL_SEARCH_ERROR'
export const GET_SAVED_DIRECT_DEAL_SEARCH_SUCCESS = 'GET_SAVED_DIRECT_DEAL_SEARCH_SUCCESS'

export const onGetSavedDirectDealSearch = makeActionCreator(GET_SAVED_DIRECT_DEAL_SEARCH)
export const onGetSavedDirectDealSearchError = makeActionCreator(GET_SAVED_DIRECT_DEAL_SEARCH_ERROR, 'payload')
export const onGetSavedDirectDealSearchSuccess = makeActionCreator(GET_SAVED_DIRECT_DEAL_SEARCH_SUCCESS, 'payload')

export const onGetSavedDirectDealThunk = payload => async dispatch => {
  dispatch(onGetSavedDirectDealSearch())

  try {
    const { data } = await getSavedDirectDealSearch({
      actionType: GET_SAVED_DIRECT_DEAL_SEARCH,
      directDealId: payload.directDealId
    })

    return batch(() => {
      dispatch(
        onGetSavedDirectDealSearchSuccess({
          sheet: {
            address: data?.payload?.despacho?.direccion || '',
            chargeValue: data?.payload?.costo?.montoCargo?.toString() || 0,
            city: data?.payload?.despacho?.disCodeComuna,
            code: data?.payload?.codigoTratoDirecto,
            coin: data?.payload?.moneda || null,
            comment: data?.payload?.despacho?.comentarios || '',
            contractCategory: data?.payload?.categoriaContrato?.toString() || '',
            contractType: data?.payload?.tipoContrato?.id?.toString() || '',
            dateFinishExecute: data?.payload?.fechaEjecucion
              ? new Date(data?.payload?.fechaEjecucion?.split('+')[0])
              : null,
            dateFromClose: data?.payload?.fechaCierre ? new Date(data?.payload?.fechaCierre.split('+')[0]) : null,
            delivery: data?.payload?.costo?.montoDespacho?.toString() || 0,
            description: data?.payload?.descripcion,
            discount: data?.payload?.costo?.montoDescuento?.toString() || 0,
            duration: data?.payload?.duracionContrato?.nombre,
            hourDateCLose: data?.payload?.fechaCierre ? new Date(data?.payload?.fechaCierre.split('+')[0]) : null,
            foreignSupplier: data?.payload?.esProveedorExtranjero || false,
            justification: data?.payload?.justificacion || '',
            nameSheet: data?.payload?.nombre || '',
            observationCharge: data?.payload?.costo?.obsCargo,
            observationDiscount: data?.payload?.costo?.obsDescuento,
            providerDni: data?.payload?.rutProveedor,
            region: data?.payload?.despacho?.disCode,
            tax: data?.payload?.costo?.taxCode,
            total: data?.payload?.montoTotal || 0
          }
        })
      )
      payload.callback({
        region: data?.payload?.despacho?.disCode,
        pricing:
          data?.payload?.cotizaciones.map(item => ({
            idQuote: item?.id,
            contributorDni: item?.rutCotizante,
            contributorLegalName: item?.nombreCotizante,
            contributorPhone: item?.telefonoCotizante,
            contributorEmail: item?.correoCotizante,
            idBusiness: item?.idAdjunto,
            idSubBusiness: item?.idBusiness
          })) || [],
        products:
          data?.payload?.productos.map(item => ({
            productId: item?.codProducto,
            categoryId: item?.codCategoria,
            amount: item?.cantidad?.toString() || 0,
            detail: item?.descripcion,
            unitMeasure: item?.unidadMedida,
            unitPrice: item?.valorUnitario?.toString() || 0,
            codeZGEN: item?.zgen,
            dontKnowCodeZGEN: item?.noConoceCodigoZgen,
            charge: item?.cargo?.toString() || 0,
            discount: item?.descuento?.toString() || 0,
            discountOrCharge: item?.descuento ? '1' : item?.cargo ? '2' : '',
            withDiscountOrCharge: !!(item?.descuento || item?.cargo),
            productName: item?.nombreProductoServicio,
            isInvalid: false
          })) || [],
        stepOne: {
          cause: {
            value: data?.payload?.causal?.id,
            name: data?.payload?.causal?.nombre,
            publicity: data?.payload?.causal?.publicidad,
            over1000Utm: data?.payload?.causal?.mayor1000UTM
          },
          amount: data?.payload?.esMayor1000UTM,
          withPublicity: data?.payload?.conPublicidad,
          id: data?.payload?.id,
          code: data?.payload?.codigoTratoDirecto,
          resolution: {
            subBusiness: data?.payload?.adjuntoResolucionDecreto?.idBusiness || null,
            idBusiness: data?.payload?.adjuntoResolucionDecreto?.idAdjunto || null
          },
          report: {
            subBusiness: data?.payload?.adjuntoCausal?.idBusiness || null,
            idBusiness: data?.payload?.adjuntoCausal?.idAdjunto || null
          },
          optional: {
            subBusiness: data?.payload?.adjuntoOpcional?.idBusiness || null,
            idBusiness: data?.payload?.adjuntoOpcional.idAdjunto || null
          },
          originDirectDeal: data?.payload?.origen
            ? DIRECT_DEAL_ORIGIN_DICTIONARY.DIRECT_DEAL_BEFORE
            : DIRECT_DEAL_ORIGIN_DICTIONARY.DIRECT_DEAL_AFTER
        }
      })
    })
  } catch (error) {
    return dispatch(
      onGetSavedDirectDealSearchError({
        error: {
          code: error.code,
          message: error.message
        }
      })
    )
  }
}

export const OPEN_ABILITY_DIRECT_DEAL_STEP_TWO = 'OPEN_ABILITY_DIRECT_DEAL_STEP_TWO'
export const onOpenAbilityDirectDealStepTwo = makeActionCreator(OPEN_ABILITY_DIRECT_DEAL_STEP_TWO)

export const CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO = 'CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO'
export const onCloseAbilityDirectDealStepTwo = makeActionCreator(CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO)

export const SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO = 'SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO'
export const onSavedInfoStepOneDirectDealStepTwo = makeActionCreator(
  SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO,
  'payload'
)

export const RESET_DIRECT_DEAL_STEP_TWO = 'RESET_DIRECT_DEAL_STEP_TWO'
export const onResetDirectDealStepTwo = makeActionCreator(RESET_DIRECT_DEAL_STEP_TWO)

export const GET_CAUSE_STEP_TWO = 'GET_CAUSE_STEP_TWO'
export const GET_CAUSE_STEP_TWO_SUCCESS = 'GET_CAUSE_STEP_TWO_SUCCESS'
export const GET_CAUSE_STEP_TWO_ERROR = 'GET_CAUSE_STEP_TWO_ERROR'

export const onGetCausesStepTwo = makeActionCreator(GET_CAUSE_STEP_TWO)
export const onGetCausesStepTwoSuccess = makeActionCreator(GET_CAUSE_STEP_TWO_SUCCESS, 'payload')
export const onGetCausesStepTwoError = makeActionCreator(GET_CAUSE_STEP_TWO_ERROR, 'payload')

export const onGetCausesStepTwoThunk = () => async dispatch => {
  dispatch(onGetCausesStepTwo())

  try {
    const { data } = await getCauses()
    return dispatch(
      onGetCausesStepTwoSuccess({
        causes: data?.payload
          ?.filter(cause => cause?.descCausal !== 'Todos')
          ?.map(cause => {
            return {
              value: cause?.idCausal,
              name: cause?.descCausal || '',
              reportAttachmentValidation: cause?.adjuntoObligatorio
            }
          })
      })
    )
  } catch (err) {
    return dispatch(onGetCausesStepTwoError({ err }))
  }
}

export const OPEN_SAVED_ERROR_DIRECT_DEAL_STEP_TWO = 'OPEN_SAVED_ERROR_DIRECT_DEAL_STEP_TWO'
export const onOpenSavedErrorDirectDealStepTwo = makeActionCreator(OPEN_SAVED_ERROR_DIRECT_DEAL_STEP_TWO)

export const CLOSE_SAVED_ERROR_DIRECT_DEAL_STEP_TWO = 'CLOSE_SAVED_ERROR_DIRECT_DEAL_STEP_TWO'
export const onCloseSavedErrorDirectDealStepTwo = makeActionCreator(CLOSE_SAVED_ERROR_DIRECT_DEAL_STEP_TWO)

export const CLEAN_INFO_PROVIDER_DIRECT_DEAL_STEP_TWO = 'CLEAN_INFO_PROVIDER_DIRECT_DEAL_STEP_TWO'
export const onCleanInfoProviderDirectDealStepTwo = makeActionCreator(CLEAN_INFO_PROVIDER_DIRECT_DEAL_STEP_TWO)
