import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DatePicker, TimePicker, Typography } from '@chilecompra/react-kit/components'
import { useDatePicker, useTimePicker } from '@chilecompra/react-kit'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

/**
 * The DirectDealStepTwoClosingDate's container
 */
const DirectDealStepTwoClosingDate = props => {
  const { closeDate, formStepTwoRef, loading, storage } = props

  const [amPm, setAmPm] = useState('')
  const [minHourClose, setMinHourClose] = useState(null)

  const {
    error: dateFromCloseError,
    onChange: handleDateFromCloseChange,
    onError: handleDateFromCloseError,
    setValue: setDateFromClose,
    value: dateFromCloseValue
  } = useDatePicker({
    changeCallback: updatedValue => {
      settingHourClose(closeDate?.minDate, closeDate?.minDate, updatedValue)
    },
    initialValue: (storage?.dateFromClose && new Date(storage.dateFromClose)) || new Date()
  })
  const {
    error: hourDateCloseError,
    onChange: handleHourDateCloseChange,
    onError: handleHourDateCloseError,
    setValue: setHourDateClose,
    value: hourDateCloseValue
  } = useTimePicker({
    initialValue: (storage?.hourDateCLose && new Date(storage.hourDateCLose)) || new Date(),
    changeCallback: updateValue => handlerSetAmOrPm(updateValue)
  })

  const handlerSetAmOrPm = value => {
    const inputDate = new Date(value)
    const hours = inputDate.getHours()

    if (!isNaN(hours)) {
      setAmPm(hours >= 12 ? 'PM' : 'AM')
    } else {
      setAmPm('')
    }
  }

  const settingHourClose = (hourClose, minDate, dateClose) => {
    setHourDateClose(new Date(hourClose))
    handlerSetAmOrPm(hourClose)
    settingMinHourClose(dateClose, minDate)
  }

  const settingMinHourClose = (dateClose, minDateClose) => {
    if (dateClose.toLocaleDateString() === new Date(minDateClose).toLocaleDateString()) {
      setMinHourClose(new Date(minDateClose))
    } else {
      setMinHourClose(null)
    }
  }

  useEffect(() => {
    if (storage?.dateFromClose) {
      setDateFromClose(new Date(storage.dateFromClose))
    } else {
      setDateFromClose(new Date(closeDate?.minDate))
    }

    if (storage?.hourDateCLose) {
      settingHourClose(storage?.hourDateCLose, closeDate?.minDate, new Date(storage.dateFromClose))
    } else {
      settingHourClose(closeDate?.minDate, closeDate?.minDate, new Date(closeDate?.minDate))
    }
  }, [closeDate, storage])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      dateFromClose: {
        error: dateFromCloseError,
        onError: handleDateFromCloseError,
        value: dateFromCloseValue
      },
      hourDateClose: {
        error: hourDateCloseError,
        onError: handleHourDateCloseError,
        value: hourDateCloseValue
      }
    }),
    [dateFromCloseError, dateFromCloseValue, hourDateCloseError, hourDateCloseValue]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Fecha de cierre
        </Typography>
        <Typography fontWeight="regular" variant="body2" padding="8px 0 0" tabIndex="0">
          La fecha de cierre debe ser a lo menos 5 días hábiles posteriores a la publicación.
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer spacing={1}>
          <GridItem md={6} sm={12} xs={12}>
            <DatePicker
              error={dateFromCloseError}
              format="dd/MM/yyyy"
              label="Desde"
              value={dateFromCloseValue}
              typing={false}
              onChange={handleDateFromCloseChange}
              minDate={closeDate?.minDate}
              loading={loading}
            />
          </GridItem>
          <GridItem md={6} sm={12} xs={12}>
            <TimePicker
              ampm
              error={hourDateCloseError}
              format="hh:mm"
              label={`Hora de cierre en ${amPm}`}
              loading={loading}
              minTime={minHourClose}
              typing={false}
              value={hourDateCloseValue}
              onChange={handleHourDateCloseChange}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoClosingDate.propTypes = {
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  closeDate: PropTypes.shape({
    minDate: PropTypes.string,
    closingTime: PropTypes.string,
    minCloseDate: PropTypes.string
  }),
  storage: PropTypes.shape({
    dateFromClose: PropTypes.string,
    hourDateCLose: PropTypes.string
  })
}

export default DirectDealStepTwoClosingDate
