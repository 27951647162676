import { makeActionCreator } from '../../config/store/utils'

export const SET_INITIAL_CREATE_FLOW_PROVIDER = 'SET_INITIAL_CREATE_FLOW_PROVIDER'
export const onSetInitialCreateFlow = makeActionCreator(SET_INITIAL_CREATE_FLOW_PROVIDER)

export const SET_INITIAL_EDIT_FLOW_PROVIDER = 'SET_INITIAL_EDIT_FLOW_PROVIDER'
export const onSetInitialEditFlow = makeActionCreator(SET_INITIAL_EDIT_FLOW_PROVIDER)

export const SET_DIRECTION_STEP_ONE_FLOW_PROVIDER = 'SET_DIRECTION_STEP_ONE_FLOW_PROVIDER'
export const onSetDirectionStepOneFlow = makeActionCreator(SET_DIRECTION_STEP_ONE_FLOW_PROVIDER, 'payload')

export const SET_DIRECTION_STEP_ZERO_FLOW_PROVIDER = 'SET_DIRECTION_STEP_ZERO_FLOW_PROVIDER'
export const onSetDirectionStepZeroFlow = makeActionCreator(SET_DIRECTION_STEP_ZERO_FLOW_PROVIDER, 'payload')

export const SET_DIRECTION_STEP_TWO_FLOW_PROVIDER = 'SET_DIRECTION_STEP_TWO_FLOW_PROVIDER'
export const onSetDirectionStepTwoFlow = makeActionCreator(SET_DIRECTION_STEP_TWO_FLOW_PROVIDER, 'payload')
