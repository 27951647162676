import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { inIframe, useQuery } from '@chilecompra/react-kit'

import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'
/**
 * The ScrollToTop component.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation()
  const query = useQuery()

  useEffect(() => {
    if (inIframe()) {
      window.parent.postMessage(
        {
          action: 'scrollTo',
          value: {
            x: 0,
            y: 0
          }
        },
        REACT_APP_MERCADO_PUBLICO_BASE_URL
      )
    }
    window.scrollTo(0, 0)
  }, [pathname, query])

  return null
}

export default ScrollToTop
