import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwoButtons's container.
 */
const DirectDealStepTwoButtons = props => {
  const { loading, onContinue, onSave, onBack } = props

  const { size } = useViewport()

  return (
    <GridContainer margin="33px 0" spacing={2} alignItems="center">
      <GridItem lg={2} md={2} sm={12} xs={12} order={{ lg: 1, md: 1, sm: 3, xs: 3 }}>
        <Button
          aria-label="Volver"
          color="default"
          disabled={loading}
          variant="outlined"
          width={size.isSmall || size.isTiny ? '100%' : 'auto'}
          onClick={onBack}
        >
          Volver
        </Button>
      </GridItem>
      <GridItem lg={3} md={3} sm={12} xs={12} order={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
        <Button
          aria-label="Guardar Borrador"
          color="default"
          disabled={loading}
          variant="contained"
          width={size.isSmall || size.isTiny ? '100%' : 'auto'}
          onClick={onSave}
        >
          Guardar Borrador
        </Button>
      </GridItem>
      <GridItem lg={3} md={4} sm={12} xs={12} order={{ lg: 3, md: 3, sm: 1, xs: 1 }} textAlign="right">
        <Button
          aria-label="Crear nuevo Trato directo"
          color="success"
          disabled={loading}
          variant="contained"
          width={size.isSmall || size.isTiny ? '100%' : 'auto'}
          onClick={onContinue}
        >
          Crear nuevo Trato directo
        </Button>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoButtons.propTypes = {
  loading: PropTypes.bool,
  onContinue: PropTypes.func,
  onBack: PropTypes.func,
  onSave: PropTypes.func
}

export default DirectDealStepTwoButtons
