import React from 'react'

import { styled, Divider as DividerBase, Grid as BaseGrid } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

export const Root = styled('div')`
  width: 100%;
`
export const Divider = styled(DividerBase)`
  width: ${props => props.width};
`
export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  width: 100%;
  text-align: ${props => props.textAlign};
  padding: ${props => props.padding};
`

export const GridItem = styled(({ ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    width: 100%;
    text-align: ${props => props.textAlign};

    ${props => props.theme.breakpoints.only('xs')} {
      padding: ${props => props.padding || '7px 0'};
    }

    ${props => props.theme.breakpoints.only('sm')} {
      padding: ${props => props.padding || '7px 8px'};
    }

    ${props => props.theme.breakpoints.only('md')} {
      padding: ${props => props.padding || '7px 16px'};
    }

    ${props => props.theme.breakpoints.only('lg')} {
      padding: ${props => props.padding || '7px 16px'};
    }

    ${props => props.theme.breakpoints.only('xl')} {
      padding: ${props => props.padding || '7px 16px'};
    }
  }
`

export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`

export const SearchSuffix = styled('button')`
  border-right: 1px solid ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[50]};
  color: ${props => props.theme.palette.brand.white};
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.palette.blue[100]}; /* Ejemplo de estilo hover */
  }
`

export const Strong = styled('strong')`
  font-size: 14px;
`

export const HiddenSm = styled('div')`
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`
export const MainCircle = styled('div')`
  width: 64px;
  height: 64px;
  background-color: ${props =>
    props.backgroundColor === 'success'
      ? props.theme.palette.green[95]
      : props.backgroundColor === 'warning'
        ? props.theme.palette.yellow[95]
        : props.theme.palette.red[95]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ContainerCheck = styled('div')`
  display: 'flex';
  justify-content: 'center';
  margin: ${props => props.margin};
`

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${props => props.theme.palette.green[50]};
  font-size: 32px;
`
export const StyledCloseIcon = styled(CloseIcon)`
  color: ${props => props.theme.palette.red[50]};
  font-size: 32px;
`
