import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Input, Select, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  Textarea,
  useInput,
  useSelect,
  useTextarea
} from '@chilecompra/react-kit'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'
import { onGetCitiesThunk } from './DirectDealStepTwo.actions'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
/**
 * The DirectDealStepTwoAddress's container.
 */
const DirectDealStepTwoAddress = props => {
  const { cities, formStepTwoRef, loading, region, storage } = props

  const dispatch = useDispatch()

  const {
    error: addressError,
    onChange: handleAndressChange,
    onError: handleAddressError,
    value: addressValue,
    setValue: setAddressValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(100), checkInputErrorRequired()],
    initialValue: storage?.address || ''
  })
  const {
    error: commentaryError,
    onChange: handleCommentaryChange,
    onError: handleCommentaryError,
    value: commentaryValue,
    setValue: setCommetaryValue
  } = useTextarea({ errorCallbacks: [checkInputErrorMaxLength(300)], initialValue: storage?.comment || '' })
  const {
    error: regionError,
    onChange: handleRegionChange,
    onError: handleRegionError,
    value: regionValue,
    setValue: setRegionValue
  } = useSelect({
    changeCallback: updatedValue => dispatch(onGetCitiesThunk(updatedValue)),
    initialValue: storage?.region || '',
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: cityError,
    onChange: handleCityChange,
    onError: handleCityError,
    value: cityValue,
    setValue: setCityValue
  } = useSelect({
    initialValue: storage?.city || '',
    errorCallbacks: [checkInputErrorRequired()]
  })

  useEffect(() => {
    setAddressValue(storage?.address || '')
    setCommetaryValue(storage?.comment || '')
    setRegionValue(storage?.region || '')
    setCityValue(storage?.city || '')
  }, [storage])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      address: {
        error: addressError,
        onError: handleAddressError,
        value: addressValue
      },
      commentary: {
        error: commentaryError,
        onError: handleCommentaryError,
        value: commentaryValue
      },
      region: {
        error: regionError,
        onError: handleRegionError,
        value: regionValue
      },
      city: {
        error: cityError,
        onError: handleCityError,
        value: cityValue
      }
    }),
    [addressError, addressValue, commentaryError, commentaryValue, regionError, regionValue, cityError, cityValue]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Dirección de entrega o ejecución del servicio
        </Typography>
        <Typography fontWeight="regular" variant="body2" padding="8px 0 0" tabIndex="0">
          Ingresa la dirección a la cuál serán despachados los productos o donde será realizado el servicio contratado.
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <GridContainer spacing={1}>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Select
              error={regionError}
              label="Región"
              loading={loading}
              options={region}
              placeholder="Seleccione una Región"
              value={regionValue}
              onChange={handleRegionChange}
              onError={handleRegionError}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Select
              error={cityError}
              label="Comuna"
              loading={loading}
              options={cities}
              placeholder="Seleccione una Comuna"
              value={cityValue}
              onChange={handleCityChange}
              onError={handleCityError}
            />
          </GridItem>
          <GridItem xs={12}>
            <Input
              error={addressError}
              label="Dirección"
              loading={loading}
              maxCount={100}
              placeholder="Ingresa el nombre de la calle y número"
              value={addressValue}
              onChange={handleAndressChange}
              onError={handleAddressError}
            />
          </GridItem>
          <GridItem xs={12}>
            <Textarea
              error={commentaryError}
              label="Comentarios (opcional)"
              loading={loading}
              maxCount={300}
              size="medium"
              value={commentaryValue}
              onChange={handleCommentaryChange}
              onError={handleCommentaryError}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoAddress.propTypes = {
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  region: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      orden: PropTypes.number
    })
  ),
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
      orden: PropTypes.number
    })
  ),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    address: PropTypes.string,
    comment: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string
  })
}
export default DirectDealStepTwoAddress
