export const {
  PUBLIC_URL,
  REACT_APP_ACCESS_TOKEN_MP_NAME,
  REACT_APP_ACCESS_TOKEN_NAME,
  REACT_APP_API_BASE_URL,
  REACT_APP_API_PRINCIPAL_URL,
  REACT_APP_ATTACHMENT_API_URL,
  REACT_APP_ATTACHMENT_LIBRARY,
  REACT_APP_BASE_URL,
  REACT_APP_CAPACITACION_BASE_URL,
  REACT_APP_CAPTCHA_ENABLED,
  REACT_APP_CAPTCHA_SITE_KEY,
  REACT_APP_CENTROAYUDA_BASE_URL,
  REACT_APP_CERTIFICADO_MOP_BASE_URL,
  REACT_APP_CHILECOMPRA_BASE_URL,
  REACT_APP_DATOSABIERTOS_CHILECOMPRA_BASE_URL,
  REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_BUYER,
  REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_PUBLIC,
  REACT_APP_DOWNLOAD_SEARCH_RESULT_LINK_SELLER,
  REACT_APP_ENVIRONMENT,
  REACT_APP_FACEBOOK_CHILECOMPRA_BASE_URL,
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_GOOGLE_TASK_MANAGER_KEY,
  REACT_APP_HOTJAR_ID,
  REACT_APP_HOTJAR_SNIPPET_VERSION,
  REACT_APP_INSTAGRAM_CHILECOMPRA_BASE_URL,
  REACT_APP_LAWSUIT_BASE_URL,
  REACT_APP_LINKEDIN_CHILECOMPRA_BASE_URL,
  REACT_APP_MERCADO_PUBLICO_BASE_URL,
  REACT_APP_PARTICIPANT_LIST_URL,
  REACT_APP_PARTICIPANT_LIST,
  REACT_APP_PROVIDER_FOREIGN_STEP_TWO,
  REACT_APP_PROVIDERS_URL,
  REACT_APP_PUBLIC_CHILECOMPRA_BASE_URL,
  REACT_APP_TWITTER_CHILECOMPRA_BASE_URL,
  REACT_APP_YOUTUBE_CHILECOMPRA_BASE_URL
} = process.env
export const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod'
export const isLocalEnv = process.env.REACT_APP_ENVIRONMENT === 'local'
