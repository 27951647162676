import React from 'react'
import PropTypes from 'prop-types'

import { useViewport } from '@chilecompra/react-kit'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'

import { ContainerCheck, Grid, MainCircle, StyledCheckIcon } from '../DirectDealStepOne.styles'

/**
 * The DirectDealStepOne's saved success dialog.
 */
const DirectDealStepOneSavedSuccessDialog = ({ open, sheetCode, onBack, onClose }) => {
  const { size } = useViewport()

  return (
    <Dialog
      open={open}
      disableBackdropClick
      verticalAlign="top"
      onClose={onClose}
      padding={size.isSmall || size.isTiny ? '0' : '20px'}
    >
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item textAlign="center" padding="13px">
          <ContainerCheck style={{ display: 'flex', justifyContent: 'center' }} margin="0 0 18px">
            <MainCircle backgroundColor="success">
              <StyledCheckIcon />
            </MainCircle>
          </ContainerCheck>
          <Typography fontWeight="bold" variant="h3" tabIndex="0">
            Se ha guardado este Trato Directo
          </Typography>
        </Grid>
        <Grid item textAlign="center">
          <Typography variant="body1" tabIndex="0">
            Asignamos el siguiente Número
          </Typography>
          <Typography fontWeight="bold" variant="h2" color="success" tabIndex="0">
            {sheetCode || ''}
          </Typography>
        </Grid>
        <Grid item padding="32px 0 0" margin="4px 0" textAlign="center">
          <Button
            aria-label="Continuar creación del Trato Directo"
            color="success"
            variant="contained"
            onClick={onClose}
          >
            Continuar completando el formulario
          </Button>
        </Grid>
        <Grid item padding="0 0 28px" margin="4px 0" textAlign="center">
          <Button aria-label="Volver al inicio del módulo" variant="text" onClick={onBack}>
            Volver al inicio del módulo
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DirectDealStepOneSavedSuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sheetCode: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func
}

export default DirectDealStepOneSavedSuccessDialog
