import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import StepFlowProviderContext from './StepFlowProvider.context'

/**
 * The StepFlowProvider container.
 */
const StepFlowProviderContainer = ({ children }) => {
  const { flowMain, flowStepOne, flowStepTwo, flowStepZero } = useSelector(state => state.stepFlowProvider)

  return (
    <StepFlowProviderContext.Provider value={{ flowMain, flowStepOne, flowStepTwo, flowStepZero }}>
      {children}
    </StepFlowProviderContext.Provider>
  )
}

StepFlowProviderContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default StepFlowProviderContainer
