import { CURRENCY_TRANSLATION } from '../../config/settings/constants'

/**
 * Function that receives the dni to retonate it formatted.
 * the returned format is the following xx.xxx.xxx.xxx-x
 *
 * @param {string} dni
 * @returns {string}
 */
export const formatDNI = dni => {
  const cleanDni = dni.replace(/[^\dkK]/g, '')

  if (cleanDni.length === 0) {
    return ''
  }

  const dv = cleanDni.slice(-1)
  let numbers = cleanDni.slice(0, -1)

  let formattedDni = ''
  while (numbers.length > 3) {
    formattedDni = '.' + numbers.slice(-3) + formattedDni
    numbers = numbers.slice(0, -3)
  }

  formattedDni = numbers + formattedDni
  formattedDni += '-' + dv.toUpperCase()

  return formattedDni
}
/**
 * function that cleans the DNI to remove the dots
 *
 * @param {string} dni
 * @returns {string}
 */
export const unformatDNI = dni => {
  return dni.replace(/\./g, '')
}
/**
 * Date formatter: it must receive the date in string with the format timestamp and returns the date in the format dd-mm-yyyy,
 * and if you want to include it would be as follows dd-mm-yyyy hh:mm
 *
 * @param {string} dateString
 * @param {boolean} withHours
 * @returns {string}
 */
export function formatDate(dateString, withHours = false) {
  if (!dateString) {
    return ''
  }

  const date = new Date(dateString)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  if (!withHours) {
    return `${day}-${month}-${year}`
  }

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${day}-${month}-${year} ${hours}:${minutes}`
}

/**
 * Formats a value based on the provided currency type.
 *
 * This function applies the correct formatting for the value depending on the
 * type of currency specified:
 * - 'CLP': Formats the value as Chilean Pesos.
 * - 'USD': Formats the value as US Dollars.
 * - 'UTM' & 'CLF': Applies a format specific to Chilean financial units (UTM and CLF).
 *
 * @param {string} typeCurrency - The type of currency ('CLP', 'USD', 'UTM', or 'CLF').
 * @param {number|string} value - The value to be formatted.
 * @returns {string} - The formatted currency value.
 */
export const formatInputByCurrency = (typeCurrency, value) => {
  if (typeCurrency === CURRENCY_TRANSLATION.CLP.cod) {
    return formatNumberToCLP(value)
  } else {
    return formatInputToInternationalWithDecimal(value)
  }
}

/**
 * Formats an input value into an international format with decimals.
 *
 * - Ensures only numeric characters and commas (,) are considered valid input.
 * - Separates the integer part with periods (.) for thousands.
 * - Uses a comma (,) to separate the decimal part, preserving existing decimals.
 *
 * @param {string|number} value - The input value, either as a number or string, to format.
 * @returns {string} - The formatted value as a string, e.g., `1.234,56`.
 *
 * @example
 * formatInputToInternationalWithDecimal('1234,567');
 * // Returns: "1.234,567"
 *
 * @example
 * formatInputToInternationalWithDecimal(56789.123);
 * // Returns: "56.789,123"
 *
 * @example
 * formatInputToInternationalWithDecimal('abc1234,56xyz');
 * // Returns: "1.234,56"
 */
export const formatInputToInternationalWithDecimal = value => {
  const valueString = !isNaN(value) ? value.toString() : value
  const valueClean = valueString.replace(/[^0-9,]/g, '')

  const valueSeparate = valueClean.split(',')
  const valueBeforeComma = valueSeparate[0]
  const valueAfterComma = valueSeparate[1]

  const valueDot = valueBeforeComma.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  if (valueSeparate.length > 1) {
    return `${valueDot},${valueAfterComma || ''}`
  } else {
    return valueDot
  }
}

/**
 * Formats a number into an international format with decimals.
 *
 * - Separates the integer part of the number with periods (.) for thousands.
 * - Uses a comma (,) to separate the decimal part, limiting it to 2 digits.
 *
 * @param {number|string} value - The input number or string representation of a number to format.
 * @returns {string} - The formatted number as a string, e.g., `1.234,56`.
 *
 * @example
 * formatNumberToInternationalFormatWithDecimals(1234.567);
 * // Returns: "1.234,56"
 *
 * @example
 * formatNumberToInternationalFormatWithDecimals(1000);
 * // Returns: "1.000"
 *
 * @example
 * formatNumberToInternationalFormatWithDecimals('56789.12345');
 * // Returns: "56.789,12"
 */
export const formatNumberToInternationalFormatWithDecimals = value => {
  const valueString = value.toString()
  const valueSeparate = valueString.split('.')
  const valueBeforeDot = valueSeparate[0]
  const valueAfterDot = (valueSeparate[1] || '').slice(0, 2)
  const valueDot = valueBeforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  if (valueSeparate.length > 1) {
    return `${valueDot},${valueAfterDot}`
  } else {
    return valueDot
  }
}

/**
 * Formats a number into CLF (Unidad de Fomento) format and UTM (Unidad Tributaria Mensual) format.
 * This function converts a given numeric value to a string with thousands separators (dots) and a comma
 * as the decimal separator. It retains up to four decimal places if present.
 *
 * @param {number|string} value - The numeric value to be formatted, either as a number or a string representation of a number.
 * @returns {string} The formatted value in CLF format, with thousands separated by dots and up to four decimal places separated by a comma.
 */
export const formateNumberToNationalFormatWithDecimals = value => {
  const valueString = value.toString()
  const valueSeparate = valueString.split('.')
  const valueBeforeDot = valueSeparate[0]
  const valueAfterDot = (valueSeparate[1] || '').slice(0, 4)
  const valueDot = valueBeforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  if (valueSeparate.length > 1) {
    return `${valueDot},${valueAfterDot}`
  } else {
    return valueDot
  }
}

/**
 * Formats a given value to match the Chilean Peso (CLP) format.
 *
 * The function removes any non-numeric characters and applies thousands separators:
 * - Only numbers are retained.
 * - Dots are added as thousands separators for large numbers.
 *
 * @param {number|string} value - The value to be formatted as Chilean Pesos (CLP).
 * @returns {string} - The formatted value as a string in CLP format with thousands separators.
 */
export const formatNumberToCLP = value => {
  const valueString = value.toString().replace(/[^0-9]/g, '')
  return valueString.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

/**
 * Converts a formatted currency string to a numerical value based on the specified currency type.
 *
 * The function handles different currency types as follows:
 * - For 'CLF' and 'UTM': Parses the amount by replacing non-numeric characters and converting the comma to a decimal point.
 * - For 'CLP': Removes non-numeric characters and converts the resulting string to an integer.
 * - For 'USD': Removes non-numeric characters and converts the resulting string to a float.
 *
 * @param {string} amount - The formatted currency amount as a string.
 * @param {string} currencyType - The type of currency ('CLF', 'UTM', 'CLP', or 'USD').
 * @returns {number} - The parsed numerical value of the currency amount.
 */
export const formatCurrencyToNumber = (amount, currencyType) => {
  let value = 0

  if (amount) {
    if (
      currencyType === CURRENCY_TRANSLATION.CLF.cod ||
      currencyType === CURRENCY_TRANSLATION.UTM.cod ||
      currencyType === CURRENCY_TRANSLATION.EUR.cod ||
      currencyType === CURRENCY_TRANSLATION.USD.cod
    ) {
      value = parseFloat(amount?.replace(/[^0-9,]/g, '').replace(',', '.') || '0')
      value =
        Math.round(value * CURRENCY_TRANSLATION[currencyType].roundedDecimal) /
        CURRENCY_TRANSLATION[currencyType].roundedDecimal
    } else {
      value = parseInt(amount?.replace(/[^0-9]/g, '') || '0')
    }
  }

  return value
}

/**
 * Removes unnecessary leading zeros from a numeric string.
 *
 * This function ensures that:
 * - If the input starts with "0" followed by other digits (e.g., "01"), the leading zero is removed.
 * - Valid inputs like "0.1" or "0,123" (considering decimals) are preserved.
 *
 * @param {string} input - The numeric string to process.
 * @returns {string} The cleaned string without unnecessary leading zeros.
 *
 * @example
 * cleanLeadingZero("01");       // "1"
 * cleanLeadingZero("0.1");      // "0.1"
 * cleanLeadingZero("123");      // "123"
 * cleanLeadingZero("0,123");    // "0,123"
 */
export const cleanLeadingZero = input => {
  if (/^0\d/.test(input)) {
    return input.replace(/^0+/, '')
  }
  return input
}
