import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector, batch } from 'react-redux'

import { getCookie, useEffectOnce, usePathParam } from '@chilecompra/react-kit'

import DirectDealOrigin from './DirectDealStepZero.origin'
import DirectDealStepZeroFooter from './DirectDealStepZero.footer'

import { useFormRef } from '../../modules/hooks/useFormRef'
import routes from '../../config/settings/routes'
import { useStepFlow } from '../StepFlowProvider/StepFlowProvider.hooks'

import {
  onSavedInfoStepZeroDirectDealStepOne,
  onSavedSheetDirectDealStepOne
} from '../DirectDealStepOne/DirectDealStepOne.actions'
import {
  onCleanInitialSheetDealStepZero,
  onGetSavedDirectDealStepZeroThunk,
  onSavedSheetDealStepZero
} from '../DirectDealStepZero/DirectDealStepZero.actions'
import { onInitialSavedValuesDirectDealQuote } from '../DirectDealStepTwo/Quotes/DirectDealQuote.actions'
import { onInitialSavedProductsDirectDealProducts } from '../ProductSearch/ProductSearch.actions'
import { onStorageDirectDealStepTwo } from '../DirectDealStepTwo/DirectDealStepTwo.actions'

import { Root, Divider } from './DirectDealStepZero.styles'
import { DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY } from '../../config/settings/constants'
import { onSetDirectionStepOneFlow } from '../StepFlowProvider/StepFlowProvider.actions'

/**
 * The DirectDealStepOne's container.
 */
const DirectDealStepZeroContainer = () => {
  const { loading: directDealStepZeroLoading, storage: directDealStepZeroValues } = useSelector(
    state => state.directDealStepZero
  )

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formStepZeroRef = useFormRef()
  const { state } = useLocation()
  const directDealCode = usePathParam('code')
  const { flowMain, flowStepZero } = useStepFlow()

  const isInvalidStepZero = useMemo(() => {
    return (
      formStepZeroRef.current.resolution?.value === '' ||
      formStepZeroRef.current.resolution?.error !== '' ||
      formStepZeroRef.current.dateExecute?.value === '' ||
      formStepZeroRef.current.dateExecute?.error !== '' ||
      formStepZeroRef.current.signer?.value === '' ||
      formStepZeroRef.current.signer?.error !== '' ||
      formStepZeroRef.current.name?.error !== '' ||
      formStepZeroRef.current.name?.value === '' ||
      formStepZeroRef.current.lastName?.error !== '' ||
      formStepZeroRef.current.lastName?.value === '' ||
      formStepZeroRef.current.charge?.value === '' ||
      formStepZeroRef.current.charge?.error !== '' ||
      formStepZeroRef.current.origin?.value === ''
    )
  }, [
    formStepZeroRef.current.resolution?.value,
    formStepZeroRef.current.resolution?.error,
    formStepZeroRef.current.dateExecute?.value,
    formStepZeroRef.current.dateExecute?.error,
    formStepZeroRef.current.signer?.value,
    formStepZeroRef.current.signer?.error,
    formStepZeroRef.current.name?.error,
    formStepZeroRef.current.name?.value,
    formStepZeroRef.current.lastName?.error,
    formStepZeroRef.current.lastName?.value,
    formStepZeroRef.current.charge?.value,
    formStepZeroRef.current.charge?.error,
    formStepZeroRef.current.origin?.value
  ])
  const handlerBack = () => {
    batch(() => {
      const query = getCookie('search')

      dispatch(onCleanInitialSheetDealStepZero())
      navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
    })
  }
  const handlerNext = () => {
    if (isInvalidStepZero) {
      formStepZeroRef.current?.resolution?.onError()
      formStepZeroRef.current?.dateExecute?.onError()
      formStepZeroRef.current?.signer?.onError()
      formStepZeroRef.current?.name?.onError()
      formStepZeroRef.current?.lastName?.onError()
      formStepZeroRef.current?.charge?.onError()

      return
    }

    const stepZero = {
      resolution: formStepZeroRef.current.resolution?.value,
      resolutionDate: formStepZeroRef.current.dateExecute?.value,
      names: formStepZeroRef.current.name?.value,
      lastNames: formStepZeroRef.current.lastName?.value,
      dni: formStepZeroRef.current.signer?.value,
      job: formStepZeroRef.current.charge?.value,
      origin: formStepZeroRef.current.origin?.value
    }

    batch(() => {
      dispatch(onSavedSheetDealStepZero(stepZero))
      dispatch(onSavedInfoStepZeroDirectDealStepOne(stepZero))
      dispatch(onSetDirectionStepOneFlow({ direction: flowStepZero }))

      navigate(routes.directDealStepOne.replace(/:code/, directDealCode || ''), { replace: true, state })
    })
  }

  useEffectOnce(() => {
    if (
      flowMain === DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.CREATE &&
      flowStepZero === DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.AHEAD
    ) {
      dispatch(onCleanInitialSheetDealStepZero())
    } else if (flowMain === DIRECT_DEAL_STEP_EVENT_FLOWS_DICTIONARY.UPDATE && directDealCode) {
      dispatch(
        onGetSavedDirectDealStepZeroThunk({
          directDealCode,
          callback: payload => {
            batch(() => {
              dispatch(onInitialSavedValuesDirectDealQuote(payload.pricing))
              dispatch(onInitialSavedProductsDirectDealProducts(payload.products))
              dispatch(onSavedSheetDirectDealStepOne(payload.stepOne))
              dispatch(onStorageDirectDealStepTwo({ sheet: payload.stepTwo }))
            })
          }
        })
      )
    }
  })

  return (
    <Root>
      <Divider />
      <DirectDealOrigin
        values={directDealStepZeroValues}
        loading={directDealStepZeroLoading}
        formRef={formStepZeroRef}
      />
      <Divider />
      <DirectDealStepZeroFooter onBack={handlerBack} onContinue={handlerNext} />
    </Root>
  )
}

export default DirectDealStepZeroContainer
