import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from '@chilecompra/react-kit/components'
import { Skeleton } from '@chilecompra/react-kit'

import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

import { Divider, Grid, Info, IconArrowForward } from './DirectDealSummary.styles'
import {
  CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL,
  DIRECT_DEAL_SHEET_STATUS_DICTIONARY
} from '../../config/settings/constants'

/**
 * The DirectDealSummaryRequestsList.
 */
const DirectDealSummaryRequestsList = props => {
  const { contracts, loading, isSeller, isBuyer, status } = props
  return (
    <Grid container spacing={2} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder color="blank" padding="32px 16px">
          <Grid container spacing={1}>
            <Grid item>
              <Typography fontWeight="bold" variant="h3" tabIndex="0">
                Solicitud de un nuevo proceso de contratación
              </Typography>
              {isSeller && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && (
                <Typography variant="body2" lineHeight="18px" tabIndex="0">
                  Si ofreces el mismo producto o servicio, o conoces otro proveedor que lo haga por favor adjunta la
                  documentación necesaria que lo demuestre.
                </Typography>
              )}
              {(((isBuyer || (!isSeller && !isBuyer)) &&
                (status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST ||
                  status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED)) ||
                (isSeller && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED)) && (
                <Typography variant="body2" lineHeight="18px" tabIndex="0">
                  Listado de solicitudes enviadas por los proveedores que declaran ofrecer el mismo producto o servicio.
                </Typography>
              )}
            </Grid>

            {(isSeller || isBuyer) && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && (
              <Grid item margin="16px 0 0">
                <Card color="primary">
                  <Typography fontSize="14px" lineHeight="16px" tabIndex="0">
                    <Info margin="0 10px 0 0" />
                    Las solicitudes que se ingresen en esta sección serán de carácter público, desde el día y hora
                    indicado para el cierre de recepción de solicitudes.
                  </Typography>
                </Card>
              </Grid>
            )}

            <Grid item margin="16px 0">
              <Divider />
            </Grid>

            {contracts?.requestTotal > 0 && !loading && (
              <>
                <Grid item>
                  <Typography variant="body1" fontWeight="bold" tabIndex="0">
                    Se recibieron {contracts?.requestTotal} solicitudes.
                  </Typography>

                  {((isSeller &&
                    (status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED ||
                      status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST)) ||
                    (!isSeller && !isBuyer && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED)) && (
                    <Typography variant="body2" tabIndex="0">
                      El organismo comprador está actualmente evaluando cómo proceder con el Trato Directo.
                    </Typography>
                  )}
                  {(isBuyer || (!isSeller && !isBuyer)) &&
                    status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && (
                      <Typography variant="body2" tabIndex="0">
                        Una vez cumplida la fecha de cierre, podrás revisar el detalle de cada solicitud.
                      </Typography>
                    )}
                  {isBuyer && status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED && (
                    <Typography variant="body2" tabIndex="0">
                      A continuación, podrás revisar las solicitudes recibidas en tu Trato Directo.
                    </Typography>
                  )}
                </Grid>
              </>
            )}

            {(isSeller || (!isSeller && !isBuyer)) &&
              contracts?.requestTotal <= 0 &&
              status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED &&
              !loading && (
                <>
                  <Grid item margin="0 0 24px">
                    <Typography variant="body1" fontWeight="bold" tabIndex="0">
                      No se recibieron solicitudes.
                    </Typography>
                    <Typography variant="body2" lineHeight="18px" tabIndex="0">
                      El organismo comprador está actualmente evaluando cómo proceder con el Trato Directo.
                    </Typography>
                  </Grid>
                </>
              )}

            {isBuyer && !loading && contracts?.requestTotal <= 0 && (
              <Grid item>
                <Typography variant="body2" lineHeight="18px" fontSize="14px" tabIndex="0">
                  El Trato Directo no ha recibido solicitudes de otros proveedores.
                </Typography>
              </Grid>
            )}
            {!isSeller &&
              !isBuyer &&
              !loading &&
              contracts?.requestTotal <= 0 &&
              status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && (
                <Grid item>
                  <Typography variant="body2" lineHeight="18px" fontSize="14px" tabIndex="0">
                    El Trato Directo no ha recibido solicitudes de otros proveedores.
                  </Typography>
                </Grid>
              )}

            {status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED && contracts?.requestTotal > 0 && !loading && (
              <>
                <Grid item margin="24px 0">
                  <Divider />
                </Grid>
                {contracts?.requests.map(request => (
                  <Grid item key={request?.id}>
                    <Grid container spacing={1}>
                      <Grid item lg={5} md={5} sm={12} xs={12}>
                        <Typography fontWeight="bold" lineHeight="18px" fontSize="14px" tabIndex="0">
                          {request?.legalName}
                        </Typography>
                        <Typography lineHeight="18px" fontSize="14px" color="black3" tabIndex="0">
                          {request?.dni || 'xx.xxx.xxx-x'}
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <Grid container>
                          <Grid item lg={2} md={2} sm={1} xs={1}>
                            <IconArrowForward fontSize="24px" />
                          </Grid>
                          <Grid item lg={10} md={10} sm={11} xs={11}>
                            <Typography variant="body2" lineHeight="18px" tabIndex="0">
                              {request?.answer}
                            </Typography>
                            {request?.idBusiness && request?.subBusiness && (
                              <AttachmentsWrapper
                                business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                                subBusiness={request?.subBusiness}
                                idBusiness={request?.idBusiness}
                                isPrivate
                                canRead
                                color="blank"
                                showIconList={false}
                                typeView="list"
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}

            {loading && (
              <Grid item>
                <Typography variant="h1">
                  <Skeleton width="250px" />
                </Typography>

                <Typography variant="body1">
                  <Skeleton width="150px" />
                </Typography>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryRequestsList.propTypes = {
  contracts: PropTypes.shape({
    requestTotal: PropTypes.number.isRequired,
    requestClosedDate: PropTypes.bool.isRequired,
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        dni: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        answer: PropTypes.string.isRequired,
        legalName: PropTypes.string.isRequired
      })
    ).isRequired
  }),
  loading: PropTypes.bool.isRequired,
  isSeller: PropTypes.bool.isRequired,
  isBuyer: PropTypes.bool.isRequired,
  status: PropTypes.string
}

export default DirectDealSummaryRequestsList
