import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'
import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../config/settings/constants'

/**
 * The DirectDealStepTwoAttachments's container
 */
const DirectDealStepTwoAttachments = props => {
  const { loading, sheet } = props

  const [openModalAttachResolution, setOpenModalAttachResolution] = useState(false)
  const [openModalAttachReport, setOpenModalAttachReport] = useState(false)
  const [openModalAttachOptional, setOpenModalAttachOptional] = useState(false)
  const [openModalDeleteAllResolution, setOpenModalDeleteAllResolution] = useState(false)
  const [openModalDeleteAllReport, setOpenModalDeleteAllReport] = useState(false)
  const [openModalDeleteAllOptional, setOpenModalDeleteAllOptional] = useState(false)

  const handlerOpenAttachments = () => dialogScrollTop()

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold" tabIndex="0">
          Adjuntos de contratación directa
        </Typography>
        <Typography fontWeight="regular" variant="body2" padding="8px 0 0" tabIndex="0">
          Adjunta documentos que justifiquen la realización de la contratación excepcional.
        </Typography>
      </GridItem>

      <GridItem xs={12} md={6}>
        <GridContainer spacing={2}>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <GridContainer>
              <GridItem xs={12} margin="0 0 26px">
                <Typography variant="body1" fontWeight="bold" margin="0 0 7px" tabIndex="0">
                  Adjuntar archivos
                </Typography>
                <Typography variant="body2" margin="0 0 12px" tabIndex="0">
                  Para los casos de Trato Directo que no necesitan publicar la intención de contratar, debes adjuntar a
                  continuación el Acto Administrativo que autoriza el Trato Directo y el contrato si lo hay.
                </Typography>
                <Typography variant="body2" tabIndex="0">
                  Se admiten archivos con un peso máximo de 20mb.
                </Typography>
              </GridItem>
              <GridItem xs={12}>
                <Typography variant="body2" fontWeight="bold" tabIndex="0">
                  Antecedente de respaldo del Trato Directo o documento de intención
                </Typography>
                <Typography color="gray1" fontSize="12px" lineHeight="18px" tabIndex="0">
                  Archivo obligatorio.
                </Typography>
                {!loading && (
                  <AttachmentsWrapper
                    business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                    idBusiness={sheet?.resolution?.idBusiness}
                    subBusiness={sheet?.resolution?.subBusiness}
                    isPrivate
                    color="blank"
                    canDelete
                    canRead
                    canWrite
                    canWriteBulk
                    showModal
                    onOpenModalAction={handlerOpenAttachments}
                    openModal={openModalAttachResolution}
                    openModalDeleteAll={openModalDeleteAllResolution}
                    setOpenModal={setOpenModalAttachResolution}
                    setOpenModalDeleteAll={setOpenModalDeleteAllResolution}
                  />
                )}
              </GridItem>
              {sheet?.withTechnicalReport && (
                <GridItem xs={12}>
                  <Typography variant="body2" fontWeight="bold" tabIndex="0">
                    Informe técnico que justifique la procedencia de la causal
                  </Typography>
                  <Typography color="gray1" fontSize="12px" lineHeight="18px" tabIndex="0">
                    De conformidad con lo señalado en el artículo 8 bis de la Ley N°19.886, se requiere que se acompañe
                    al trato directo el informe que justifique la procedencia de la causal invocada, cuando se trate de
                    contrataciones superiores a las 1.000 UTM.
                  </Typography>
                  {!loading && (
                    <AttachmentsWrapper
                      business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                      idBusiness={sheet?.report?.idBusiness}
                      subBusiness={sheet?.report?.subBusiness}
                      isPrivate
                      color="blank"
                      canDelete
                      canRead
                      canWrite
                      canWriteBulk
                      showModal
                      onOpenModalAction={handlerOpenAttachments}
                      openModal={openModalAttachReport}
                      openModalDeleteAll={openModalDeleteAllReport}
                      setOpenModal={setOpenModalAttachReport}
                      setOpenModalDeleteAll={setOpenModalDeleteAllReport}
                    />
                  )}
                </GridItem>
              )}

              <GridItem xs={12}>
                <Typography variant="body2" fontWeight="bold" tabIndex="0">
                  Otros documentos opcionales
                </Typography>
                {!loading && (
                  <AttachmentsWrapper
                    business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                    idBusiness={sheet?.optional?.idBusiness}
                    subBusiness={sheet?.optional?.subBusiness}
                    isPrivate
                    color="blank"
                    canDelete
                    canRead
                    canWrite
                    canWriteBulk
                    showModal
                    onOpenModalAction={handlerOpenAttachments}
                    openModal={openModalAttachOptional}
                    openModalDeleteAll={openModalDeleteAllOptional}
                    setOpenModal={setOpenModalAttachOptional}
                    setOpenModalDeleteAll={setOpenModalDeleteAllOptional}
                  />
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoAttachments.propTypes = {
  loading: PropTypes.bool,
  sheet: PropTypes.shape({
    report: PropTypes.shape({
      subBusiness: PropTypes.number,
      idBusiness: PropTypes.number
    }),
    optional: PropTypes.shape({
      subBusiness: PropTypes.number,
      idBusiness: PropTypes.number
    }),
    resolution: PropTypes.shape({
      subBusiness: PropTypes.number,
      idBusiness: PropTypes.number
    }),
    withTechnicalReport: PropTypes.bool
  })
}

export default DirectDealStepTwoAttachments
