import React from 'react'
import { Card, Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem, ErrorImage } from './SearchResults.styles'

/**
 * The SearchResultsNotFound container.
 */
const SearchResultsNotFound = () => {
  return (
    <Card enableBorder color="blank" padding="118px 138px">
      <GridContainer>
        <GridItem xs={12} textAlign="center">
          <ErrorImage alt="Atención" src="/assets/images/Error_Busqueda.svg" />
          <Typography variant="h4" fontWeight="bold" padding="16px 0 0 " color="black1" tabIndex="0">
            No se encontraron resultados para tu búsqueda
          </Typography>
          <Typography variant="subtitle" fontWeight="regular" padding="0 0 0" tabIndex="0">
            Intenta con otra palabra clave u otro criterio de búsqueda.
          </Typography>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

export default SearchResultsNotFound
