import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, Card, CheckBox, Input, Select, Radio, Tag, Textarea } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMinLength,
  checkInputErrorMaxLength,
  checkInputErrorRegEx,
  checkInputErrorRequired,
  useCheckBox,
  useInput,
  useRadio,
  useSelect,
  useTextarea
} from '@chilecompra/react-kit'

import FormattedCurrencyComponent from '../../../components/components/FormattedCurrency.component'

import { AmountPrefix, DeleteLink, DeleteIconCustom, GridContainer, GridItem } from '../DirectDealStepTwo.styles'

import { ONLY_NUMBERS_CHECKED } from '../../../modules/utils/regexes'
import {
  DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS,
  DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE,
  DIRECT_DEAL_INITIAL_VALUES_PRODUCT
} from '../../../config/settings/constants'
import { checkInputErrorMinValue, getRegexByCurrency } from '../../../modules/utils/checkers'
import { formatInputByCurrency, formatCurrencyToNumber, cleanLeadingZero } from '../../../modules/utils/formatters'

/**
 * The DirectDealStepTwo product container.
 */
const DirectDealStepTwoProduct = props => {
  const { currency, onCalculate, onInvalidProduct, onRemove, onSave, onSavedProduct, product, unitMeasurement } = props

  const [withdiscount, setWithdiscount] = useState(false)
  const [withCharge, setWithCharge] = useState(false)
  const [totalItem, setTotalItem] = useState(0)
  const [totalItemWithoutDescount, setTotalItemWithoutDescount] = useState(0)
  const [isSaved, setSaved] = useState(true)

  const checkMaxDescountValueError = (maxValue, currency) => value => {
    return formatCurrencyToNumber(value, currency) >= maxValue
      ? `El monto del descuento no debe ser mayor o igual a ${maxValue}.`
      : ''
  }
  const {
    error: amountError,
    value: amountValue,
    onChange: handleChangeAmount,
    onError: handleErrorAmount,
    setValue: setAmount
  } = useInput({
    changeCallback: updatedValue => {
      calculateTotalItem(updatedValue, unitPriceValue, discountValue, chargeValue)
      setSaved(false)
    },
    errorCallbacks: [
      checkInputErrorMaxLength(20),
      checkInputErrorRequired(),
      checkInputErrorMinValue(1, 'El valor no debe ser inferior a 1.'),
      checkInputErrorRegEx(ONLY_NUMBERS_CHECKED)
    ],
    formatCallbacks: [value => cleanLeadingZero(value)],
    initialValue: product?.amount || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.AMOUNT
  })
  const {
    error: unitMeasureError,
    value: unitMeasureValue,
    onChange: handleChangeUnitMeasure,
    onError: handleErrorUnitMeasure
  } = useSelect({
    changeCallback: () => setSaved(false),
    initialValue: product?.unitMeasure || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.UNIT_MEASUREMENT,
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: unitPriceError,
    value: unitPriceValue,
    onChange: handleChangeUnitPrice,
    onError: handleErrorUnitPrice,
    setValue: setUnitPrice
  } = useInput({
    changeCallback: updatedValue => {
      calculateTotalItem(amountValue, updatedValue, discountValue, chargeValue)
      setSaved(false)
    },
    formatCallbacks: [value => cleanLeadingZero(formatInputByCurrency(currency?.type, value))],
    initialValue: product?.unitPrice || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.UNIT_PRICE,
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))]
  })
  const { value: withDiscountOrChargeValue, onChange: handleChangeWithDiscountOrCharge } = useCheckBox({
    changeCallback: () => {
      setDiscountOrCharge('')
      setDiscount('0')
      setCharge('0')
      setWithCharge(false)
      setWithdiscount(false)
      calculateTotalItem(amountValue, unitPriceValue, 0, 0)
      setSaved(false)
    },
    initialValue: product?.withDiscountOrCharge || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.WITH_DESCOUNT_OR_CHARGE,
    overoverwriteCallback: () => product?.withDiscountOrCharge || false
  })

  const {
    value: discountOrChargeValue,
    onChange: handleChangeDiscountOrChange,
    setValue: setDiscountOrCharge
  } = useRadio({
    changeCallback: updatedValue => {
      setDiscount('0')
      setCharge('0')
      setWithdiscount(updatedValue === DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS[0].value)
      setWithCharge(updatedValue === DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS[1].value)
      calculateTotalItem(amountValue, unitPriceValue, 0, 0)
      setSaved(false)
    },
    initialValue: product?.discountOrCharge || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.DISCOUNT_OR_CHARGE
  })
  const {
    error: discountError,
    value: discountValue,
    onChange: handleChangeDiscount,
    onError: handleErrorDiscount,
    setValue: setDiscount
  } = useInput({
    changeCallback: updatedValue => {
      calculateTotalItem(amountValue, unitPriceValue, updatedValue, chargeValue)
      setSaved(false)
    },
    formatCallbacks: [value => cleanLeadingZero(formatInputByCurrency(currency?.type, value))],
    initialValue: product?.discount || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.DISCOUNT,
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorRegEx(getRegexByCurrency(currency?.type)),
      checkMaxDescountValueError(totalItemWithoutDescount, currency?.type)
    ]
  })
  const {
    error: chargeError,
    value: chargeValue,
    onChange: handleChangeCharge,
    onError: handleErrorCharge,
    setValue: setCharge
  } = useInput({
    changeCallback: updatedValue => {
      calculateTotalItem(amountValue, unitPriceValue, discountValue, updatedValue)
      setSaved(false)
    },
    formatCallbacks: [value => cleanLeadingZero(formatInputByCurrency(currency?.type, value))],
    initialValue: product?.charge || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.CHARGE,
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorRegEx(getRegexByCurrency(currency?.type))]
  })
  const {
    error: codeZGENError,
    value: codeZGENValue,
    onChange: handleChangeZGEN,
    onError: handleErrorZGEN,
    onReset: handleResetZGEN,
    setValue: setZGEN
  } = useInput({
    changeCallback: () => setSaved(false),
    initialValue: product?.codeZGEN || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.ZGEN,
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMinLength(9),
      checkInputErrorMaxLength(9),
      checkInputErrorRegEx(ONLY_NUMBERS_CHECKED)
    ]
  })
  const { value: notCodeZGENValue, onChange: handleChangeNotCode } = useCheckBox({
    changeCallback: () => {
      handleResetZGEN()
      setZGEN('')
      setSaved(false)
    },
    initialValue: product?.dontKnowCodeZGEN
  })
  const {
    error: detailError,
    value: detailValue,
    onChange: handleChangeDetail,
    onError: handleErrorDetail
  } = useTextarea({
    changeCallback: () => setSaved(false),
    initialValue: product?.detail || DIRECT_DEAL_INITIAL_VALUES_PRODUCT.DETAIL,
    errorCallbacks: [checkInputErrorMaxLength(300), checkInputErrorRequired()]
  })

  const calculateTotalItem = (amount, unit, discount, charge) => {
    setTotalItem(
      onCalculate({
        item: {
          amount,
          unit,
          currencyType: currency?.type,
          discountOrCharge: discountOrChargeValue,
          discount,
          charge
        },
        callback: netWithoutDescount => setTotalItemWithoutDescount(netWithoutDescount)
      })
    )
  }

  const handlerRemoveProduct = () => {
    onRemove(product?.productKey)
  }

  const handlerSaveProduct = () => {
    if (isInvalidProduct) {
      handleChangeUnitMeasure()
      handleErrorAmount()
      handleErrorCharge()
      handleErrorDetail()
      handleErrorDiscount()
      handleErrorUnitPrice()
      handleErrorZGEN()
      return
    }

    setSaved(true)
    onSave({
      ...product,
      amount: amountValue,
      unitMeasure: unitMeasureValue,
      unitPrice: unitPriceValue,
      discountOrCharge: discountOrChargeValue,
      discount: discountValue,
      charge: chargeValue,
      codeZGEN: codeZGENValue,
      detail: detailValue,
      dontKnowCodeZGEN: notCodeZGENValue,
      isInvalid: isInvalidProduct,
      withDiscountOrCharge: withDiscountOrChargeValue
    })
  }

  const isInvalidProduct = useMemo(() => {
    return (
      amountValue === '' ||
      amountError !== '' ||
      unitMeasureError !== '' ||
      unitMeasureValue === '' ||
      unitPriceError !== '' ||
      unitPriceValue === '' ||
      detailError !== '' ||
      detailValue === '' ||
      (withCharge && (chargeError !== '' || chargeValue === '')) ||
      (withdiscount && (discountError !== '' || discountValue === '')) ||
      (product?.productId?.toString().startsWith(DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE) &&
        (codeZGENError !== '' || codeZGENValue === '') &&
        !notCodeZGENValue)
    )
  }, [
    amountError,
    amountValue,
    chargeError,
    chargeValue,
    codeZGENError,
    codeZGENValue,
    detailError,
    detailValue,
    discountError,
    discountValue,
    notCodeZGENValue,
    unitMeasureError,
    unitMeasureValue,
    unitPriceError,
    unitPriceValue
  ])

  useEffect(() => {
    setAmount(product?.amount)
    setUnitPrice(product?.unitPrice)
    setDiscount(product?.discount)
    setCharge(product?.charge)
    setWithdiscount(product?.discountOrCharge === DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS[0].value)
    setWithCharge(product?.discountOrCharge === DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS[1].value)
    calculateTotalItem(product?.amount, product?.unitPrice, product?.discount, product?.charge)
  }, [product, currency])

  useEffect(() => onInvalidProduct(isInvalidProduct), [isInvalidProduct])

  useEffect(() => onSavedProduct(isSaved), [isSaved])

  return (
    <Card padding="0">
      <GridContainer spacing={1} alignItems="center" padding="0">
        {!isSaved && (
          <GridItem
            xs={12}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'start'
            }}
          >
            <Tag color="warning" text="Cambios por guardar" tabIndex="0" />
          </GridItem>
        )}
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Input
            error={amountError}
            label="Cantidad"
            value={amountValue}
            onChange={handleChangeAmount}
            onError={handleErrorAmount}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Select
            error={unitMeasureError}
            label="Unidad"
            options={unitMeasurement}
            placeholder="Seleccione una unidad"
            value={unitMeasureValue}
            onChange={handleChangeUnitMeasure}
            onError={handleErrorUnitMeasure}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <Input
            error={unitPriceError}
            info={
              <>
                Total items: <FormattedCurrencyComponent amount={totalItem} currency={currency?.type} includeSymbol />
              </>
            }
            label="Valor unitario"
            prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
            value={unitPriceValue}
            onChange={handleChangeUnitPrice}
            onError={handleErrorUnitPrice}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={12} xs={12}>
          <CheckBox
            accessibility={{
              label: 'Agregar descuentos o cargos'
            }}
            label="Agregar descuentos o cargos"
            checked={withDiscountOrChargeValue}
            onChange={handleChangeWithDiscountOrCharge}
            tabIndex="0"
          />
        </GridItem>
        {withDiscountOrChargeValue && (
          <>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Radio
                direction="horizontal"
                options={DIRECT_DEAL_STEP_TWO_RADIO_OPTIONS_PRODUCTS}
                value={discountOrChargeValue}
                onChange={handleChangeDiscountOrChange}
              />
            </GridItem>
            <GridItem
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ visibility: withdiscount || withCharge ? 'visible' : 'hidden' }}
            >
              {withdiscount && (
                <Input
                  error={discountError}
                  label="Descuentos"
                  prefix={<AmountPrefix>{currency?.symbol}</AmountPrefix>}
                  value={discountValue}
                  onChange={handleChangeDiscount}
                  onError={handleErrorDiscount}
                />
              )}
              {withCharge && (
                <Input
                  error={chargeError}
                  label="Cargos"
                  prefix={<AmountPrefix> {currency?.symbol}</AmountPrefix>}
                  value={chargeValue}
                  onChange={handleChangeCharge}
                  onError={handleErrorCharge}
                />
              )}
            </GridItem>
          </>
        )}

        {product?.productId?.toString().startsWith(DIRECT_DEAL_STEP_TWO_PRODUCT_ONU_CODE) && (
          <>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Input
                disabled={notCodeZGENValue}
                error={codeZGENError}
                info="Informado por CENABAST"
                label="Código ZGEN"
                maxCount={9}
                value={codeZGENValue}
                onChange={handleChangeZGEN}
                onError={handleErrorZGEN}
              />
            </GridItem>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <CheckBox
                accessibility={{ label: 'No conozco el código ZGEN' }}
                label="No conozco el código ZGEN"
                checked={notCodeZGENValue}
                onChange={handleChangeNotCode}
              />
            </GridItem>
          </>
        )}

        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Textarea
            error={detailError}
            label="Detalle"
            maxCount="300"
            size="medium"
            value={detailValue}
            onChange={handleChangeDetail}
            onError={handleErrorDetail}
          />
        </GridItem>
        <GridItem lg={6} md={6} sm={6} xs={6}>
          <DeleteLink tabIndex="0" onClick={handlerRemoveProduct}>
            Eliminar ítem
          </DeleteLink>
          <DeleteIconCustom margin="0 0 -3px 5px" fontSize="16px" />
        </GridItem>
        <GridItem lg={6} md={6} sm={6} xs={6} textAlign="right">
          <Button aria-label="Guardar" variant="outlined" color="primary" onClick={handlerSaveProduct}>
            Guardar
          </Button>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

DirectDealStepTwoProduct.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  product: PropTypes.shape({
    amount: PropTypes.number,
    categoryId: PropTypes.number,
    charge: PropTypes.number,
    codeZGEN: PropTypes.string,
    detail: PropTypes.string,
    discount: PropTypes.number,
    discountOrCharge: PropTypes.string,
    dontKnowCodeZGEN: PropTypes.bool,
    isInvalid: PropTypes.bool,
    productId: PropTypes.number,
    productKey: PropTypes.string,
    productName: PropTypes.string,
    unitMeasure: PropTypes.string,
    unitPrice: PropTypes.number,
    withDiscountOrCharge: PropTypes.bool
  }),
  unitMeasurement: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number
    })
  ),
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
  onCalculate: PropTypes.func,
  onInvalidProduct: PropTypes.func,
  onSavedProduct: PropTypes.func
}

export default DirectDealStepTwoProduct
