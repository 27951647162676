import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import SearchResultPaginator from './SearchResults.paginator'
import SearchResultsBuyer from './SearchResults.buyer'
import SearchResultsError from './SearchResults.error'
import SearchResultsLoading from './SearchResults.loading'
import SearchResultsNotFound from './SearchResults.notfound'
import SearchResultsProvider from './SearchResults.provider'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import { Container } from './SearchResults.styles'

import routes from '../../config/settings/routes'
import { DIRECT_DEAL_SHEET_STATUS_DICTIONARY } from '../../config/settings/constants'

import { onSetInitialEditFlow } from '../StepFlowProvider/StepFlowProvider.actions'

/**
 * The SearchResult's container.
 */
const SearchResults = () => {
  const { isBuyer, isSeller } = useAuthorization()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const {
    loading: loadingSearch,
    error: errorSearch,
    searchResult: { results: data, resultCount, page, pageCount }
  } = useSelector(state => state.searchFilters)

  /**
   * One more page is added to the page because
   * the search service starts with page 0.
   */
  const pageValue = page + 1
  const pageSize = data?.length || 0

  const handleLineColors = state => {
    switch (state) {
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CANCEL:
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.REMOVE:
        return 'error'
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.ISSUE_PURCHASE:
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.PUBLISH:
        return 'primary'
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST:
        return 'success'
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.SAVED:
        return 'warning'
      case DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED:
        return 'default'
      default:
        return 'default'
    }
  }

  const handleRedirectDetails = sheetId => {
    if (isBuyer || isSeller) {
      navigate(routes.directDealSummary.replace(/:directDealId/, sheetId), { state: { from: location.pathname } })
    } else {
      navigate(routes.directDealSummaryPublic.replace(/:directDealId/, sheetId), { state: { from: location.pathname } })
    }
  }
  const handleRedirectEdition = sheetId => {
    dispatch(onSetInitialEditFlow())
    navigate(routes.directDealStepZero.replace(/:code/, sheetId), {
      replace: true
    })
  }

  return (
    <Container>
      {loadingSearch && <SearchResultsLoading />}

      {!loadingSearch && isBuyer && (
        <SearchResultsBuyer
          directDealSheets={data || []}
          onLineColors={handleLineColors}
          onRedirect={handleRedirectDetails}
          onEditionRedirect={handleRedirectEdition}
        />
      )}

      {!loadingSearch && !isBuyer && (
        <SearchResultsProvider
          directDealSheets={data || []}
          onLineColors={handleLineColors}
          onRedirect={handleRedirectDetails}
        />
      )}

      {!loadingSearch && (
        <SearchResultPaginator result={resultCount} page={pageValue} size={pageSize} count={pageCount} />
      )}

      {!loadingSearch && !errorSearch && data.length <= 0 && <SearchResultsNotFound />}

      {!loadingSearch && errorSearch && <SearchResultsError />}
    </Container>
  )
}

export default SearchResults
