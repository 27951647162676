import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Select, Skeleton, Typography, Radio } from '@chilecompra/react-kit/components'
import { checkInputErrorRequired, useSelect, useRadio } from '@chilecompra/react-kit'
import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'

import { Grid, GridItem, Strong } from './DirectDealStepOne.styles'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

import {
  BINARY_CONFIRMATION_RESPONSE,
  DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED,
  DIRECT_DEAL_STEP_ZERO_OPTIONS
} from '../../config/settings/constants'

/**
 * The DirectDealStepOne's basic background information.
 */
const DirectDealBasicBackground = props => {
  const { causes, formRef, loading, origin, utm, values } = props

  const [causeWithPublicity, setCauseWithPublicity] = useState(false)
  const [causeWithTechnicalReport, setCauseWithTechnicalReport] = useState(false)
  const [causeDescription, setCauseDescription] = useState('')
  const [disabledGreaterThanValue, setDisabledGreaterThanValue] = useState(false)

  const {
    error: causeError,
    onChange: handleCauseChange,
    onError: handleCauseError,
    setValue: setCauseValue,
    value: causeValue
  } = useSelect({
    changeCallback: updateValue => {
      const causeSelected = causes.find(cause => cause.value === updateValue)

      const causeWithPublicity = causeSelected?.publicity
      const causeOver1000Utm = causeSelected?.over1000Utm
      const causeWithTechReport = causeSelected?.technicalReport
      const causeDescription = causeSelected?.description

      setCauseDescription(causeDescription)
      setCauseWithPublicity(causeWithPublicity)
      setDisabledGreaterThanValue(causeOver1000Utm)

      handleOver1000Utm(causeOver1000Utm, causeWithPublicity, causeWithTechReport)
    },
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.cause || ''
  })
  const {
    value: isGreaterThanThousandValue,
    onChange: handleGreaterThanThousandChange,
    setValue: setGreaterThanThousandValue
  } = useRadio({
    changeCallback: updateValue => {
      const causeSelected = causes.find(cause => cause.value === causeValue)
      handleCauseWithTechnicalReport(causeSelected?.technicalReport, updateValue)
    },
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.amount || ''
  })

  const handleCauseWithTechnicalReport = (withTechReport, isOver1000Utm) => {
    setCauseWithTechnicalReport(withTechReport && isOver1000Utm === BINARY_CONFIRMATION_RESPONSE[0].value)
  }

  const handleOver1000Utm = (withOver, withPublicity, withTechReport) => {
    let isOver1000UtmValue = ''

    if (
      (origin === DIRECT_DEAL_STEP_ZERO_OPTIONS[0].value && withOver) ||
      (origin === DIRECT_DEAL_STEP_ZERO_OPTIONS[1].value && !withPublicity && withOver)
    ) {
      isOver1000UtmValue = BINARY_CONFIRMATION_RESPONSE[1].value
    } else if (origin === DIRECT_DEAL_STEP_ZERO_OPTIONS[1].value && withPublicity && withOver) {
      isOver1000UtmValue = BINARY_CONFIRMATION_RESPONSE[0].value
    }

    setGreaterThanThousandValue(isOver1000UtmValue)
    handleCauseWithTechnicalReport(withTechReport, isOver1000UtmValue)
  }

  useImperativeFormRef(
    formRef,
    () => ({
      cause: {
        error: causeError,
        onError: handleCauseError,
        value: causeValue
      },
      greaterThanThounsand: {
        value: isGreaterThanThousandValue
      },
      withPublicity: causeWithPublicity,
      withTechnicalReport: causeWithTechnicalReport
    }),
    [causeError, causeValue, isGreaterThanThousandValue, causeWithPublicity, causeWithTechnicalReport]
  )

  useEffect(() => {
    const causeSelected = causes.find(cause => cause.value === values?.cause)

    setCauseValue(values?.cause || '')
    setGreaterThanThousandValue(values?.amount || '')

    setCauseDescription(causeSelected?.description)
    setCauseWithPublicity(causeSelected?.publicity)
    setDisabledGreaterThanValue(causeSelected?.over1000Utm)

    handleCauseWithTechnicalReport(causeSelected?.technicalReport, values?.amount)
  }, [values, causes])

  return (
    <Grid container margin="33px 0" spacing={2}>
      <GridItem lg={2} md={2} sm={12} xs={12}>
        <Typography fontWeight="bold" variant="body1" margin="0 0 8px" tabIndex="0">
          Antecedentes básicos
        </Typography>
        <Typography variant="body2" tabIndex="0">
          Ingresa información específica de tu Trato Directo.
        </Typography>
      </GridItem>

      <GridItem lg={6} md={6} sm={12} xs={12}>
        <GridItem>
          <Select
            error={causeError}
            label="Causal de Trato Directo"
            placeholder="Selecciona la causal de tu Trato Directo"
            loading={loading}
            options={causes}
            value={causeValue}
            onChange={handleCauseChange}
          />
        </GridItem>
        <GridItem>
          {loading && <Skeleton width="150px" />}

          {!loading && (
            <>
              <Typography variant="body2" fontWeight="bold" tabIndex="0">
                ¿El Trato Directo será por un monto mayor a 1.000 UTM?
              </Typography>
              <Typography fontSize="12px" lineHeight="16px" color="black3" variant="body2" tabIndex="0">
                Monto referencial calculado en peso chileno{' '}
                {utm?.valueCLPRef && (
                  <FormattedCurrencyComponent amount={utm?.valueCLPRef} currency="CLP" includeSymbol />
                )}
              </Typography>
              <Typography fontSize="12px" lineHeight="16px" color="black3" variant="body2" tabIndex="0">
                Valor UTM al día {utm?.date}:{' '}
                {utm?.valueCLP && <FormattedCurrencyComponent amount={utm?.valueCLP} currency="CLP" includeSymbol />}
              </Typography>
            </>
          )}

          <Radio
            disabled={disabledGreaterThanValue}
            options={BINARY_CONFIRMATION_RESPONSE}
            loading={loading}
            margin="9px 0 0 -8px"
            value={isGreaterThanThousandValue}
            onChange={handleGreaterThanThousandChange}
          />
        </GridItem>

        {causeWithPublicity && (
          <GridItem margin="0 0 32px">
            <Card color="primary">
              <Typography fontSize="14px" lineHeight="18px" tabIndex="0">
                Debido a la causal y monto del Trato Directo que deseas realizar, este{' '}
                <Strong>deberá permanecer publicado al menos 5 días hábiles</Strong> en caso de que exista otro
                proveedor que pueda ofrecer el mismo bien o servicio.
              </Typography>
            </Card>
          </GridItem>
        )}
      </GridItem>

      <GridItem lg={4} md={4} sm={12} xs={12}>
        {causeDescription && causeDescription.trim() && (
          <>
            <Card
              padding="24px"
              margin="0 0 26px"
              sx={{ display: { sm: 'block', xs: 'block', lg: 'none', md: 'none' } }}
            >
              <Grid container>
                <GridItem>
                  <Typography variant="body1" fontWeight="bold" margin="0 0 16px" tabIndex="0">
                    Causal y justificación de Trato Directo
                  </Typography>
                </GridItem>
                <GridItem>
                  <Typography variant="body2" tabIndex="0">
                    {causeDescription}
                  </Typography>
                </GridItem>
              </Grid>
            </Card>

            <Card
              padding="24px"
              margin="0 0 26px"
              sx={{
                position: 'absolute',
                top: '45%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
                maxWidth: '400px',
                width: '90%',
                display: { sm: 'none', xs: 'none', lg: 'block', md: 'block' }
              }}
            >
              <Grid container>
                <GridItem>
                  <Typography variant="body1" fontWeight="bold" margin="0 0 16px" tabIndex="0">
                    Causal y justificación de Trato Directo
                  </Typography>
                </GridItem>
                <GridItem>
                  <Typography variant="body2" tabIndex="0">
                    {causeDescription}
                  </Typography>
                </GridItem>
              </Grid>
            </Card>
          </>
        )}

        {causeWithTechnicalReport && (
          <Card
            enableBorder
            lineColor="default"
            color="blank"
            padding="24px"
            sx={{ display: { sm: 'block', xs: 'block', lg: 'none', md: 'none' } }}
          >
            <Grid container>
              <GridItem>
                <Typography variant="h4" fontWeight="bold" padding="0 0 8px" tabIndex="0">
                  Se requiere informe técnico
                </Typography>
              </GridItem>
              <GridItem>
                <Typography variant="body2" lineHeight="18px" tabIndex="0">
                  {DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED}
                </Typography>
              </GridItem>
            </Grid>
          </Card>
        )}
      </GridItem>
    </Grid>
  )
}

DirectDealBasicBackground.propTypes = {
  causes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  origin: PropTypes.number,
  loading: PropTypes.bool,
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  utm: PropTypes.shape({
    date: PropTypes.string,
    valueCLP: PropTypes.number,
    valueCLPRef: PropTypes.number
  }),
  values: PropTypes.shape({
    cause: PropTypes.string,
    amount: PropTypes.boolean
  })
}

export default DirectDealBasicBackground
