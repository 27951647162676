import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import { Grid, GridItem } from './DirectDealStepOne.styles'

/**
 * The DirectDealStepOneFooter's component.
 */
const DirectDealStepOneFooter = ({ onBack, onContinue, onSave, loading }) => {
  const { size } = useViewport()

  return (
    <Grid container margin="32px 0">
      <GridItem
        lg={2}
        md={2}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'left'}
        order={{ lg: 1, md: 1, sm: 2, xs: 2 }}
      >
        <Button aria-label="Volver" disabled={loading} onClick={onBack} variant="outlined" width="100%">
          Volver
        </Button>
      </GridItem>
      <GridItem
        lg={2}
        md={3}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'left'}
        order={{ lg: 1, md: 1, sm: 2, xs: 2 }}
      >
        <Button aria-label="Guardar borrador" disabled={loading} onClick={onSave} variant="default" width="100%">
          Guardar borrador
        </Button>
      </GridItem>
      <GridItem
        lg={4}
        md={5}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'right'}
        order={{ lg: 2, md: 2, sm: 1, xs: 1 }}
        padding={size.isSmall || size.isTiny ? '7px 8px' : '7px 39px 7px 16px'}
      >
        <Button
          aria-label="Siguiente"
          color="primary"
          disabled={loading}
          onClick={onContinue}
          variant="contained"
          width={size.isSmall || size.isTiny ? '100%' : '50%'}
        >
          Siguiente
        </Button>
      </GridItem>
    </Grid>
  )
}

DirectDealStepOneFooter.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool
}

export default DirectDealStepOneFooter
