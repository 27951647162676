import React from 'react'
import PropTypes from 'prop-types'
import { Card, Tooltip, Typography } from '@chilecompra/react-kit/components'

import { Divider, GridContainer, GridItem, IconArrowForward, Link, InfoIconGray } from './SearchResults.styles'
import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'
import { CURRENCY_TRANSLATION, DIRECT_DEAL_SHEET_STATUS_DICTIONARY } from '../../config/settings/constants'

/**
 * The SearchResultsProvider's component.
 */
const SearchResultsProvider = props => {
  const { directDealSheets, onLineColors, onRedirect } = props

  const currentDate = new Date().toLocaleDateString().replace(/\//g, '-')

  return (
    <>
      {directDealSheets.map((sheet, index) => {
        const handleClick = event => {
          event.preventDefault()
          onRedirect(sheet.idDocument)
        }
        return (
          <Card
            color="blank"
            elevation="1"
            enableLine
            key={index}
            lineColor={onLineColors(sheet.state)}
            margin="0 0 16px"
            padding="24px 18px"
            tabIndex="0"
          >
            <GridContainer spacing={1}>
              <GridItem xs={12} margin="0 0 8px">
                <Typography variant="body2" fontWeight="bold" color={onLineColors(sheet.state)} tabIndex="0">
                  {sheet.state}
                </Typography>
              </GridItem>

              <GridItem lg={4} md={4} sm={12} xs={12}>
                <Typography variant="body2" fontWeight="regular" color="black1" tabIndex="0">
                  {sheet.idDocument}
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="black1" tabIndex="0">
                  {sheet.name || ''}
                </Typography>
              </GridItem>
              <GridItem lg={4} md={4} sm={12} xs={12}>
                <Typography variant="body2" fontWeight="regular" color="black3" tabIndex="0">
                  Causal
                </Typography>
                <Typography variant="body1" color="black1" tabIndex="0">
                  {sheet.cause || ''}
                </Typography>
              </GridItem>
              <GridItem
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{
                  textAlign: {
                    lg: 'right',
                    md: 'right',
                    sm: 'left',
                    xs: 'left'
                  }
                }}
              >
                <Typography variant="body2" fontWeight="regular" color="black3" tabIndex="0">
                  Monto Total
                </Typography>
                <Typography variant="h4" fontWeight="bold" color="black1" tabIndex="0">
                  <FormattedCurrencyComponent
                    amount={sheet.totalAmount || ''}
                    currency={sheet.currency || ''}
                    includeSymbol
                  />
                  {sheet.currency !== CURRENCY_TRANSLATION.CLP.cod && (
                    <Tooltip
                      content={`Monto referencial calculado en pesos chilenos: ${sheet.translatedTotalAmount}. Valor ${sheet.coinName} al día ${currentDate} : ${sheet.dailyCoinValue}`}
                      placement="bottom"
                    >
                      <InfoIconGray alt="Información" src="/assets/images/info_gray_questionMark.svg" />
                    </Tooltip>
                  )}
                </Typography>
                <Typography variant="body2" color="black1" tabIndex="0">
                  Publicado el {sheet.creationDate || ''}
                </Typography>
              </GridItem>

              <GridItem xs={12} margin="16px 0 8px">
                <Divider />
              </GridItem>

              <GridItem lg={4} md={4} sm={12} xs={12}>
                <Typography variant="body2" color="black3" tabIndex="0">
                  Comprador:
                </Typography>
                <Typography variant="body2" color="black1" tabIndex="0">
                  {sheet.buyer || ''}
                </Typography>
              </GridItem>
              <GridItem lg={4} md={4} sm={12} xs={12}>
                <Typography variant="body2" color="black3" tabIndex="0">
                  Proveedor:
                </Typography>
                <Typography variant="body2" color="black1" tabIndex="0">
                  {sheet.provider || ''}
                </Typography>
              </GridItem>

              {sheet?.state !== DIRECT_DEAL_SHEET_STATUS_DICTIONARY.SAVED && (
                <GridItem
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  sx={{
                    justifyContent: {
                      xs: 'flex-start',
                      md: 'flex-end',
                      lg: 'flex-end',
                      sm: 'flex-start'
                    }
                  }}
                >
                  <Link href="#" onClick={handleClick}>
                    Revisar detalle
                  </Link>
                  <IconArrowForward fontSize="16px" style={{ marginLeft: '8px' }} />
                </GridItem>
              )}
            </GridContainer>
          </Card>
        )
      })}
    </>
  )
}

SearchResultsProvider.propTypes = {
  directDealSheets: PropTypes.arrayOf(
    PropTypes.shape({
      cause: PropTypes.string,
      creationDate: PropTypes.string,
      idDocument: PropTypes.string,
      name: PropTypes.string,
      provider: PropTypes.string,
      state: PropTypes.string,
      totalAmount: PropTypes.number
    })
  ),
  onLineColors: PropTypes.func,
  onRedirect: PropTypes.func
}

export default SearchResultsProvider
